import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import AudioPlayer from '../podcast/podcast-audio-player';
import styles from './podcast-card.module.scss';

const PodcastCard = ({
  podcast,
  podcastImage,
  headsetImage,
  customCard,
  customImg,
  customContainer,
  customText,
  customAudio,
}) => {
  return (
    <div className={`${styles.podcast} ${customContainer}`}>
      <div className={`${customCard}`}>
        <GatsbyImage
          image={podcastImage.childImageSharp.gatsbyImageData}
          alt="le podcast bemum"
          className={styles.podcast_img}
        />
      </div>
      <div className={styles.podcast_text}>
        <div className={`${customImg}`}>
          <img src={headsetImage} alt="logo headset" className={styles.headset} />
        </div>
        <div className={`${styles.podcast_details} ${customText}`}>
          <div>
            <p className={styles.podcast_title}>{podcast.title}</p>
            <p className={styles.podcast_desc}>{podcast.desc}</p>
          </div>
          <div className={`${customAudio}`}>
            <AudioPlayer key={podcast.id} podcast={podcast} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PodcastCard;
