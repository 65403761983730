/**
 * @interface SmartNav
 * @name SmartNav
 */
/**
 * Add class to navbar on scroll down / scroll up
 * @function
 * @name smartNav
 * @memberof FunctionsComponent
 * @param {Object} options
 * @param {number} options.delta - Scroll threshold
 * @param {number} options.navbarHeight - Navigation height
 * @param {HTMLElement} options.header - Navbar Element
 * @return {SmartNav} Start with init(), kill with destroy()
 */
export function SmartNav(options) {
  const myOptions = {};

  myOptions.lastScrollTop = 0;
  myOptions.delta = options.delta || 2;
  myOptions.navbarHeight = options.navbarHeight || 0;
  myOptions.header = options.header || null;

  myOptions.style = options.style || {
    onTop: 'onTop',
    navDown: 'navDown',
    navUp: 'navUp',
  };

  return {
    interval: null,
    didScroll: false,
    scrollOpt: options,
    /**
     * Get document height
     * @function
     * @name SmartNav#docHeight
     * @return {number} Document height
     */
    docHeight: () => {
      var body = document.body,
        html = document.documentElement;

      var height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );

      return height;
    },
    /**
     * Watch scroll
     * @function
     * @name SmartNav#setInterval
     */
    setInterval: function () {
      return setInterval(() => {
        if (this.didScroll) {
          this.hasScrolled();
          this.didScroll = false;
        }
      });
    },
    /**
     * Toggle navbar based on scroll
     * @function
     * @name SmartNav#hasScrolled
     */
    hasScrolled: function () {
      const st = window.scrollY;

      const scrollOpt = this.scrollOpt;

      // did not scroll enough
      if (Math.abs(scrollOpt.lastScrollTop - st) <= scrollOpt.delta) return;

      // bellow navbar
      if (st <= scrollOpt.navbarHeight) {
        scrollOpt.header.classList.add(myOptions.style.onTop);
      } else {
        scrollOpt.header.classList.remove(myOptions.style.onTop);
      }

      // If current position > last position AND scrolled past navbar...
      if (st > scrollOpt.lastScrollTop && st > scrollOpt.navbarHeight) {
        // Scroll Down
        scrollOpt.header.classList.remove(myOptions.style.navDown);
        scrollOpt.header.classList.add(myOptions.style.navUp);
      } else {
        // Scroll Up
        // If did not scroll past the document (possible on mac)...
        if (st + window.innerHeight < this.docHeight()) {
          scrollOpt.header.classList.remove(myOptions.style.navUp);
          scrollOpt.header.classList.add(myOptions.style.navDown);
        }
      }

      this.scrollOpt.lastScrollTop = st;
    },
    /**
     * Enable `hasScrolled`
     * @function
     * @name SmartNav#reset
     */
    reset: function () {
      this.didScroll = true;
    },
    /**
     * Initialize module
     * @function
     * @name SmartNav#init
     */
    init: function () {
      this.interval = this.setInterval();
      window.addEventListener('scroll', this.reset.bind(this));
    },
    /**
     * Reset/destroy module
     * @function
     * @name SmartNav#destroy
     */
    destroy: function () {
      window.removeEventListener('scroll', this.reset.bind(this));
      clearInterval(this.interval);
      this.interval = null;
    },
  };
}

/**
 * Strip html tags from text
 * @param {string} str
 */
export function strip_html(str) {
  if (str && typeof str === 'string') return str.replace(/<[^>]*>/g, '');
  return null;
}

/**
 * Algorithm to create pagination dots
 * @param {*} c Current page
 * @param {*} m Total pages
 */
export function paginationAlgo(c, m) {
  var current = c,
    last = m,
    delta = 1,
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithDots = [],
    l;

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push('...');
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
}

/**
 * Check if graphql query has at least 1 child
 */
export function hasAnEdge(data) {
  return data && data.edges && Array.isArray(data.edges) && data.edges.length > 0;
}

/**
 * Give node at index and iterator
 * @param {*} data
 * @param {integer} index Data position wanted, default to first child
 */
export function dataEdge(data, index) {
  index = index || 0;

  return hasAnEdge(data)
    ? {
        node: data.edges[index].node,
        next: function () {
          return dataEdge(data, index + 1);
        },
      }
    : {
        node: null,
        next: null,
      };
}
