import React from 'react';
import { Link, navigate } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import SEO from '../components/seo';
import Post from '../components/blog/card';

import linkResolver from '../../linkResolver';

import * as style from '../modules/blog.module.scss';

export default function Blog(props) {
  const { currentPage, totalPages, lang } = props;

  const posts = props.posts || {};
  const postNodes = posts.nodes || [];

  const category = props.prismicCategory || {};
  const categoryData = category.data || {};

  const doc = props.prismicBlog || {};
  const blog = doc.data || {};
  const blogMetaTitle = blog.meta_title || {};
  const categoryName = categoryData.name ? `${categoryData.name.text} | ` : '';
  const metaTitle = `${categoryName}${blogMetaTitle.text}${currentPage > 1 ? ` - Page ${currentPage}`: ''}${' | BeMum'}`;
  const metaDescription = `Découvrez dans notre guide complet tout ce qu’il faut savoir pour tomber enceinte | ${categoryName ? `${categoryName}BeMum` : `Guide pour tomber enceinte ${currentPage}`}`;

  const blogLink = React.useMemo(() => {
    return linkResolver({ type: `blog`, lang });
  }, [lang]);

  const categories = props.allPrismicCategory || {};
  const categoryNodes = categories.nodes || [];

  const [toggleMore, setToggleMore] = React.useState(false);
  const toggleMoreClickHandler = React.useCallback(() => setToggleMore(!toggleMore), [toggleMore, setToggleMore]);
  const toggleMoreText = React.useMemo(() => `Voir ${toggleMore ? 'moins' : 'plus >'}`, [toggleMore]);

  return (
    <div>
      <SEO
        title={`${metaTitle}`}
        description={metaDescription}
        canonical={`https://www.bemum.co/blog/${currentPage}/`}
      />

      <header className={`BgRedLighter ${style.Header}`}>
        <div className={`wrapper`}>
          <div className={`cmd8 clg8`}>
            <h1 itemProp={`name`} className={`t1 mb3`}>
              {blog.title && blog.title.text}
              {categoryData.name && ` | ${categoryData.name.text}`}
              {totalPages > 1 && ` | ${currentPage}/${totalPages}`}
            </h1>
            {blog.content && toggleMore && (
              <div className="mb3" dangerouslySetInnerHTML={{ __html: blog.content.html }} />
            )}
            <button className={`p0 ${style.ToggleMoreButton}`} onClick={toggleMoreClickHandler}>
              {toggleMoreText}
            </button>
          </div>
        </div>
      </header>

      <div className={`${style.Blog}`}>
        <div className={`wrapper`}>
          <div className={`cmd10 clg10 shmd1 shlg1 mb5`}>
            <div className={`grid`}>
              <Search placeholder={blog.search_article} />
              <Select currentCategory={category} categories={categoryNodes} placeholder={blog.theme} />
            </div>
          </div>

          <div className={`grid-flex mb6 mbmd9`}>
            {postNodes.map((post) => (
              <Post key={post.uid} {...post} />
            ))}
          </div>

          {/* Prev / next page */}
          <ul className={`${style.Pagination} Center mb6 mbmd10`}>
            <li>
              {currentPage < 2 ? (
                <span>page précédente</span>
              ) : (
                <Link to={`${blogLink}/${currentPage - 1}/`}>page précédente</Link>
              )}
            </li>
            <li>
              {currentPage >= totalPages ? (
                <span>page suivante</span>
              ) : (
                <Link to={`${blogLink}/${currentPage + 1}/`}>page suivante</Link>
              )}
            </li>
          </ul>

          <div className={`cmd6 clg6 shmd6 shlg6`}>
            {blog.extra_content && (
              <div className={`${style.ExtraContent}`} dangerouslySetInnerHTML={{ __html: blog.extra_content.html }} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * Search prismic API for posts with {value}
 * @param {string} value
 * @returns {Promise}
 */
function searchAPI(value) {
  const apiBase = `https://bemum-blog.cdn.prismic.io`;
  const refApi = `${apiBase}/api/v2`;

  // Get Prismic api client and query for value in posts
  return import('@prismicio/client').then(({ default: Prismic }) => {
    return Prismic.client(refApi).query(
      [Prismic.Predicates.at('document.type', 'post'), Prismic.Predicates.fulltext('document', value)],
      { orderings: '[my.post.published_date desc]' }
    );
  });
}

// Timeout for typing
const TYPING = 500;

/**
 * Search form state management
 */
const useFormState = () => {
  const [search, setSearch] = React.useState('');
  const [results, setResults] = React.useState([]);
  const [isSearching, setSearching] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const timer = React.useRef();

  /**
   * Quit searching input
   */
  const quitSearch = React.useCallback(() => setSearch(''), [setSearch]);

  /**
   * update search value
   */
  const update = React.useCallback((evt) => setSearch(evt.target.value), [setSearch]);

  /**
   * Query api
   */
  const searchForValue = React.useCallback(
    (value) => {
      setLoading(true);
      return searchAPI(value)
        .then((res) => {
          setResults(res.results);
        })
        .finally(() => setLoading(false));
    },
    [setResults]
  );

  // Delay query api while user is still typing
  React.useEffect(() => {
    timer.current = setTimeout(() => {
      if (search !== '') {
        searchForValue(search);
        console.log('searching...', search);
      }
      timer.current = null;
    }, TYPING);

    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, [search, searchForValue]);

  // User is using the search input
  React.useEffect(() => {
    setSearching(search !== '');
  }, [search]);

  return {
    results,
    quitSearch,
    update,
    search,
    isLoading,
    isSearching,
  };
};

const Search = ({ placeholder }) => {
  const { results, quitSearch, update, search, isLoading, isSearching } = useFormState();

  let className = style.Search;
  if (isLoading) className += ` ${style.Loading}`;
  if (isSearching) className += ` ${style.Searching}`;

  return (
    <div className={`grid__item mb4 mbmd0 cmd6 clg6`}>
      <form onSubmit={(evt) => evt.preventDefault()}>
        <div className={`${className}`}>
          <input name={`search`} value={search} onChange={update} placeholder={placeholder} />

          {isSearching && !isLoading && <button className={style.Cross} aria-hidden={true} onClick={quitSearch} />}

          {isSearching && !isLoading && (
            <div className={style.Results}>
              {results.map((result) => (
                <Result key={result.id} {...result} />
              ))}
              {results.length === 0 && (
                <div className={`${style.Result}`}>
                  <span>{`Aucun résultat`}</span>
                </div>
              )}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

const Result = ({ uid, type, lang, data }) => {
  return (
    <div className={`${style.Result}`}>
      <Link to={linkResolver({ uid, type, lang })}>
        <h4>{RichText.asText(data.title)}</h4>
        <p>{RichText.asText(data.short_content)}</p>
      </Link>
    </div>
  );
};

const Select = ({ categories, currentCategory, placeholder = '' }) => {
  const handleChange = React.useCallback((evt) => {
    const link = evt.target.value;
    navigate(link);
  }, []);

  const handleSubmit = React.useCallback((evt) => {
    evt.preventDefault();
    return;
  }, []);

  // Current category link
  const currentLink = currentCategory.uid ? `${linkResolver(currentCategory)}/1/` : '';
  return (
    <div className={`grid__item cmd6 clg6`}>
      <form onSubmit={handleSubmit} className={style.Select}>
        <select name={`theme`} onChange={handleChange} value={currentLink}>
          <option default value="">
            {placeholder}
          </option>
          {categories.map((category) => {
            const link = linkResolver(category);
            return (
              <option key={category.uid} value={`${link}/1/`}>
                {category.data.name.text}
              </option>
            );
          })}
        </select>
      </form>
    </div>
  );
};
