import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useContext, useState } from 'react';
import Link from '../../components/utils/link';
import CartContext from '../../context/CartContext';
import Diets from '../static/diets';
import Testimonials from '../static/testimonials';
import style from './coaching.module.scss';
import { navigate } from 'gatsby';
import Ellipses from '../repeatable/ellipses';
import useFacebookConversionApi from '../../hooks/useFacebookConversionApi';
import { useLocation } from '@reach/router';
import useLocalStorage from '../../hooks/useLocalStorage';

const Coaching = ({ data }) => {
  const {
    pageCoachingYaml: { icon, presentation, details, mutuelle, how_it_works, option, stress_reduction, infertility },
  } = data;

  return (
    <div>
      {/* Presentation */}
      <section className={`${style.section} ${style.section__presentation}`}>
        <div className={`${style.flex} ${style.flex__reverse}`}>
          <div className={style.image_wrapper}>
            <GatsbyImage image={presentation.image.childImageSharp.gatsbyImageData} alt={presentation.title} />
          </div>
          <div>
            <h2>{presentation.title}</h2>
            <div
              className={`t3 ${style.presentation_content}`}
              dangerouslySetInnerHTML={{ __html: presentation.content }}
            />
          </div>
        </div>
        <div className={style.details}>
          <h3>{details.title}</h3>
          {details.items && (
            <ul>
              {details.items.map((item, key) => (
                <li key={key} className={`${style.contents_items} p0`}>
                  <div className={style.contents_icon} dangerouslySetInnerHTML={{ __html: icon }} />
                  <div className={style.contents_single_item}>{item}</div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </section>

      <Diets />

      {/* Mutuelle */}
      <div className={style.mutuelle}>
        <div className={style.mutuelle_icon} dangerouslySetInnerHTML={{ __html: icon }} />
        <div className="Center">
          <h2 className={`${style.mutuelle_title} mb3 DeepBlack`}>{mutuelle.title}</h2>
          <div className={`t3 Light mb4`} dangerouslySetInnerHTML={{ __html: mutuelle.content }} />
          <Link to={mutuelle.button.link} className={`Btn Btn--Reg`}>
            {mutuelle.button.text}
          </Link>
        </div>
        <div className={`${style.mutuelle_icon} ${style.mt4}`} dangerouslySetInnerHTML={{ __html: icon }} />
      </div>

      {/* How it works */}
      <section className={`${style.section}`}>
        <h2 className="mb5">{how_it_works.title}</h2>
        {how_it_works.items && (
          <ul>
            {how_it_works.items.map((item, key) => (
              <li key={key} className={style.contents_items}>
                <div className={style.contents_icon} dangerouslySetInnerHTML={{ __html: icon }} />
                <div className={style.contents_single_item}>{item}</div>
              </li>
            ))}
          </ul>
        )}
      </section>

      <Testimonials coaching />

      <CoachingOption product={option} icon={icon} />

      {/* Stress reduction and Infertility*/}
      <section className={`${style.stress_section}`}>
        <div className={`${style.stress_title}`}>{stress_reduction.title}</div>
        <div className={`p0 ${style.stress_content}`} dangerouslySetInnerHTML={{ __html: stress_reduction.content }} />

        <div className={`${style.stress_title}`}>{infertility.title}</div>
        <div className={`${style.flex}`}>
          <div className={style.image_wrapper}>
            <GatsbyImage image={infertility.image.childImageSharp.gatsbyImageData} alt={infertility.title} />
          </div>
          <div
            className={`p0 ${style.stress_content} ${style.stress_content_2}`}
            dangerouslySetInnerHTML={{ __html: infertility.content }}
          />
        </div>
      </section>
    </div>
  );
};

function CoachingOption({ product, icon }) {
  const { setCart } = useContext(CartContext);

  const location = useLocation();
  const [fertilityTestResults] = useLocalStorage('answers', null);
  const [eventId] = useLocalStorage('eventId', null);

  const [fbEventName, setFbEventName] = useState(null);

  // cf issue bemum#1638
  useFacebookConversionApi(
    fbEventName,
    location.href,
    fertilityTestResults?.email,
    fertilityTestResults?.firstname,
    fertilityTestResults?.phone,
    eventId,
    product.amount
  );

  function addToCart() {
    setFbEventName('ViewContent');
    setCart('add', 'product', product.name);
    navigate('/panier/');
  }

  return (
    <div className={`${style.flex} ${style.section} ${style.section__presentation}`} id="coaching-plus">
      <Ellipses top={'0'} left={'-120px'} />

      <div className={`t2 mb4 lg-hide`} dangerouslySetInnerHTML={{ __html: product.title }} />

      {/** Image */}
      <div className={`${style.image_wrapper} ${style.image_wrapper__coaching}`}>
        <GatsbyImage image={product.image.childImageSharp.gatsbyImageData} alt={product.title} />
      </div>

      <div>
        {/** Heading */}
        <div className={`t2 mb4 xs-hide sm-hide md-hide`} dangerouslySetInnerHTML={{ __html: product.title }} />
        <div className={`t3 mt4 mb3`} dangerouslySetInnerHTML={{ __html: product.subtitle }} />

        {/** Price */}
        <div className={style.price_wrapper}>
          <div className={`${style.price} p0`} dangerouslySetInnerHTML={{ __html: product.price }} />
          <button className={`Btn Btn--Reg Btn--Circle-Icon Btn--Circle-Icon--Checked`} onClick={addToCart}>
            Ajouter
          </button>
        </div>

        {/** Fertility Test */}
        <div className={`${style.doubt} p0 mb3`}>
          {' '}
          Un doute sur la pertinence pour vous de consultations complémentaires ?
        </div>
        <Link to="/test-fertilite/form/" className={`Btn Btn--Purple--Light mb5`}>
          Je fais mon test de fertilité
        </Link>

        <ul>
          {product.items.map((item, key) => (
            <li key={key} className={style.contents_items}>
              <div dangerouslySetInnerHTML={{ __html: icon }} className={style.contents_icon} />
              <div className={`${style.contents_single_item} p0`} dangerouslySetInnerHTML={{ __html: item }} />
            </li>
          ))}
        </ul>

        <div className={style.illustration_wrapper}>
          <img src={product.doctor.publicURL} height="50px" className={style.illustration} alt="doctor" />

          <div className={`${style.composition_wrapper} p0`} dangerouslySetInnerHTML={{ __html: product.highlight }} />
        </div>
      </div>
    </div>
  );
}

export default function (props) {
  return (
    <StaticQuery
      render={(data) => <Coaching data={data} {...props} />}
      query={graphql`
        {
          pageCoachingYaml {
            icon
            presentation {
              title
              content
              image {
                publicURL
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
            }
            details {
              title
              items
            }
            mutuelle {
              title
              content
              button {
                text
                link
              }
            }
            how_it_works {
              title
              items
            }
            option {
              name
              title
              subtitle
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
              price
              amount
              items
              doctor {
                publicURL
              }
              highlight
            }
            stress_reduction {
              title
              content
            }
            infertility {
              title
              content
              image {
                publicURL
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      `}
    />
  );
}
