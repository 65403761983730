import React from 'react';
import styles from './choice-cards.module.scss';

const ChoiceCards = ({ choice, mark, checked, onSelectionChange }) => {
  const handleChange = () => {
    onSelectionChange(choice.id);
  };
  const cardClasses = `${styles.choiceCard} ${styles[`choiceCard${choice.id}`]} ${checked ? styles.active : ''
    }`;


  return (
    <div className={cardClasses} onClick={handleChange}>
      {checked && <img src={mark} className={styles.choiceMarkIcon} alt="Checked" />}
      <div>
        <p dangerouslySetInnerHTML={{ __html: choice.title }} />
        <p dangerouslySetInnerHTML={{ __html: choice.description }} />
      </div>
    </div>
  );
};

export default ChoiceCards;
