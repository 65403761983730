import { navigate } from 'gatsby';
import React from 'react';
import styles from './call-to-action.module.scss';

/**
 * Call To Action on results page
 *
 * @returns {React.ReactElement}
 * @see resultats.js
 */
function CallToAction() {
  function addToCart() {
    navigate('/panier/?from=results');
  }

  return (
    <div className={`Center ${styles.cta}`}>
      <button onClick={addToCart} className={`Btn Btn--Black ${styles.button}`}>
        AJOUTER AU PANIER
      </button>
    </div>
  );
}

export default CallToAction;
