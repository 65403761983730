import React from 'react';
import { graphql } from 'gatsby';

// Layout
import Layout from '../components/layout';
import Blog from './blogLayout';

function Page({ data, pageContext }) {
  return (
    <Layout extendedReduced>
      <Blog {...data} {...pageContext} />
    </Layout>
  );
}

export const query = graphql`
  query($lang: String, $uid: String) {
    allPrismicCategory(filter: { lang: { eq: $lang } }) {
      nodes {
        uid
        type
        lang
        data {
          name {
            text
          }
        }
      }
    }
    posts: allPrismicPost(
      sort: { fields: data___date_published, order: DESC }
      filter: { lang: { eq: $lang }, data: { publication_level: { ne: true }, category: { uid: { eq: $uid } } } }
    ) {
      nodes {
        ...PostShort
      }
    }
    prismicCategory(lang: { eq: $lang }, uid: { eq: $uid }) {
      uid
      type
      lang
      data {
        name {
          text
        }
      }
    }
    prismicBlog(lang: { eq: $lang }) {
      data {
        title {
          text
        }
        theme
        search_article
        other_articles {
          text
        }
        extra_content {
          html
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        content {
          html
        }
      }
    }
  }
`;

export default Page;
