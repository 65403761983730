import { navigate } from 'gatsby';
import React, { useEffect } from 'react';

function AppMobile() {
  const isAndroid = typeof window !== 'undefined' && /Android/.test(navigator.userAgent);
  const isIOS = typeof window !== 'undefined' && /iPhone|iPad|iPod/.test(navigator.userAgent);

  const IOSLink = 'https://apps.apple.com/us/app/bemum/id1521451081/';
  const GooglePlayLink = 'https://play.google.com/store/apps/details?id=com.bemum';
  const notMobileAgent = '/';

  let redirect;

  if (isAndroid) {
    redirect = GooglePlayLink;
  } else if (isIOS) {
    redirect = IOSLink;
  } else {
    redirect = notMobileAgent;
  }

  useEffect(() => {
    if (typeof window !== undefined)   navigate(redirect);
  }, [redirect])


  return (
    <div>
      <p style={{ textAlign: 'center', marginTop: 40 }}>Redirection...</p>
    </div>
  );
}
export default AppMobile;
