import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from 'prop-types';
import React from 'react';
import questionDefinitionProptypes from '../question-definition.proptypes';
import styles from './input.module.scss';

/**
 * Question of type "text", "number", "email"
 * @param {Object} props
 * @param {import("./question").Definition} props.definition - Interlude question definition
 * @param {function} props.change - event handler
 * @param {*} props.value - input value
 */
function Input({ definition, change, value }) {
  if (!['email', 'text', 'number'].includes(definition.type)) {
    throw new Error(`Invalid type for Input : ${definition.type}`);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.imageWrapper}>
        {definition.image && (
          <GatsbyImage
            image={definition.image.childImageSharp.gatsbyImageData}
            className={styles.image}
            alt={definition.name} />
        )}
      </div>
      <div>
        <h2>{definition.title}</h2>
      </div>
      {definition.helper && <p className={`${styles.helper} p1`}>{definition.helper}</p>}
      <div className={styles.inputWrapper}>
        <div className={`${styles.Input} ${definition.type !== 'number' ? styles.InputText : ''}`}>
          <input
            name={definition.name}
            type={definition.type}
            value={value != null ? value : ''}
            onChange={change}
            placeholder={definition.input.placeholder}
            step={definition.input.step}
            min={definition.input.min}
            max={definition.input.max}
          />
        </div>
        <span className={`${styles.Unit} p0`}>{definition.input.unit}</span>
      </div>
    </div>
  );
}
export default Input;

Input.propTypes = {
  definition: questionDefinitionProptypes.isRequired,
  change: PropTypes.func.isRequired,
  value: PropTypes.any,
};
