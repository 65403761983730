import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styles from './article-card.module.scss';
import Modal from 'react-modal';



function Modalold({ url, onClose }) {
    return (
        <div className={styles.modal_overlay}>
            <div className={styles.modal_content}>
                <button onClick={onClose} className={styles.close_modal}>
                    X
                </button>
                <iframe src={url} title="Contenu" width="100%" height="100%"></iframe>
            </div>
        </div>
    );
}

const ArticleCard = ({ article, index }) => {

    const modalStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.58)',
            zIndex: 9000,
        },
        content: {
            top: '40%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            overflow: 'hidden',
            padding: '0',
            backgroundColor: 'transparent',
            marginTop: '60px',
            border: 'none',
            width: '100%',
            height: '100%',
        },
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);


    return (
        <div key={index} className={styles.article}>
            <GatsbyImage image={article.image.childImageSharp.gatsbyImageData} alt={`Article BeMum: ${article.title}`} className={styles.img_article} />
            <div className={styles.article_text}>
                <p className={styles.article_title}>{article.title}</p>
                <p className={styles.article_desc}>{article.desc}</p>
                <div className={styles.lire}>
                    <button className={`${styles.btn} ${styles.lire}`} onClick={handleOpenModal}>
                        Lire !
                    </button>
                    {/* {isModalOpen && <Modal url={article.link} onClose={handleCloseModal} />} */}
                    <Modal
                        isOpen={isModalOpen}
                        onRequestClose={handleCloseModal}
                        contentLabel="Guide Modal"
                        style={modalStyles}
                        closeTimeoutMS={200}
                    >
                        <div className={styles.modal_content}>
                            <button onClick={handleCloseModal} className={styles.close_modal}>
                                X
                            </button>
                            <iframe src={article.link} title="Contenu" width="100%" height="100%"></iframe>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default ArticleCard;
