import axios from 'axios';
import { graphql, navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import slideLeftTransition from '../../modules/transitions/slide-left.module.scss';
import slideRightTransition from '../../modules/transitions/slide-right.module.scss';
import Header from '../../components/fertility-test/header';
import NextButton from '../../components/fertility-test/next-button';
import Question from '../../components/fertility-test/questions/question';
import SEO from '../../components/seo';
import styles from './form.module.scss';
import useLocalStorage from '../../hooks/useLocalStorage';
import useFacebookConversionApi from '../../hooks/useFacebookConversionApi';

function FertilityTest({ data, location }) {
  /** Questions definition */
  const {
    allFormDiagnosticYaml: { edges },
  } = data;

  const initialTestAnswers = {
    interlude_start: '',
    firstname: '',

    interlude_welcome: '',

    baby_journey: '',
    infertility_diagnosis: [],
    to_get_pregnant: [],
    age: '',
    weight: '',
    height: '',

    interlude_lifestyle: '',

    environment: '',
    smoker: '',
    former_smoker: '',
    smoking_partner: '',
    alcohol: '',
    endocrine_disruptor_sensitivity: '',
    stress: '4', // default value
    sleep: '4', // default value
    physical_activity: '',

    interlude_food: '',
    fruits: '',
    veggies: '',
    oily_fish: '',
    oil: '',
    food_supplements: '',
    food_supplements_content: [],
    relationship_to_food: '',

    email: '',
    phone: '',

    gdpr: '',
  };
  const [answers, setAnswers] = useLocalStorage('answers', initialTestAnswers);
  const [motion, setMotion] = React.useState('left'); // 'left' or 'right'
  const [questionIndex, setQuestionIndex] = React.useState(0);
  const email = answers.email === '' ? null : answers.email;
  const firstname = answers.firstname === '' ? null : answers.firstname;
  const phone = answers.phone === '' ? null : answers.phone;
  /**
   * Set by google tag manager
   * Used by Facebook Business Manager to deduplicate events
   * @see https://www.facebook.com/business/help/823677331451951?id=1205376682832142
   */
  const [eventId] = useLocalStorage('eventId', null);
  useFacebookConversionApi('PageView', location.href, email, phone, firstname, eventId);

  /**
   * @param {Event} event
   */
  function sendForm(event) {
    event.preventDefault();
    const score = calculateScore(answers);
    saveOnGoogleSheet(score);
    // Save results in local storage
    saveResults(answers, score);
    saveAnswersInDB();
    navigate(`/resultats/programme-essentiel/`);
  }

  async function saveAnswersInDB() {
    const score = calculateScore(answers);
    const payload = { ...answers };
    let programName = 'ESS';
    let coaching = '';
    if (score.coaching >= 20) coaching = '+';
    if (score.antiox >= 20) programName = 'E-P';
    if (score.poids >= 20) programName = 'E-S';
    if (score.poids >= 20 && score.antiox >= 20) programName = 'E-PS';
    const program = programName + coaching;
    payload.program = program;
    payload.age = parseInt(answers.age, 10);
    payload.weight = parseInt(answers.weight, 10);
    payload.height = parseInt(answers.height, 10);
    payload.stress = parseInt(answers.stress, 10);
    payload.sleep = parseInt(answers.sleep, 10);
    payload.former_smoker = answers.former_smoker === '' ? null : answers.former_smoker;
    payload.smoking_partner = answers.smoking_partner === '' ? null : answers.smoking_partner;
    try {
      await axios.post(`${process.env.GATSBY_API}/fertility-test`, payload);
    } catch (error) {
      console.error(error);
    }
  }

  async function saveOnGoogleSheet(score) {
    const body = formatGoogleSheetData(score);

    try {
      await axios.get(`${process.env.GATSBY_ZAPIER_WEBHOOK}`, { params: body });
    } catch (error) {
      console.error(error);
    }
  }

  /** Transform answers values into readable format */
  function formatGoogleSheetData(score) {
    const today = new Date();
    const date = today.toLocaleDateString();
    const baby_journey = answers.baby_journey
      .replace('je-veux-préparer-ma-grossesse', 'Je veux préparer ma grossesse à priori tout va bien')
      .replace(
        'je-n-arrive-pas-a-tomber-enceinte',
        "Nous avons commencé les essais il y a quelques mois et je n'arrive pas à tomber enceinte"
      )
      .replace('j-ai-un-diagnostic-d-infertilite', 'Je suis suivie médicalement pour ma fertilité');
    const infertility_diagnosis = answers.infertility_diagnosis
      .join(' / ')
      .replace('sopk', 'Un SOPK')
      .replace('endometriose', 'Une endométriose')
      .replace('infertility', 'Une infertilité inexpliquée')
      .replace('aucun', 'Aucune des propositions');
    const to_get_pregnant = answers.to_get_pregnant
      .join(' / ')
      .replace('weight', 'Je souhaiterais perdre du poids avant ma grossesse')
      .replace('food', "Je souhaiterais améliorer ma façon de m'alimenter")
      .replace('life', 'Je souhaiterais améliorer mon hygiène de vie')
      .replace('fine', "Mes habitudes me semblent adaptées à la préparation d'une grossesse")
      .replace('aucun', 'Aucune des propositions');
    const relationship_to_food = answers.relationship_to_food
      .join(' / ')
      .replace('food_1', 'Utiliser de la nourriture pour gérer vos émotions ou comme récompense ou punition')
      .replace('food_2', 'Ne pas respecter votre satiété')
      .replace('food_3', 'Grignoter sans faim entre les repas')
      .replace('food_4', 'Avoir des difficultés à vous passer des aliments sucrés');
    const food_supplements_content = answers.food_supplements_content.join(' / ');

    let options = [];
    if (score.coaching >= 20) options.push('Coaching+');
    if (score.poids >= 20) options.push('S+');
    if (score.antiox >= 20) options.push('P+');
    options = options.join(' / ');

    return {
      date: date,
      firstname: answers.firstname,
      baby_journey: baby_journey,
      infertility_diagnosis: infertility_diagnosis,
      to_get_pregnant: to_get_pregnant,
      age: answers.age,
      weight: answers.weight,
      height: answers.height,
      environment: answers.environment,
      smoker: answers.smoker,
      former_smoker: answers.former_smoker,
      smoking_partner: answers.smoking_partner,
      alcohol: answers.alcohol,
      endocrine_disruptor_sensitivity: answers.endocrine_disruptor_sensitivity,
      stress: answers.stress,
      sleep: answers.sleep,
      physical_activity: answers.physical_activity,
      fruits: answers.fruits,
      veggies: answers.veggies,
      oily_fish: answers.oily_fish,
      oil: answers.oil,
      food_supplements: answers.food_supplements,
      food_supplements_content: food_supplements_content,
      relationship_to_food: relationship_to_food,
      email: answers.email,
      phone: answers.phone,
      v2: true,
      options: options,
    };
  }

  /**
   * Move to next step when pressing enter key
   * @param {Event} event
   */
  function onKeyDown(event) {
    const currentQuestion = edges[questionIndex].node;
    const isLast = questionIndex + 1 === edges.length;
    // Enter
    if (event.keyCode === 13) {
      event.preventDefault();
      if (!validate(answers[currentQuestion.name], currentQuestion.name)) {
        return event;
      }
      !isLast ? nextStep(event) : sendForm(event);
    }
  }

  /**
   * Validate current step
   * @param {*} stateVal Current value
   * @param {string} name Current step name
   */
  function validate(stateVal, name) {
    const currentQuestion = edges[questionIndex].node;

    const min = currentQuestion.min_opt || 0;
    const max = currentQuestion.max_opt || 99;
    const exclude = currentQuestion.exclude_opts || null;

    if (currentQuestion.name.includes('interlude')) return true;

    if (currentQuestion.name === 'phone') {
      /**
       * If phone number provided, check format
       * @see checkout-functions.js function validatePhoneNumber
       * Validate phone number
       * all these version:
       * 0601234567
       * +33601020304
       * 0033701020304
       */
      if (stateVal) {
        return /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(stateVal);
      }
      return true;
    }

    if (currentQuestion.name === 'email') {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(stateVal);
    }

    if (currentQuestion.name === 'gdpr') {
      return stateVal === 'oui';
    }

    if (Array.isArray(stateVal)) {
      if (exclude && exclude.some((e) => stateVal.indexOf(e) > -1)) return stateVal.length === 1;
      return stateVal.length >= min && stateVal.length <= max;
    }
    if (typeof stateVal === 'string') {
      const isValid = stateVal !== '' && stateVal !== '0,0' && stateVal !== ',';
      const int = parseInt(stateVal);
      if (isNaN(int)) return isValid;
      return isValid && int >= min;
    }

    return false;
  }

  /**
   * Back to previous step
   * @param {Event} event
   */
  function prevStep(event) {
    event.preventDefault();
    setMotion('right');
    const currentQuestion = edges[questionIndex].node;
    if (currentQuestion.name === 'alcohol' && answers.smoker === 'oui') {
      setQuestionIndex(questionIndex - 3);
    } else if (currentQuestion.name === 'relationship_to_food' && answers.food_supplements === 'non') {
      setQuestionIndex(questionIndex - 2);
    } else {
      setQuestionIndex(questionIndex - 1);
    }
  }

  /**
   * Go to next step
   * @param {Event} event
   */
  function nextStep(event) {
    event.preventDefault();
    setMotion('left');
    const currentQuestion = edges[questionIndex].node;
    if (currentQuestion.name === 'smoker' && answers.smoker === 'oui') {
      setQuestionIndex(questionIndex + 3);
    } else if (currentQuestion.name === 'food_supplements' && answers.food_supplements === 'non') {
      setQuestionIndex(questionIndex + 2);
    } else {
      setQuestionIndex(questionIndex + 1);
    }
  }

  /**
   *
   * @param {Event} event
   */
  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    const newAnswers = { ...answers };

    switch (type) {
      case 'checkbox':
        if (value === 'on') {
          newAnswers[name] = checked;
        } else {
          newAnswers[name] = getCheckboxValue({ answers, name, value });
        }
        break;
      case 'radio':
        if (value === 'on') {
          newAnswers[name] = checked;
        } else {
          newAnswers[name] = value;
          if (newAnswers['smoker'] === 'oui') {
            newAnswers['smoking_partner'] = 'non';
            newAnswers['former_smoker'] = 'non';
          }
        }
        break;
      default:
        newAnswers[name] = value;
        break;
    }

    setAnswers(newAnswers);

    if (
      (type === 'radio' && !(name === 'oily_fish' && value === '3+') && !(name === 'gdpr')) ||
      (type === 'checkbox' && (value === 'aucun' || value === 'infertility'))
    ) {
      event.persist();
      setTimeout(() => {
        // I had trouble getting the updated answers
        // inside the timeout closure so...
        // copy pasta from nextStep
        setMotion('left');
        const currentQuestion = edges[questionIndex].node;
        if (currentQuestion.name === 'smoker' && newAnswers.smoker === 'oui') {
          setQuestionIndex(questionIndex + 3);
        } else if (currentQuestion.name === 'food_supplements' && newAnswers.food_supplements === 'non') {
          setQuestionIndex(questionIndex + 2);
        } else {
          setQuestionIndex(questionIndex + 1);
        }
      }, 600);
    }
  }

  /**
   * Goes to next question when question type is range
   * @param {Event} event
   */
  function handleMouseUp(event) {
    const { type } = event.target;
    if (type === 'range') {
      setTimeout(() => {
        setMotion('left');
        setQuestionIndex(questionIndex + 1);
      }, 600);
    }
  }

  const currentQuestion = edges[questionIndex].node;

  /** Translate question name to french to display in url search params */
  function translateQuestionToFrench(question) {
    const mapping = {
      interlude_start: 'introduction_expert_i',
      firstname: 'prenom',
      interlude_welcome: 'desir_enfant_i',
      baby_journey: 'envie_de_bebe',
      infertility_diagnosis: 'diagnostic_fertilite',
      to_get_pregnant: 'pour_tomber_enceinte',
      age: 'age',
      weight: 'poids',
      height: 'taille',
      interlude_lifestyle: 'hygiene_de_vie_i',
      environment: 'environnement',
      smoker: 'fumeur',
      former_smoker: 'ancien_fumeur',
      smoking_partner: 'partenaire_fumeur',
      alcohol: 'alcool',
      endocrine_disruptor_sensitivity: 'perturbateurs_endocriniens',
      stress: 'stress',
      sleep: 'sommeil',
      physical_activity: 'sport',
      interlude_food: 'alimentation_i',
      fruits: 'fruits',
      veggies: 'legumes',
      oily_fish: 'poissons_gras',
      oil: 'huile_vegetale',
      food_supplements: 'complements_alimentaires',
      food_supplements_content: 'complements_alimentaires_composition',
      relationship_to_food: 'comportement_alimentaire',
      email: 'email',
      phone: 'telephone',
      gdpr: 'gdpr',
    };

    return mapping[question];
  }

  /** Change URL search params when changing question for GA tracking */
  useEffect(() => {
    const newQuestionName = translateQuestionToFrench(currentQuestion.name);
    navigate(`${location.pathname}?question=${newQuestionName}`);
  }, [questionIndex, currentQuestion.name, location.pathname]);

  useEffect(() => {
    if (currentQuestion.exclude_opts) {
      for (const exclude_opt of currentQuestion.exclude_opts) {
        const index = answers[currentQuestion.name].indexOf(exclude_opt);
        if (index > -1 && answers[currentQuestion.name].length > 1) {
          if (index === 0) {
            // Exclude current
            handleChange({
              target: {
                name: currentQuestion.name,
                value: answers[currentQuestion.name].filter((val) => {
                  return val !== exclude_opt;
                }),
              },
            });
          } else {
            // Exclude others
            handleChange({
              target: { name: currentQuestion.name, value: [exclude_opt] },
            });
          }
        }
      }
    }
    if (answers.firstname !== '') {
      answers.firstname = answers.firstname[0].toUpperCase() + answers.firstname.slice(1);
    }
  });

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  });

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  return (
    <>
      <SEO
        title={`Diagnostic`}
        description={`Nous établissons votre diagnostic`}
        canonical={`https://www.bemum.co/test-fertilite/form/`}
        noindex
      />
      <Header questionIndex={questionIndex} questionCount={edges.length} onBackClick={prevStep} />

      <main className={`wrapper`}>
        <div className={styles.layout}>
          {/** CURRENT QUESTION */}
          <form className={styles.form}>
            <Question
              definition={currentQuestion}
              value={answers[currentQuestion.name]}
              change={handleChange}
              firstname={answers.firstname}
              motion={motion}
              answers={answers}
              imc={calcImc(answers.weight, answers.height)}
            />
          </form>

          {/** BOTTOM NAVIGATION */}
          <div className={styles.nextButtonWrapper}>
            {/* Duplicated from question.js */}
            <SwitchTransition>
              <CSSTransition
                key={currentQuestion.name}
                // TODO : there's a bug here. To reproduce, observe the motion when doing
                // next next previous --> last animation is in the wrong direction
                // previous previous next --> last animation is in the wrong direction
                classNames={motion === 'left' ? slideLeftTransition : slideRightTransition}
                addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
              >
                {(currentQuestion.name !== 'interlude_start' && currentQuestion.expertise && (
                  <div className={styles.proTips}>
                    <h3 className={`p0 Diag_Expertise_Title`}>{currentQuestion.expertise.title}</h3>
                    <p className={`p1 ${styles.content}`}>{currentQuestion.expertise.content}</p>
                  </div>
                )) || <div></div>}
              </CSSTransition>
            </SwitchTransition>
            <NextButton
              autofocus={['hello', 'welcome', 'interlude'].includes(currentQuestion.type)}
              disabled={!validate(answers[currentQuestion.name], currentQuestion.name)}
              onClick={questionIndex + 1 === edges.length ? sendForm : nextStep}
              label={currentQuestion.next}
              errorMessage={currentQuestion.error_message}
            />
          </div>
        </div>
      </main>
    </>
  );
}

export default FertilityTest;

export const query = graphql`
  {
    allFormDiagnosticYaml {
      edges {
        node {
          title
          helper
          name
          type
          min_opt
          max_opt
          exclude_opts
          input {
            unit
            min
            max
            step
            placeholder
            default
          }
          range {
            min
            max
            step
            labels
          }
          options {
            value
            label
            type
            helper
            detail
            hover
          }
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          next
          error_message
          expertise {
            title
            content
          }
        }
      }
    }
  }
`;

/**
 * Calculate the user score based on their answers
 */
export function calculateScore(answers) {
  let score = {
    coaching: 0,
    poids: 0,
    antiox: 0,
    total: 10,
  };

  for (let key in answers) {
    const value = answers[key];

    switch (key) {
      case 'baby_journey':
        if (value === 'je-n-arrive-pas-a-tomber-enceinte') {
          score.total -= 1.25;
        }
        if (value === 'j-ai-un-diagnostic-d-infertilite') {
          score.total -= 1.25;
          score.infertility = true;
        }

        break;
      case 'infertility_diagnosis':
        if (arrHasVal('sopk', value)) {
          score.poids += 20;
          score.coaching += 10;
          score.total -= 1.25;
        }
        if (arrHasVal('endometriose', value)) {
          score.coaching += 10;
          score.total -= 0.5;
        }
        if (arrHasVal('infertility', value)) {
          score.total -= 1.25;
        }
        break;
      case 'to_get_pregnant':
        if (arrHasVal('weight', value)) {
          score.coaching += 10;
          score.poids += 20;
          score.total -= 0.25;
        }
        if (arrHasVal('food', value)) {
          score.coaching += 6;
          score.total -= 0.25;
        }
        if (arrHasVal('life', value)) {
          score.coaching += 3;
          score.total -= 0.25;
        }
        break;
      case 'age':
        if (value >= 29 && value < 35) {
          score.antiox += 6;
          score.total -= 0.5;
        }
        if (value >= 35 && value < 37) {
          score.antiox += 20;
          score.total -= 1.25;
        }
        if (value >= 37) {
          score.antiox += 20;
          score.total -= 1.25;
        }
        break;
      case 'height':
        const imc = calcImc(answers.weight, answers.height);
        if (imc < 18.5) {
          score.total -= 0.5;
        }
        if (imc >= 25 && imc < 27) {
          score.total -= 0.5;
        }
        if (imc >= 27 && imc < 30) {
          score.coaching += 6;
          score.poids += 14;
          score.total -= 1;
        }
        if (imc >= 30) {
          score.coaching += 10;
          score.poids += 20;
          score.total -= 1.25;
        }
        break;
      case 'environment':
        if (value === 'oui') {
          score.antiox += 8;
          score.total -= 0.25;
        }
        break;
      case 'smoker':
        if (value === 'oui') {
          score.antiox += 12;
          score.coaching += 3;
          score.total -= 0.5;
        }
        break;
      case 'former_smoker':
        if (value === 'oui') {
          score.antiox += 6;
          score.total -= 0.25;
        }
        break;
      case 'smoking_partner':
        if (value === 'oui') {
          score.antiox += 6;
          score.total -= 0.5;
        }
        break;
      case 'alcohol':
        if (value === '1-2') {
          score.total -= 0.25;
        }
        if (value === '3-4') {
          score.antiox += 8;
          score.total -= 0.25;
        }
        if (value === '5+') {
          score.antiox += 12;
          score.coaching += 3;
          score.total -= 0.5;
        }
        break;
      case 'endocrine_disruptor_sensitivity':
        if (value === 'non') {
          score.coaching += 3;
          score.total -= 0.1;
        }
        break;
      case 'stress':
        if (Number.parseInt(value) >= 5) {
          score.coaching += 3;
          score.total -= 0.1;
        }
        break;
      case 'sleep':
        if (Number.parseInt(value) >= 5) {
          score.coaching += 1.5;
          score.total -= 0.1;
        }
        break;
      case 'physical_activity':
        if (value === 'rarely') {
          score.coaching += 3;
          score.total -= 0.1;
        }
        break;
      case 'fruits':
        if (value === 'aucun') {
          score.antiox += 4;
          score.coaching += 4;
          score.total -= 0.25;
        }
        if (value === '1') {
          score.antiox += 2;
          score.coaching += 2;
          score.total -= 0.25;
        }
        break;
      case 'veggies':
        if (value === 'aucun') {
          score.antiox += 4;
          score.coaching += 4;
          score.total -= 0.25;
        }
        if (value === '1') {
          score.antiox += 2;
          score.coaching += 2;
          score.total -= 0.1;
        }
        break;
      case 'oily_fish':
        if (value === 'aucun') {
          score.total -= 0.25;
        }
        if (value === '1-2') {
          score.total -= 0.1;
        }
        break;
      case 'oil':
        if (value === 'aucun') {
          score.total -= 0.25;
          score.coaching += 4;
        }
        if (value === '1') {
          score.total -= 0.1;
        }
        break;
      case 'food_supplements':
        if (value === 'non') {
          score.total -= 0.25;
        }
        break;
      case 'relationship_to_food':
        if (arrHasVal('food_1', value)) {
          score.coaching += 6;
          score.total -= 0.1;
        }
        if (arrHasVal('food_2', value)) {
          score.coaching += 3;
          score.total -= 0.1;
        }
        if (arrHasVal('food_3', value)) {
          score.coaching += 6;
          score.total -= 0.1;
        }
        if (arrHasVal('food_4', value)) {
          score.coaching += 6;
          score.poids += 6;
          score.total -= 0.1;
        }
        break;
      default:
        break;
    }
  }

  score.total = Math.round(score.total * 10) / 10;
  return score;
}

/**
 * Save test results in local storage
 */
function saveResults(answers, score) {
  localStorage.setItem('score', JSON.stringify(score));
  localStorage.setItem('answers', JSON.stringify(answers));
}

/**
 * @param {string} val
 * @param {string[]} arr
 */
function arrHasVal(val, arr) {
  return arr.indexOf(val) > -1;
}

/**
 * @param {string} poids 12,6
 * @param {string} taille 120,6
 */
export function calcImc(poids, taille) {
  const sTaille = taille.split(',');
  const sPoids = poids.split(',');

  const nPoids = parseInt(sPoids[0]) + (isNaN(parseInt(sPoids[1])) ? 0 : parseInt(sPoids[1]) * 0.1);

  const nTaille = parseInt(sTaille[0]) + (isNaN(parseInt(sTaille[1])) ? 0 : parseInt(sTaille[1]) * 0.1);

  const sqTaille = (nTaille / 100) * (nTaille / 100);

  return nPoids / sqTaille;
}

function getCheckboxValue({ answers, name, value }) {
  if (Array.isArray(answers[name])) {
    if (answers[name].indexOf(value) > -1) {
      // Remove value
      return answers[name].filter((str) => {
        return str !== value;
      });
    } else {
      // Populate
      let arr = answers[name];
      arr[arr.length] = value;
      return arr;
    }
  }

  return [value];
}
