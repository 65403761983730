import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useContext, useState } from 'react';
import Modal from 'react-modal';
import CartContext, { products, programs } from '../../context/CartContext';
import style from './cart-popup-options.module.scss';

Modal.setAppElement(`#___gatsby`);

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.58)',
    zIndex: 9000,
  },
  content: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '1224px',
    maxHeight: '80vh',
    margin: '10vh auto',
    padding: '2rem 0',
    border: 0,
    overflowY: 'auto',
  },
  zIndex: 9000,
};

/**
 * Cart popup for options
 */
function CartPopupOptions({ modalOpen, closeModal, option, checked }) {
  const {
    pagePanierPopupYaml: {
      star,
      quote,
      doctor,
      close,
      essentiel,
      antiox,
      equilibre,
      coaching,
      benefits,
      inserm,
      fertility_age,
    },
  } = useStaticQuery(graphql`
    query PanierPopupQuery {
      pagePanierPopupYaml {
        star {
          publicURL
        }
        quote {
          publicURL
        }
        doctor {
          publicURL
        }
        close
        essentiel {
          name
          title
          subtitle
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          items
          rea
          expertise {
            content
            author
            role
          }
          contents {
            name
            description
          }
          composition {
            title
            image {
              publicURL
            }
            content_1
            content_2
          }
          coaching_presentation {
            title
            items
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
          app_presentation {
            title
            items
            small
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
        }
        coaching {
          name
          title
          subtitle
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          price
          items
          highlight
        }
        antiox {
          name
          title
          subtitle
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          price
          rea
          more
          expertise {
            content
            author
            role
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
          contents {
            name
            description
          }
          composition {
            title
            content
            image {
              publicURL
            }
          }
        }
        equilibre {
          name
          title
          subtitle
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          price
          rea
          more
          expertise {
            content
            author
            role
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
          contents {
            name
            description
          }
          composition {
            title
            content
            image {
              publicURL
            }
          }
        }
        benefits {
          title
          subtitle
          subtitle_2
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          items
          content
        }
        inserm {
          content
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        fertility_age {
          title
          content
          image {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  const modalCloseTimeout = 300;

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Modal"
      closeTimeoutMS={modalCloseTimeout}
    >
      {/** Close Button */}
      <button className={style.close} onClick={closeModal} dangerouslySetInnerHTML={{ __html: close }} />

      <div>
        {option === programs.ESSENTIEL && (
          <PackEssentiel product={essentiel} star={star.publicURL} doctor={doctor.publicURL} quote={quote.publicURL} />
        )}
        {option === products.COACHING && (
          <CoachingOption
            product={coaching}
            star={star.publicURL}
            doctor={doctor.publicURL}
            closeModal={closeModal}
            checked={checked}
          />
        )}
        {option === products.ANTIOX && (
          <FoodSupplement
            product={antiox}
            quote={quote.publicURL}
            doctor={doctor.publicURL}
            star={star.publicURL}
            benefits={benefits}
            inserm={inserm}
            fertility_age={fertility_age}
            closeModal={closeModal}
            checked={checked}
          />
        )}
        {option === products.EQUILIBRE && (
          <FoodSupplement
            product={equilibre}
            quote={quote.publicURL}
            doctor={doctor.publicURL}
            star={star.publicURL}
            benefits={benefits}
            inserm={inserm}
            fertility_age={fertility_age}
            closeModal={closeModal}
            checked={checked}
          />
        )}
      </div>
    </Modal>
  );
}

function PackEssentiel({ product, star, doctor, quote }) {
  const [isInflated, setIsInflated] = useState(false);

  return (
    <div className={`${style.section} ${style.section__presentation}`}>
      <div className={style.flex}>
        <div className={`t2 mb4 lg-hide`} dangerouslySetInnerHTML={{ __html: product.title }} />

        {/** Image */}
        <div className={`${style.image_wrapper}`}>
          <GatsbyImage image={product.image.childImageSharp.gatsbyImageData} alt={product.title} />
        </div>

        <div>
          {/** Heading */}
          <div className={`t2 mb4 xs-hide sm-hide md-hide`} dangerouslySetInnerHTML={{ __html: product.title }} />
          <div className={`t3 mt4 mb3`} dangerouslySetInnerHTML={{ __html: product.subtitle }} />

          <ul>
            {product.items.map((item, key) => (
              <li key={key} className={style.contents_items}>
                <img src={star} className={style.contents_icon} alt="star" />
                <div className={`${style.contents_single_item} p0`} dangerouslySetInnerHTML={{ __html: item }} />
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/** Rea */}
      <div className={`p0 ${style.product_rea}`} dangerouslySetInnerHTML={{ __html: product.rea }} />

      {/** Show more */}
      <div className={style.show_more} role="button" onClick={() => setIsInflated(!isInflated)}>
        <div className="p0"> En savoir plus </div>
        <div className={style.show_more_button}>{isInflated ? <span>-</span> : <span>+</span>}</div>
      </div>

      {/** Detailed composition */}
      {isInflated && (
        <>
          <div className={style.more} dangerouslySetInnerHTML={{ __html: product.more }} />

          {/** Advice from experts */}
          <div className={`p0 ${style.xp__essentiel}`}>
            <div className={style.xp_image_wrapper}>
              <img alt="quote" src={quote} />
            </div>
            <div dangerouslySetInnerHTML={{ __html: product.expertise.content }} />
            <div className={`${style.xp_author}`}>{product.expertise.author}</div>
            <div className={`${style.xp_role}`}>{product.expertise.role}</div>
          </div>

          <div className={style.detailed_compo}>
            <img height="20" src={star} alt="star" />
            <div
              className={`${style.detailed_compo_title} p0 Medium`}
              dangerouslySetInnerHTML={{ __html: product.composition.title }}
            />
          </div>
          <div className={`${style.detailed_compo_details}`}>
            <div>
              <img
                src={product.composition.image.publicURL}
                height="225px"
                width="212px"
                alt={product.composition.title}
              />
            </div>
            <div>
              <div className={style.product_contents__essentiel}>
                <div dangerouslySetInnerHTML={{ __html: product.composition.content_1 }} />
                <div dangerouslySetInnerHTML={{ __html: product.composition.content_2 }} />
              </div>

              {/** Overview of composition */}
              <div className={style.illustration_wrapper}>
                <img src={doctor} height="82px" alt="doctor" />

                <div className={`${style.composition_wrapper}`}>
                  {product.contents.map((ingredient, key) => (
                    <div key={key} className={`p0`}>
                      <span className={`Medium`}>{ingredient.name} : </span> {ingredient.description}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Coaching Presentation */}

          <div className={`${style.presentation}`}>
            <div className={`${style.image_wrapper__presentation}`}>
              <GatsbyImage
                image={product.coaching_presentation.image.childImageSharp.gatsbyImageData}
                alt={product.coaching_presentation.title}
              />
            </div>
            <div>
              <h2>{product.coaching_presentation.title}</h2>
              {product.coaching_presentation.items && (
                <ul>
                  {product.coaching_presentation.items.map((item, key) => (
                    <li key={key} className={style.contents_items}>
                      <img src={star} className={style.contents_icon} alt="star" />
                      <div className={style.contents_single_item}>{item}</div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          {/** App presentation */}
          <div>
            <div className={`${style.presentation}`}>
              <div className={`${style.image_wrapper__presentation}`}>
                <GatsbyImage
                  image={product.app_presentation.image.childImageSharp.gatsbyImageData}
                  alt={product.app_presentation.title}
                />
              </div>
              <div className={style.presentation_content}>
                <h2 className={style.presentation_title}>{product.app_presentation.title}</h2>
                <div className="p0 BlackLighter">
                  {product.app_presentation.items && (
                    <ul>
                      {product.app_presentation.items.map((item, key) => (
                        <li key={key} className={style.contents_items}>
                          <img src={star} className={style.contents_icon} alt="star" />
                          <div className={style.contents_single_item}>{item}</div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <p className={`${style.small} xs-hide sm-hide md-hide`}>{product.app_presentation.small}</p>
          </div>
        </>
      )}
    </div>
  );
}

function CoachingOption({ product, star, doctor, closeModal, checked }) {
  const { setCart } = useContext(CartContext);

  function toggleChecked() {
    if (checked) {
      setCart('remove', 'product', product.name);
    } else {
      setCart('add', 'product', product.name);
    }
    closeModal();
  }

  return (
    <div className={`${style.flex} ${style.section} ${style.section__presentation}`}>
      <div className={`t2 mb4 lg-hide`} dangerouslySetInnerHTML={{ __html: product.title }} />

      {/** Image */}
      <div className={`${style.image_wrapper} ${style.image_wrapper__coaching}`}>
        <GatsbyImage image={product.image.childImageSharp.gatsbyImageData} alt={product.title} />
      </div>

      <div>
        {/** Heading */}
        <div className={`t2 mb4 xs-hide sm-hide md-hide`} dangerouslySetInnerHTML={{ __html: product.title }} />
        <div className={`t3 mt4 mb3`} dangerouslySetInnerHTML={{ __html: product.subtitle }} />

        {/** Price */}
        <div className={style.price_wrapper}>
          <div className={`${style.price} p0`} dangerouslySetInnerHTML={{ __html: product.price }} />
          <button
            className={`Btn Btn--Reg Btn--Circle-Icon Btn--Circle-Icon ${
              checked ? 'Btn--Circle-Icon--Checked BgPurpleLighter' : ''
            }`}
            onClick={toggleChecked}
          >
            Ajouter
          </button>
        </div>

        <ul>
          {product.items.map((item, key) => (
            <li key={key} className={style.contents_items}>
              <img src={star} className={style.contents_icon} alt="star" />
              <div className={`${style.contents_single_item} p0`} dangerouslySetInnerHTML={{ __html: item }} />
            </li>
          ))}
        </ul>

        <div className={style.illustration_wrapper}>
          <img src={doctor} height="50px" className={style.illustration} alt="doctor" />

          <div
            className={`${style.composition_wrapper} BgRedLighter p0`}
            dangerouslySetInnerHTML={{ __html: product.highlight }}
          />
        </div>
      </div>
    </div>
  );
}

function FoodSupplement({ product, quote, doctor, star, benefits, inserm, fertility_age, closeModal, checked }) {
  const [isInflated, setIsInflated] = useState(false);

  const { setCart } = useContext(CartContext);

  function toggleChecked() {
    if (checked) {
      setCart('remove', 'product', product.name);
    } else {
      setCart('add', 'product', product.name);
    }
    closeModal();
  }

  return (
    <section className={`${style.section} ${style.section__options}`}>
      <div className={style.flex}>
        {/** Image */}

        <div className={`t2 mb4 lg-hide`}>
          Complément optionnel <span className={`Red ${style.product_title}`}>{product.title}</span>
        </div>

        <div className={`${style.image_wrapper} ${style.image_wrapper__options}`}>
          <GatsbyImage image={product.image.childImageSharp.gatsbyImageData} alt={product.title} />
        </div>

        <div>
          {/** Heading */}
          <div className={`t2 mb4 xs-hide sm-hide md-hide`}>
            Complément optionnel <span className={`Red ${style.product_title}`}>{product.title}</span>
          </div>
          <div className={`t3`} dangerouslySetInnerHTML={{ __html: product.subtitle }} />

          {/** Price */}
          <div className={style.price_wrapper}>
            <div className={`${style.price} p0`} dangerouslySetInnerHTML={{ __html: product.price }} />
              <button
                className={`Btn Btn--Reg Btn--Circle-Icon Btn--Circle-Icon ${
                  checked ? 'Btn--Circle-Icon--Checked BgPurpleLighter' : ''
                }`}
                onClick={toggleChecked}
              >
                Ajouter
              </button>
          </div>
        </div>
      </div>

      {/** Rea */}
      <div className={`p0 ${style.product_rea}`} dangerouslySetInnerHTML={{ __html: product.rea }} />

      {/** Show more */}
      <div className={style.show_more} role="button" onClick={() => setIsInflated(!isInflated)}>
        <div className="p0"> En savoir plus </div>
        <div className={style.show_more_button}>{isInflated ? <span>-</span> : <span>+</span>}</div>
      </div>

      {/** Detailed composition */}
      {isInflated && (
        <>
          <div className={style.more} dangerouslySetInnerHTML={{ __html: product.more }} />

          {/** Advice from experts */}
          <div className={`p0 ${style.xp} ${style.xp__options}`}>
            <div className={`${style.image_wrapper} ${style.image_wrapper_author__options} `}>
              <GatsbyImage
                image={product.expertise.image.childImageSharp.gatsbyImageData}
                alt={product.expertise.author}
              />
            </div>
            <div>
              <div className={style.xp_image_wrapper}>
                <img alt="quote" src={quote} />
              </div>
              <div dangerouslySetInnerHTML={{ __html: product.expertise.content }} />
              <div className={`${style.xp_author}`}>{product.expertise.author}</div>
              <div className={`${style.xp_role}`}>{product.expertise.role}</div>
            </div>
          </div>

          <div className={style.detailed_compo}>
            <img height="20" src={star} alt="star" />
            <div className={`${style.detailed_compo_title} p0 Uppercase Medium`}>
              Composition détaillée complément alimentaire
              <span className="Red"> {product.composition.title} </span>
            </div>
          </div>
          <div className={`${style.detailed_compo_details}`}>
            <div>
              <img
                src={product.composition.image.publicURL}
                className={style.detailed_compo_img}
                alt={product.composition.title}
              />
            </div>
            <div>
              <div
                className={`${style.product_contents__options} p0`}
                dangerouslySetInnerHTML={{ __html: product.composition.content }}
              />
              {/** Overview of composition */}
              <div className={style.illustration_wrapper}>
                <img src={doctor} height="82px" alt="doctor" />

                <div className={`${style.composition_wrapper} BgRedLighter`}>
                  {product.contents.map((ingredient, key) => (
                    <div key={key} className={`p0`}>
                      <span className={`Medium`}>{ingredient.name} : </span> {ingredient.description}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className={`${style.spacer}`} />

          {product.name === 'antiox' && <FertilityAge fertility_age={fertility_age} />}
          {product.name === 'equilibre' && <Benefits benefits={benefits} star={star} inserm={inserm} />}
        </>
      )}
    </section>
  );
}

function FertilityAge({ fertility_age }) {
  return (
    <div className={`${style.section__benefits} BgRedLighter`}>
      <h2 className={style.benefits_title}>{fertility_age.title}</h2>

      <div className={`${style.flex} ${style.flex__benefits}`}>
        <div className={style.image_wrapper}>
          <GatsbyImage
            image={fertility_age.image.childImageSharp.gatsbyImageData}
            alt={fertility_age.title}
            objectPosition="0% 0%"
            className={style.benefits_image}
          />
        </div>

        <div className={`p0 BlackLighter`} dangerouslySetInnerHTML={{ __html: fertility_age.content }} />
      </div>
    </div>
  );
}

function Benefits({ benefits, star, inserm }) {
  return (
    <div className={`${style.section__benefits} BgRedLighter`}>
      <h2 className={style.benefits_title}>{benefits.title}</h2>

      <div className={`${style.flex} ${style.flex__benefits}`}>
        <div className={style.image_wrapper}>
          <GatsbyImage
            image={benefits.image.childImageSharp.gatsbyImageData}
            alt={benefits.title}
            objectPosition="0% 0%"
            className={style.benefits_image}
          />

          <GatsbyImage
            image={inserm.image.childImageSharp.gatsbyImageData}
            alt={'inserm'}
            className={style.inserm_image}
          />

          <div className="p0" dangerouslySetInnerHTML={{ __html: inserm.content }} />
        </div>

        <div>
          <h3 className={style.benefits_subtitle}>{benefits.subtitle}</h3>

          <ul>
            {benefits.items.map((item, key) => (
              <li key={key} className={`${style.contents_item__benefits} p0`}>
                <img className={style.contents_icon} src={star} alt="star" />
                <div className={style.contents_single_item} dangerouslySetInnerHTML={{ __html: item }} />
              </li>
            ))}
          </ul>

          <h3 className={style.benefits_subtitle}>{benefits.subtitle_2}</h3>

          <div className="p0" dangerouslySetInnerHTML={{ __html: benefits.content }} />
        </div>
      </div>
    </div>
  );
}

export default CartPopupOptions;
