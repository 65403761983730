import React from 'react';

// Layout
import Layout from '../components/layout';
// React Helmet SEO
import SEO from '../components/seo';

import { strip_html } from '../functions';

import style from '../modules/legal.module.scss';

function Page({ pageContext }) {
  return (
    <Layout>
      <SEO
        title={`${pageContext.title} | Legal | BeMum`}
        description={`${strip_html(pageContext.content).slice(0, 150)}...`}
        canonical={`https://www.bemum.co/${pageContext.slug}/`}
      />

      {/* Header */}
      <header className={style.Header}>
        <div className={`wrapper`}>
          <div className={`grid`}>
            <div className={`grid__item shmd1 shlg1 cmd8 clg8`}>
              <h1 className={`t1 mb3`}>{pageContext.title}</h1>
              {pageContext.tagline && (
                <div className={`t3`} dangerouslySetInnerHTML={{ __html: pageContext.tagline }} />
              )}
            </div>
          </div>
        </div>
      </header>

      <div className={`wrapper`}>
        <div className={style.Content}>
          <div className={`grid`}>
            <div className={`grid__item shmd1 shlg1 cmd10 clg10`}>
              <div className={`p1`} dangerouslySetInnerHTML={{ __html: pageContext.content }} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Page;
