import React from 'react';
import { Link } from 'gatsby';

/**
 * Navigation link, handles external links
 * @param {*} props Link props
 */
export default function (props) {
  let newProps = { ...props };
  delete newProps.extern;

  if (props.extern) {
    delete newProps.to;
    delete newProps.activeClassName;
    newProps.href = props.to;
    newProps.target = '_blank';
    newProps.rel = 'noreferrer';

    return <a {...newProps}>{props.children}</a>;
  }

  return <Link {...newProps} />;
}
