import React, { useContext, useState, useEffect, useRef } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styles from './product.module.scss';
import CartContext from '../../context/CartContext';

const Product = ({ item, showPlusIcon, plusIconURL, star_icon }) => {
  const { cart, setCart } = useContext(CartContext);
  const [isFirstPlusIcon, setIsFirstPlusIcon] = useState(false);
  const plusIconRef = useRef(null);
  const [showNotification, setShowNotification] = useState(false);

  const isChecked = cart.contents.items.some((cartItem) => cartItem.id === item.id);
  const buttonText = item.id === 'essentiel' ? 'Ajouté' : isChecked ? 'Ajouté' : 'Ajouter';

  useEffect(() => {
    if (showPlusIcon && plusIconRef.current) {
      const plusIcons = document.querySelectorAll(`.${styles.recPlusIconContainer}`);
      if (plusIcons[0] === plusIconRef.current) {
        setIsFirstPlusIcon(true);
      }
    }
  }, [showPlusIcon]);

  function toggleChecked() {
    if (item.id === 'essentiel') {
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 2000);
      return;
    }
  
    if (isChecked) {
      setCart('remove', 'product', item.id);
    } else {
      setCart('add', 'product', item.id);
    }
  }
  

  return (
    <>
      {showNotification && (
        <div className={styles.notification}>
          Nos Essentiels ne peuvent pas être retirés du panier.
        </div>
      )}

      <div className={styles.recContent}>
        <div>
          <GatsbyImage image={item.image.childImageSharp.gatsbyImageData} className={styles.recContentImage} />
        </div>
        <div className={styles.recContentInfo}>
          <h3>{item.title}</h3>
          <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
          {item.options && (
            <ul>
              {item.options.map((option, key) => (
                <li key={key} className={styles.contents_items}>
                  <div className={styles.contents_icon} dangerouslySetInnerHTML={{ __html: star_icon }} />
                  <div className={styles.contents_single_item} dangerouslySetInnerHTML={{ __html: option }} />
                </li>
              ))}
            </ul>
          )}
          <div className={styles.recButtonContainer}>
            <button
              onClick={toggleChecked}
              className={`Btn Btn--Reg Btn--Circle-Icon ${
                isChecked || item.id === 'essentiel' ? `${styles.black} Btn--Circle-Icon--Checked ` : ''
              } ${styles.button}`}
            >
              {item.price} €&nbsp;<span>{buttonText}</span>
            </button>
          </div>
        </div>
      </div>
      {showPlusIcon && (
        <div
          className={`${styles.recPlusIconContainer} ${isFirstPlusIcon ? styles.firstPlusIcon : ''}`}
          ref={plusIconRef}
        >
          <GatsbyImage image={plusIconURL} className={styles.recPlusIcon} />
        </div>
      )}
    </>
  );
};

export default Product;
