import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useCallback, useMemo, useState } from 'react';
// Layout
import Layout from '../components/layout';
import Ellipses from '../components/repeatable/ellipses';
// React Helmet SEO
import SEO from '../components/seo';
import style from '../modules/temoignages.module.scss';

function IndexPage({ data }) {
  const {
    pageTemoignagesYaml: { title, seo, content, image, image_mobile, quote, star, testimonies, diets },
  } = data;

  const [toogleMore, setToogleMore] = useState(false);
  const toogleMoreClickHandler = useCallback(() => setToogleMore(!toogleMore), [toogleMore, setToogleMore]);
  const toogleMoreText = useMemo(() => `En voir ${toogleMore ? 'moins' : 'davantage'}`, [toogleMore]);
  const newestTestimonies = testimonies.slice();

  return (
    <Layout>
      <SEO title={seo.title} description={seo.description} canonical={seo.canonical} />

      <header className={`${style.header}`}>
        <div className={style.header_img}>
          <img className="xs-hide sm-hide md-hide" src={image.publicURL} alt={title} />
          <img className="lg-hide" src={image_mobile.publicURL} alt={title} />
        </div>

        <div className={`${style.header_title_wrapper}`}>
          <h1 className={`t1 mb2 ${style.header_title}`} dangerouslySetInnerHTML={{ __html: title }} />
        </div>
      </header>

      <div className={style.section}>
        <Ellipses top={'0'} right={'0'} rotate />
        <Ellipses bottom={'150px'} left={'-120px'} />
        <div className={style.flex}>
          <div className={style.diets}>
            {diets
              .filter((item, key) => {
                if (!toogleMore) return key < 2;
                return true;
              })
              .map((item, key) => (
                <Diet
                  key={key}
                  name={item.name}
                  picture={item.picture.childImageSharp.gatsbyImageData}
                  tags={item.tags}
                  description={item.text}
                  star={star.publicURL}
                />
              ))}
          </div>
          <div className={style.testimonials}>
            <div className={`${style.header_content}`} dangerouslySetInnerHTML={{ __html: content }} />
            {newestTestimonies
              .reverse()
              .filter((item, key) => {
                if (!toogleMore) return key < 6;
                return true;
              })
              .map((item, key) => (
                <Testimonial
                  key={key}
                  name={item.name}
                  avatar={item.picture && item.picture.childImageSharp.gatsbyImageData}
                  text={item.description}
                  quote={quote.childImageSharp.gatsbyImageData}
                />
              ))}
          </div>
        </div>

        <div className="Center">
          <button
            className={`Btn Btn--Reg ${style.button} ${toogleMore ? style.button__rotated : ''}`}
            onClick={toogleMoreClickHandler}
          >
            {toogleMoreText}
          </button>
        </div>
      </div>
    </Layout>
  );
}

/**
 * UI Component representing a diet description
 *
 * @param {*} props
 * @param {string} props.name Name of the diet
 * @param {import('gatsby-image').FluidObject} props.picture Picture of the diet
 * @param {string} props.tags Tags for the diet
 * @param {string} props.description Description of the diet
 * @param {import('gatsby-image').publicURL} props.star Star image
 *
 */
function Diet({ name, picture, tags, description, star }) {
  return (
    <div className={style.diet_wrapper}>
      <div className={style.diet}>
        <img src={star} height="30" alt="star" />

        <div className={`mb3`}>
          <div className={style.diet_picture}>
            <GatsbyImage image={picture} alt={name} />
          </div>

          <div className={style.diet_name}>{name}</div>

          <div className={style.diet_tags}>
            {/* Hide Tags cf issue bemum#1683 */}
            {/* {tags && (
              <ul className={`${style.tag_wrapper}`}>
                {tags.map((tag, i) => (
                  <li key={i} className={`mb1`}>
                    <p className={`Center Black Regular ${style.tag}`}>{tag.value}</p>
                  </li>
                ))}
              </ul>
            )} */}
          </div>

          <div className={style.diet_description} dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        <img src={star} height="30" className={style.star_bottom} alt="star" />
      </div>
    </div>
  );
}

/**
 * UI Component representing a person giving a stestimonial
 *
 * @param {*} props
 * @param {string} props.name Name of the person
 * @param {import('gatsby-image').FixedObject} props.avatar Picture of the person
 * @param {string} props.text Contents of the testimonial
 * @param {import('gatsby-image').FixedObject} props.quote Quote image
 *
 */
function Testimonial({ name, avatar, text, quote }) {
  return (
    <div className={style.testimonial}>
      <GatsbyImage image={quote} alt="quote" className={`lg-hide`} />

      {/** PERSON AVATAR */}
      <div className={style.testimonial_avatar}>
        <GatsbyImage image={avatar} alt={name} />
      </div>

      <div className={style.testimonial_contents}>
        {/** PERSON TEXT */}
        <div className={`BlackLighter`}>
          <GatsbyImage image={quote} alt="quote" className={`xs-hide sm-hide md-hide ${style.testimonial_quote}`} />
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </div>

        {/** PERSON DETAILS */}
        <div className={`${style.testimonial_name} Regular`}>{name}</div>
      </div>

      <GatsbyImage image={quote} className={`lg-hide ${style.testimonial_quote_bottom}`} />
    </div>
  );
}

export default IndexPage;

export const query = graphql`
  {
    pageTemoignagesYaml {
      title
      seo {
        title
        description
        canonical
      }
      image {
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      image_mobile {
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      content
      quote {
        childImageSharp {
          gatsbyImageData(width: 54, placeholder: NONE, layout: FIXED)
        }
      }
      star {
        publicURL
      }
      testimonies {
        title
        description
        name
        picture {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
      }
      diets {
        name
        text
        tags {
          value
        }
        picture {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
