/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({
  description,
  lang,
  meta,
  title,
  canonical,
  product,
  program,
  productId,
  imageLink,
  productPrice,
  facebookDescription,
  noindex,
  appleSmartBanner
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  let metaArray = [];
  if (appleSmartBanner) {
    metaArray.push({
      name: 'apple-itunes-app',
      content: 'app-id=1670087295, app-argument=https://podcasts.apple.com/ma/podcast/%C3%A0-l%C3%A9coute-de-votre-fertilit%C3%A9/id1670087295?l=fr'
    })
  }


  metaArray.push(
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: facebookDescription ? facebookDescription : metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  )
  
  metaArray.concat(meta);

  if (product || program) {
    metaArray = [
      {
        property: `product:retailer_item_id`,
        content: `${program ? 'programme-' : ''}${productId}`,
      },
      {
        property: `og:image`,
        content: `${
          program ? 'https://bemum-landing-assets.s3.eu-west-3.amazonaws.com/pack-essentiel.png' : imageLink
        }`,
      },
      {
        property: `product:price:amount`,
        content: productPrice,
      },
      {
        property: `product:price:currency`,
        content: `EUR`,
      },
      {
        property: `product:availability`,
        content: `in stock`,
      },
      {
        property: `og:url`,
        content: `https://www.bemum.co/${program ? 'mon' : ''}programme/${productId}`,
      },
    ].concat(metaArray);
  }

  if (noindex) {
    metaArray.push({
      name: 'robots',
      content: 'noindex',
    });
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={metaArray}
    >
      {canonical && <link rel="canonical" href={canonical} />}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
