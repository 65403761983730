import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { dataEdge } from '../../functions';
import style from './modules/team.module.scss';

/**
 * Team BeMum
 */
function Team({ data }) {
  const { allStaticTeamYaml } = data;
  const {
    node: { image, content, ceo, compagny, quote },
  } = dataEdge(allStaticTeamYaml);

  return (
    <div className={style.wrapper}>
      <div className={style.teamWrapper}>
        <div className={style.text}>
          <div className={style.quote_1}>
            <GatsbyImage image={quote.childImageSharp.gatsbyImageData} alt="quote" />
          </div>
          <div className={style.ceo_text} dangerouslySetInnerHTML={{ __html: content }} />
          <div className={style.quote_2}>
            <GatsbyImage image={quote.childImageSharp.gatsbyImageData} alt="quote" />
          </div>
        </div>
        <div className={style.container_ceo}>
          <div className={style.imageWrapper}>
            <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={ceo} />
          </div>
          <div>
            <p className={style.author}>{ceo}</p>
            <p className={style.authortitle}>{compagny}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * UI Component representing a person giving a testimonial
 * @param {import('gatsby-image').FixedObject} props.quote Quote image
 */

export default function (props) {
  return (
    <StaticQuery
      render={(data) => <Team data={data} {...props} />}
      query={graphql`
        {
          allStaticTeamYaml {
            edges {
              node {
                content
                ceo
                compagny
                quote {
                  childImageSharp {
                    gatsbyImageData(width: 54, placeholder: NONE, layout: FIXED)
                  }
                }
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      `}
    />
  );
}
