import PropTypes from 'prop-types';
import React, { useState } from 'react';
import questionDefinitionProptypes from '../question-definition.proptypes';
import styles from './radio.module.scss';
import blinker from '../../../modules/animations/blinker.module.scss';

/**
 * Question of type "radio"
 * @param {Object} props
 * @param {import("./question").Definition} props.definition - Interlude question definition
 * @param {function} props.change - event handler
 * @param {*} props.value - input value
 */
function Radio({ definition, change, value }) {
  const [isBlinking, setIsBlinking] = useState(false);

  let detail;
  for (const option of definition.options) {
    if (option.value === value && option.detail) {
      detail = option.detail;
    }
  }

  const containsHover = definition.options.some((option) => option.hover);
  const containsDetail = definition.options.some((option) => option.detail);

  /**
   * Make the input label blink before
   * bubbling the event upstream
   *
   * @param {Event} event
   */
  function onClick(event) {
    const { name, value } = event.target;
    // Only radio question answer with detail info
    if (!(name === 'oily_fish' && value === '3+') && !(name === 'gdpr')) {
      setIsBlinking(true);
      setTimeout(() => {
        setIsBlinking(false);
      }, 300);
    }
    change(event);
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <h2>{definition.title}</h2>
      </div>
      <div className={styles.optionsWrapper}>
        {definition.options.map((option, index) => (
          <div key={index} className={`${styles.option}`}>
            <input
              type="radio"
              name={definition.name}
              value={option.value}
              checked={option.value === value}
              id={`${definition.name}-${index}`}
              onClick={onClick}
              onChange={() => {}}
            />
            <label
              className={value === option.value && isBlinking ? blinker.blink : null}
              htmlFor={`${definition.name}-${index}`}
            >
              {option.label}
              {option.helper && <span className={`p2 BlackLight Block Center`}>{option.helper}</span>}
            </label>
          </div>
        ))}
      </div>
      {(containsHover || containsDetail) && <p className={`${styles.detail} p1`}>{detail ? detail : ''}</p>}

      {definition.helper && (
        <div className={`${styles.detail} Center`} dangerouslySetInnerHTML={{ __html: definition.helper }}></div>
      )}
    </div>
  );
}
export default Radio;

Radio.propTypes = {
  definition: questionDefinitionProptypes.isRequired,
  change: PropTypes.func.isRequired,
  value: PropTypes.any,
};
