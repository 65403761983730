import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from './podcast-card.module.scss';
import AudioPlayer from './podcast-audio-player';

const Podcast = ({ season, seasons }) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const shortenText = (text) => {
    if (text.length <= 300) {
      return text;
    }
    const shortened = text.substr(0, 300);
    // Find the index of the last period before the 300-character limit
    const lastDotIndex = shortened.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return shortened + '...';
    }
    // Check if the sentence is cut off or not
    const nextChar = shortened.charAt(lastDotIndex + 1);
    if (nextChar === ' ' || nextChar === '\n') {
      return shortened.substr(0, lastDotIndex + 1) + '...';
    } else {
      return shortened + '...';
    }
  };

  return (
    <div className={styles.cardContainer}>
      {season === seasons.title ? (
        seasons.podcasts.sort((a, b) => b.id - a.id).map((podcast) => (
          <div>
            <div className={styles.podcast}>
              <div className={styles.imageContainer}>
                <GatsbyImage image={podcast.image.childImageSharp.gatsbyImageData} alt={podcast.title} />
              </div>
              <div className={styles.info}>
                <h2 className={styles.title}>{podcast.title}</h2>

                <p className={styles.description}>
                  {showFullText ? podcast.description : shortenText(podcast.description)}
                  {podcast.description.split('.').length > 1 && podcast.description.length > 300 && (
                    <span className={styles.readmore} onClick={toggleText}>
                      {showFullText ? 'Afficher moins' : 'Lire la suite'}
                    </span>
                  )}
                </p>
                <span className={styles.date}>{podcast.date}</span>
              </div>
              <div className={styles.audioContainer}>
                <AudioPlayer key={podcast.id} podcast={podcast} />
              </div>
            </div>
            <hr className={styles.divider}/>
          </div>
        ))
      ) : null}
    </div>
  );
};

export default Podcast;
