import { GatsbyImage } from "gatsby-plugin-image";
import React from 'react';
import QuestionDefinition from '../question-definition.proptypes';
import styles from './hello.module.scss';

/**
 * Question of type "hello"
 * @param {Object} props
 * @param {import("./question").Definition} props.definition - Hello question definition
 */
function Hello({ definition }) {
  return (
    <div className={styles.wrapper}>
      {definition.image && (
        <GatsbyImage
          image={definition.image.childImageSharp.gatsbyImageData}
          className={styles.image}
          alt={definition.name} />
      )}
      <h2 className={styles.title}>{definition.title}</h2>
      {definition.helper && (
        <div className={`${styles.helper} p0`} dangerouslySetInnerHTML={{ __html: definition.helper }}></div>
      )}
      {definition.expertise && (
        <div className={styles.proTips}>
          <h3 className={`p0 Diag_Expertise_Title`}>{definition.expertise.title}</h3>
          <p className={`p1 ${styles.content}`}>{definition.expertise.content}</p>
        </div>
      )}
    </div>
  );
}
export default Hello;

Hello.propTypes = {
  definition: QuestionDefinition.isRequired,
};
