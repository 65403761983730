import React from 'react';
import style from '../modules/banner.module.scss';


function BannerContent() {
  return(
      <div className={style.single_banner}>
        <svg xmlns="http://www.w3.org/2000/svg" width="32.338" height="31.255" viewBox="0 0 32.338 31.255" className={style.banner_svg}>
          <g id="ast" transform="translate(-154 -246)">
            <path id="Path_263" data-name="Path 263" d="M0,0C10.755,0,19.473,9.341,19.473,20.864" transform="translate(165.473 277.255) rotate(-90)" fill="none" stroke="#2f1212" strokeWidth="1"/>
            <path id="Path_265" data-name="Path 265" d="M0,20.864c10.755,0,19.473-9.341,19.473-20.864" transform="translate(186.338 246) rotate(90)" fill="none" stroke="#2f1212" strokeWidth="1"/>
            <path id="Path_264" data-name="Path 264" d="M0,19.473A19.473,19.473,0,0,0,19.473,0" transform="translate(154 277.255) rotate(-90)" fill="none" stroke="#2f1212" strokeWidth="1"/>
            <path id="Path_266" data-name="Path 266" d="M0,0A19.473,19.473,0,0,1,19.473,19.473" transform="translate(173.473 246) rotate(90)" fill="none" stroke="#2f1212" strokeWidth="1"/>
          </g>
        </svg>

        <span className={style.banner_single_content}>30€ offerts le 1er mois </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="32.338" height="31.255" viewBox="0 0 32.338 31.255" className={`${style.banner_svg} lg-hide`}>
          <g id="ast" transform="translate(-154 -246)">
            <path id="Path_263" data-name="Path 263" d="M0,0C10.755,0,19.473,9.341,19.473,20.864" transform="translate(165.473 277.255) rotate(-90)" fill="none" stroke="#2f1212" strokeWidth="1"/>
            <path id="Path_265" data-name="Path 265" d="M0,20.864c10.755,0,19.473-9.341,19.473-20.864" transform="translate(186.338 246) rotate(90)" fill="none" stroke="#2f1212" strokeWidth="1"/>
            <path id="Path_264" data-name="Path 264" d="M0,19.473A19.473,19.473,0,0,0,19.473,0" transform="translate(154 277.255) rotate(-90)" fill="none" stroke="#2f1212" strokeWidth="1"/>
            <path id="Path_266" data-name="Path 266" d="M0,0A19.473,19.473,0,0,1,19.473,19.473" transform="translate(173.473 246) rotate(90)" fill="none" stroke="#2f1212" strokeWidth="1"/>
          </g>
        </svg>
      </div>
  )
}

export default function Banner({ funnel }) {

  return (
    <div className={`${style.banner} ${funnel ? style.funnel : ''} Center BgPurpleLighter`}>
      <div className={`${style.banner_content} xs-hide sm-hide md-hide`}>
        <BannerContent />
        <BannerContent />
        <BannerContent />
        <BannerContent />
        <BannerContent />
        <BannerContent />
        <BannerContent />
        <BannerContent />
        <BannerContent />
        <BannerContent />
        <BannerContent />
        <BannerContent />
        <BannerContent />
        <BannerContent />
        <BannerContent />
        <BannerContent />
      </div>

      <div className={`${style.banner_content} lg-hide`}>
        <BannerContent />
      </div>
    </div>
  );
}
