import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

import linkResolver from '../../../linkResolver';
import style from './card.module.scss';

/**
 * Post card
 */
const Post = ({ uid, type, lang, data }) => {
  const categoryDoc = data.category || {};
  const categoryData = categoryDoc.document || {};
  const category = categoryData.data || {};

  const authorDoc = data.author || {};
  const authorData = authorDoc.document || {};
  const author = authorData.data || {};

  const categoryLink = linkResolver({
    uid: categoryData.uid,
    type: `category`,
    lang,
  });

  return (
    <div className={`grid-flex__item csm6 cmd4 clg4`}>
      <article className={style.PostCard} itemScope itemType={`http://schema.org/Article`}>
        <div className={`Relative`}>
          <Link itemProp={`url`} to={linkResolver({ uid, type, lang })}>
            <img
              src={data.featured_image.url}
              className={`${style.PostCard__Img} mb4`}
              alt={data.title.text}
              itemProp={`image`} />
          </Link>
          <Link itemProp={`url`} to={`${categoryLink}/1/`}>
            <h3 className={`mb2`}>{category.name.text}</h3>
          </Link>
          <Link itemProp={`url`} to={linkResolver({ uid, type, lang })}>
            <h4 itemProp={`name`} className={`mb1`}>
              {data.title.text}
            </h4>
            <div className={`mb3 ${style.PostCard__Content}`}>{data.short_content.text}</div>
          </Link>
          <div className={style.Author}>
            <div>
                <img src={author.photo.url} className={style.Author__Img}
                alt={author.name.text}/>
            </div>
            <div>
              <h5 itemProp={`author`}>{author.name.text}</h5>
              <time itemProp={`datePublished`}>{data.date_published}</time>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

export const query = graphql`
  fragment PostShort on PrismicPost {
    uid
    type
    lang
    data {
      title {
        text
      }
      short_content {
        text
      }
      featured_image {
        url
        fluid {
          base64
          src
          sizes
          srcSet
          aspectRatio
        }
      }
      date_published(formatString: "DD MMMM Y", locale: $lang)
      author {
        document {
          ... on PrismicAuthor {
            id
            data {
              name {
                text
              }
              photo {
                url
                fluid {
                  base64
                  src
                  sizes
                  srcSet
                  aspectRatio
                }
              }
            }
          }
        }
      }
      category {
        document {
          ... on PrismicCategory {
            id
            uid
            data {
              name {
                text
              }
            }
            uid
          }
        }
      }
    }
  }
`;

export default Post;
