import PropTypes from 'prop-types';
import React from 'react';
import questionDefinitionProptypes from '../question-definition.proptypes';
import styles from './range.module.scss';

/**
 * Question of type "range"
 * @param {Object} props
 * @param {import("./question").Definition} props.definition - Interlude question definition
 * @param {function} props.change - event handler
 * @param {*} props.value - input value
 */
function Range({ definition, change, value }) {
  return (
    <div className={styles.wrapper}>
      <div>
        <h2>{definition.title}</h2>
      </div>
      <div className={styles.rangeWrapper}>
        <div className={`${styles.Range}`}>
          <input
            onChange={change}
            name={definition.name}
            type="range"
            min={definition.range.min}
            max={definition.range.max}
            step={definition.range.step}
            value={value}
          />
          <ul className={`${styles.tickmarks}`}>
            {Array.from(Array(definition.range.max + 1), (value, index) => (
              <li key={index}></li>
            ))}
          </ul>
          <ul className={`${styles.rangeLabels}`}>
            {definition.range.labels.map((label, index) => (
              <li key={index} className="p0">
                {label}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Range;

Range.propTypes = {
  definition: questionDefinitionProptypes.isRequired,
  change: PropTypes.func.isRequired,
  value: PropTypes.any,
};
