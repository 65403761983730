import FertilityTestCta from './fertility-test-cta';
import * as Collapsible from '@radix-ui/react-collapsible';
import { useStaticQuery, graphql } from 'gatsby';
import styles from './navigation.module.scss';
import Link from '../utils/link';
import Menu from './menu';
import { keyframes, styled } from '@stitches/react';
import React, { useState, useEffect } from 'react';

export default function Navigation() {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allStaticNavbarYaml {
        nodes {
          caret {
            desktop {
              img {
                publicURL
              }
              img_red {
                publicURL
              }
              alt
            }
          }
          navbar {
            color
            link
            text
            img {
              publicURL
            }
            alt
            items {
              color
              link
              text
              img {
                publicURL
              }
              extern
              alt
            }
          }
          logo {
            alt
            img {
              publicURL
            }
          }
          account {
            img {
              publicURL
            }
            alt
          }
          mobileMenu {
            alt
            img {
              publicURL
            }
          }
          closemobileMenu {
            alt
            img {
              publicURL
            }
          }
        }
      }
    }
  `);

  const Trigger = styled('button', {
    '@media only screen and (min-width: 1020px)': {
      display: 'none',
    },
    padding: '0 20px',
  });

  const MyBeMum = styled('a', {
    '@media only screen and (min-width: 1020px)': {
      display: 'none',
    },
  });

  const open = keyframes({
    from: { height: 0 },
    to: { height: '100vh' },
  });

  const close = keyframes({
    from: { height: '100vh' },
    to: { height: 0 },
  });
  const CollapsibleContent = styled(Collapsible.Content, {
    overflow: 'hidden',
    position: 'fixed',
    zIndex: 8,
    width: '100%',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    '&[data-state="open"]': { animation: `${open}` },
    '&[data-state="closed"]': { animation: `${close}` },
  });

  const StyledCaret = styled('img', {
    position: 'relative',
    marginLeft: '7px',
    transform: 'rotate(180deg)',
    top: 1,
    width: '25px',
    height: '14px',
  });

  const StyledDiv = styled('div', {
    fontWeight: 500,
    fontFamily: 'Americana',
    textUnderlineOffset: '0.8rem',
    letterSpacing: '0.64px',
    fontSize: '20px',
    lineHeight: '42px',
  });

  const StyledTriggerWithCaret = React.forwardRef(({ children, ...props }, forwardedRef) => (
    <StyledDiv {...props} ref={forwardedRef}>
      {children}

      <StyledCaret
        aria-hidden
        src={data.allStaticNavbarYaml.nodes[0].caret.desktop.img_red.publicURL}
        alt={data.allStaticNavbarYaml.nodes[0].caret.desktop.img_red.alt}
      />
    </StyledDiv>
  ));

  const NavigationMenuTrigger = StyledTriggerWithCaret;
  
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
  setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Collapsible.Root>
      <div
        style={{
          position: 'fixed',
          top: 0,
          zIndex: 9,
          background: 'white',
          width: '100vw',
          boxShadow: '0px 3px 25px #0000000D',
        }}
      >
        <div>
          <div
            style={{
              display: 'flex',
              height: '80px',
              alignItems: 'center',
              backgroundColor: 'white',
              width: '100%',
              paddingLeft: '20px',
            }}
          >
            <Link to="/">
              <img
                src={data.allStaticNavbarYaml.nodes[0].logo.img.publicURL}
                alt={data.allStaticNavbarYaml.nodes[0].logo.alt}
              />
            </Link>

            <div style={{ flexGrow: 1 }}></div>

            <Menu />
            <FertilityTestCta label="TEST DE FERTILITÉ" />
            <MyBeMum href="https://my.bemum.co" target="_blank" rel="noreferrer">
              <img
                style={{ width: '24px' }}
                src={data.allStaticNavbarYaml.nodes[0].account.img.publicURL}
                alt={data.allStaticNavbarYaml.nodes[0].account.alt}
              />
            </MyBeMum>
            <Collapsible.Trigger asChild>
            <Trigger onClick={toggleMenu}>
              <img
                style={{ width: '25px' }}
                src={isMenuOpen 
                  ? data.allStaticNavbarYaml.nodes[0].closemobileMenu.img.publicURL 
                  : data.allStaticNavbarYaml.nodes[0].mobileMenu.img.publicURL}
                alt={isMenuOpen 
                  ? data.allStaticNavbarYaml.nodes[0].closemobileMenu.alt 
                  : data.allStaticNavbarYaml.nodes[0].mobileMenu.alt}
              />
            </Trigger>
          </Collapsible.Trigger>
          </div>
        </div>
      </div>

      <CollapsibleContent>
        <div className={styles.wrapper}>
          <ul className={styles.ul_list}> 
            {data.allStaticNavbarYaml.nodes[0].navbar
              .filter((item) => item.text !== 'Mon espace')
              .map((item, index) => (
                <li className={styles.li_item} key={index}>
                  {item.link && (
                    <StyledDiv style={{ marginBottom: '10px' }}>
                      <Link style={{ color: item.color }} to={item.link}>
                        <span style={{ whiteSpace: 'nowrap' }}>{item.text}</span>
                      </Link>
                    </StyledDiv>
                  )}
                  {item.items && (
                    <div>
                      <NavigationMenuTrigger
                        style={{
                          color: item.color,
                        }}
                      >
                        {item.text}
                      </NavigationMenuTrigger>
                      <ul>
                        {item.items.map((i, key) => (
                          <li key={key}>
                            <Link
                              style={{ color: i.color, display: 'flex', alignItems: 'center', marginTop: '12px' }}
                              to={i.link}
                              extern={i.extern}
                            >
                              {i.img && (
                                <img
                                  src={i.img.publicURL}
                                  alt={i.alt}
                                  style={{ marginRight: '17px', width: '16px', height: '25px' }}
                                />
                              )}
                              <span> {i.text} </span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
          </ul>
        </div>
        <FertilityTestCta label="FAIRE MON TEST DE FERTILITÉ" display="flex" justifyContent="center" />
      </CollapsibleContent>
    </Collapsible.Root>
  );
}
