import PropTypes from 'prop-types';
import React from 'react';
import Link from '../../components/utils/link';
import styles from './header.module.scss';
import BackButton from './back-button';

/**
 * header of the fertility test
 * @param {Object} props - current test state
 * @param {number} props.questionIndex - index of the current question
 * @param {number} props.questionCount - total number of questions
 */
function Header({ questionIndex, questionCount, onBackClick }) {
  const logo = (
    <svg width="15" height="15" viewBox="0 0 15 15">
      <path
        d="M1.033 14.468l6.48-6.48 6.467 6.467.475-.475-6.467-6.466 6.48-6.48-.528-.529-6.48 6.48L1.02.546l-.475.475 6.44 6.44-6.48 6.48z"
        fill="#000"
        fillRule="nonzero"
      />
    </svg>
  );

  return (
    <header className={styles.Header}>
      <div className="wrapper">
        <div className={styles.HeaderWrapper}>
          <div className={styles.backButtonWrapper}>
            <BackButton onClick={onBackClick} questionIndex={questionIndex} />
          </div>
          <Link className={`${styles.Logo}`} to={`/`} />
          <Link className={`${styles.Cross}`} to={`/test-fertilite/`}>
            {logo}
          </Link>
        </div>
      </div>
      <span className={`${styles.ProgressWrapper}`} aria-hidden="true">
        <span
          className={`${styles.Progress}`}
          style={{ transform: `scale(${(questionIndex + 1) / questionCount}, 1)` }}
          aria-hidden="true"
          aria-label="progress"
        />
      </span>
    </header>
  );
}
export default Header;

Header.defaultProps = {
  questionIndex: 0,
  questionCount: 42,
};

Header.propTypes = {
  questionIndex: PropTypes.number.isRequired,
  questionCount: PropTypes.number.isRequired,
};
