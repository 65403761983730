import { useFormik } from 'formik';
import { navigate } from 'gatsby';
import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement(`#___gatsby`);

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.58)',
    zIndex: 9000,
  },
  content: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '960px',
    margin: '10vh auto',
    padding: '2rem 0',
    border: 0,
  },
  zIndex: 9000,
};

/**
 * Fertility test modal
 * Used to retrieve fertility test results
 */
function FertilityTestModal({ modalOpen, closeModal }) {
  const modalCloseTimeout = 300;

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    initialStatus: '',
    onSubmit: async (values) => {
      formik.setStatus('');
      const res = await fetch(`${process.env.GATSBY_API}/fertility-test/email?email=${values.email}`);
      if (res.status === 404) {
        formik.setErrors({ email: "Cette adresse email n'a pas de test fertilité associé." });
      }
      if (res.status !== 404 && res.status !== 200) {
        formik.setErrors({ email: 'Une erreur est survenue' });
      }
      if (res.status === 200) {
        formik.setStatus('success');
        closeModal();
        navigate(`/resultats/programme-essentiel/?email=${values.email}`)
      }
      formik.setSubmitting(false);
    },
  });

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Modal"
      closeTimeoutMS={modalCloseTimeout}
    >
      <div className="wrapper">
        <h2>Retrouver mes résultats</h2>

        <div style={{ marginTop: '2rem' }}>
          Saisissez l’email utilisé lors de votre test fertilité :
        </div>

        <form onSubmit={formik.handleSubmit} style={{ marginTop: '1rem' }}>
          <input
            style={{
              display: 'block',
              padding: '0 20px',
              height: '45px',
              borderRadius: '25px',
              border: '1px solid #D6D6D6',
              marginRight: '7px',
              fontSize: '16px',
              fontFamily: 'Poppins',
              width: '80%',
            }}
            placeholder="Saisissez votre adresse email ici"
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          ></input>
          {formik.errors.email && <p style={{ color: '#ff4d4f' }}>{formik.errors.email}</p>}
          {formik.status === 'success' && (
            <p style={{ color: '#52c41a' }}>Veuillez patientez vous allez être redirigée vers vos résultats</p>
          )}
          <div style={{ marginTop: '1rem' }}>
            <button disabled={formik.isSubmitting} className={`Btn Btn--Purple`}>
              {formik.isSubmitting ? 'Patientez...' : 'Envoyer'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
export default FertilityTestModal;
