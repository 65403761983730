import React from 'react';
import styles from './back-button.module.scss';
import PropTypes from 'prop-types';

/** This component is used in the Fertility Test to go back to the previous question */
function BackButton({ questionIndex, onClick }) {
  const index = parseInt(questionIndex, 10);

  // No back button for the first question
  if (index === 0) {
    return null;
  }

  const arrowImg = (
    <svg width="22" height="17" viewBox="0 0 22 17">
      <g
        stroke="#1E1E1C"
        strokeWidth=".738"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M21.269 8.342H1.148M8.36 15.928L.632 8.342 8.36.758" />
      </g>
    </svg>
  );
  return (
    <button className={`${styles.back} p0`} onClick={onClick}>
      {arrowImg} Retour
    </button>
  );
}

export default BackButton;

BackButton.defaultProps = {
  questionIndex: 0,
  onClick: () => alert('No click handler has been defined'),
};

BackButton.propTypes = {
  questionIndex: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};
