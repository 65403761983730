import React from 'react';
import axios from 'axios';

function useFacebookConversionApi(
  event_name,
  event_source_url,
  client_email,
  client_phone,
  client_firstname,
  event_id,
  amount
) {
  /**
   * Get IP address required by facebook conversion api
   * @see https://medium.com/how-to-react/how-to-get-user-ip-address-in-react-js-73eb295720d0
   */
  async function retrieveIdAddress() {
    const res = await axios.get('https://geolocation-db.com/json/');
    return res.data.IPv4;
  }

  /**
   * @see https://stackoverflow.com/questions/10730362/get-cookie-by-name?page=1&tab=scoredesc#tab-top
   */
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  React.useEffect(() => {
    async function sendEventToFacebookConversionApi() {
      if (!event_name) return;
      const endpoint = `https://tcpw93qcre.execute-api.eu-west-3.amazonaws.com/default/sendEventsToFacebookConversionApi`;
      try {
        const client_ip_address = await retrieveIdAddress();
        return await axios({
          method: 'POST',
          url: endpoint,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            event_name: event_name,
            event_source_url: event_source_url,
            client_ip_address: client_ip_address,
            client_user_agent: window.navigator.userAgent,
            event_context: process.env.GATSBY_CONTEXT,
            client_email: client_email,
            client_phone: client_phone,
            client_firstname: client_firstname,
            event_id: event_id,
            amount: amount,
            _fbp: getCookie('_fbp'),
            _fbc: getCookie('_fbc'),
          }),
        });
      } catch (error) {}
    }
    sendEventToFacebookConversionApi();
  }, [event_name, event_id, event_source_url, client_email, client_phone, client_firstname]);

  return [];
}

export default useFacebookConversionApi;
