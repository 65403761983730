import React from 'react';
import Modal from 'react-modal'; // Assurez-vous d'importer Modal de react-modal ou de la bibliothèque que vous utilisez
import { GatsbyImage } from 'gatsby-plugin-image';
import styles from './guide-popup.module.scss';

const GuidePopup = ({
  modalOpen,
  closeModal,
  guideiphone,
  guideiphone2,
  handleSubmit,
  handleChangeGuide,
  formErrors,
}) => {
  const modalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.58)',
      zIndex: 9000,
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      overflow: 'hidden',
      padding: '0',
      backgroundColor: 'transparent',
      marginTop: '60px',
      border: 'none',
    },
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      contentLabel="Guide Modal"
      style={modalStyles}
      closeTimeoutMS={200}
    >
      <div className={styles.popupContent}>
        <button className={styles.closeBtn} onClick={closeModal}>
          <svg viewBox="0 0 15 15">
            <path
              d="M1.033 14.468l6.48-6.48 6.467 6.467.475-.475-6.467-6.466 6.48-6.48-.528-.529-6.48 6.48L1.02.546l-.475.475 6.44 6.44-6.48 6.48z"
              fill="#000"
              fillRule="nonzero"
            />
          </svg>
        </button>
        <section className={styles.guide}>
          <GatsbyImage
            image={guideiphone.childImageSharp.gatsbyImageData}
            alt="Le guide SOPK sur votre téléphone"
            className={styles.guide_img_desktop}
          />
          <div className={styles.guide_form}>
            <h2 className={styles.guide_form_title}>
              <span className={styles.italicText}> Obtenez</span>{' '}
              <span className={styles.boldText}>10% de réduction</span>
              <span className={styles.italicText}> sur le pack en téléchargeant notre guide !</span>
            </h2>
            <GatsbyImage
              image={guideiphone2.childImageSharp.gatsbyImageData}
              alt="Le guide SOPK sur votre téléphone"
              className={styles.guide_img_mobile}
            />
            <form className={styles.guide_inputs} onSubmit={handleSubmit} id="myform">
              <div className={styles.guide_name}>
                <label htmlFor="name">Prénom</label>
                <input
                  className={styles.name}
                  type="text"
                  name="name"
                  required
                  placeholder="Votre prénom"
                  onChange={handleChangeGuide}
                />
              </div>
              <p className={styles.error}>{formErrors?.name}</p>
              <div className={styles.guide_email}>
                <label htmlFor="email">E-mail</label>
                <input
                  className={styles.email}
                  type="email"
                  name="email"
                  placeholder="Votre e-mail"
                  onChange={handleChangeGuide}
                />
              </div>
              <p className={styles.error}>{formErrors?.email}</p>
              <input className={`${styles.btn} ${styles.dl_guide}`} type="submit" value="Je télécharge mon guide" />
            </form>
          </div>
        </section>
      </div>
    </Modal>
  );
};

export default GuidePopup;
