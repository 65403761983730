import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { dataEdge } from '../../functions';
import Link from '../utils/link';
import style from './modules/pack-essentiel.module.scss';

function PackEssentiel({ data }) {
  const { allStaticPackEssentielYaml } = data;
  const {
    node: { content },
  } = dataEdge(allStaticPackEssentielYaml);

  return (
    <div className={style.wrapper}>
      <div className={style.image_back} />

      <section className={`${style.contents} BgPurpleLighter`}>
        <h2 className={`t2`}>{content.title}</h2>
        <h3 className={`${style.contents_description} t3`}>{content.description}</h3>
        <p className={style.contents_rea}>{content.rea}</p>

        <div>
          {content.items && (
            <ul>
              {content.items.map((item, key) => (
                <li key={key} className={style.contents_items}>
                  <div className={style.contents_icon} dangerouslySetInnerHTML={{ __html: content.icon }} />
                  <div className={style.contents_single_item}>{item}</div>
                </li>
              ))}
            </ul>
          )}
        </div>

        <Link to={content.button.link} className={`Btn Btn--Black ${style.contents_button}`}>
          {content.button.text}
        </Link>
      </section>
    </div>
  );
}

export default function (props) {
  return (
    <StaticQuery
      render={(data) => <PackEssentiel data={data} {...props} />}
      query={graphql`
        {
          allStaticPackEssentielYaml {
            edges {
              node {
                content {
                  title
                  description
                  rea
                  icon
                  items
                  button {
                    text
                    link
                  }
                }
              }
            }
          }
        }
      `}
    />
  );
}
