// AudioContext.js
import React, { createContext, useState } from 'react';

const AudioContext = createContext({ playingAudio: null, handleAudioPlay: () => {} });

const AudioProvider = ({ children }) => {
  const [playingAudio, setPlayingAudio] = useState(null);

  const handleAudioPlay = (newAudio) => {
    if (playingAudio && playingAudio !== newAudio) {
      playingAudio.pause();
    }

    setPlayingAudio(newAudio);
  };

  return <AudioContext.Provider value={{ playingAudio, handleAudioPlay }}>{children}</AudioContext.Provider>;
};

export { AudioContext, AudioProvider };
