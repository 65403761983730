import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import styles from './next-button.module.scss';

/**
 * Set focus on an element after rendering
 *
 * @see https://stackoverflow.com/questions/28889826/how-to-set-focus-on-an-input-field-after-rendering
 */
const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

/**
 * This component is used in the Fertility Test to go to the next question
 *
 * @param {Object} props
 * @param {boolean} disabled - Should the button be disabled
 * @param {boolean} autofocus - Should the button have the focus
 * @param {func} onClick - click handler
 * @param {string} label - the button label
 * @param {string} errorMessage - optional error message to display
 */
function NextButton({ disabled, onClick, label, errorMessage, autofocus }) {
  const whitespace = <>&nbsp;</>;

  // Set focus on the button so that
  // the user can use the Enter key
  // without interacting with its
  // mouse
  const [btnRef, setBtnFocus] = useFocus();
  if (autofocus) {
    setTimeout(() => setBtnFocus(), 200);
  }

  return (
    <>
      <button
        ref={btnRef}
        disabled={disabled}
        onClick={onClick}
        className={disabled ? `Btn Btn--Not--Clickable` : `Btn Btn--Clickable`}
      >
        {label}
      </button>
      {<p className={`p1 ${styles.error}`}>{disabled ? errorMessage : whitespace}</p>}
    </>
  );
}

export default NextButton;

NextButton.defaultProps = {
  autofocus: false,
  disabled: true,
  label: 'Generic label',
  errorMessage: 'Generic error message',
  onClick: () => alert('No click handler has been defined'),
};

NextButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  autofocus: PropTypes.bool.isRequired,
};
