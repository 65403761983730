import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styles from './avis.module.scss';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const ButtonGroup = ({ arrow, next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow },
  } = rest;

  return (
    <div className={styles.custom_button_group}>
      <button onClick={() => previous()}>
        <img className={styles.arrow_left} src={arrow.publicURL} alt="left" />
      </button>
      <button onClick={() => next()}>
        <img className={styles.arrow_right} src={arrow.publicURL} alt="right" />
      </button>
    </div>
  );
};

const Avis = ({ item, trustpilot_stars, quote, arrow }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1225 },
      items: 1,
    },
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 1,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 1,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      className={styles.carousel}
      arrows={false}
      renderButtonGroupOutside={false}
      autoPlay
      infinite
      itemClass={styles.avisItem}
      autoPlaySpeed={3000}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      containerClass={styles.container}
      customButtonGroup={<ButtonGroup arrow={arrow} />}
      shouldResetAutoplay
      slidesToSlide={1}
      swipeable
    >
      {item.map((item, index) => (
        <div className={styles.avisContainer}>
          <div className={styles.avisContentContainer}>
            <div className={styles.avisImage}>
              {item.image && (
                <GatsbyImage
                  image={item.image.childImageSharp.gatsbyImageData}
                  alt={item.name}
                  className={styles.img_avis}
                />
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                paddingRight: '5px',
              }}
            >
              <div className={styles.avisContent}>
                <h4>
                  {item.name} {item.age}ans
                </h4>
                <span
                  style={{
                    fontStyle: 'italic',
                    fontWeight: '400',
                  }}
                >
                  {item.tagLabel ? 'Objectif :' : 'Problématique :'} <br></br>
                </span>
                <div className={styles.avisTags}>
                  {item.tags &&
                    item.tags.map((tag, i) => (
                      <span key={i} className={styles.tag}>
                        {tag.value}
                      </span>
                    ))}
                </div>
              </div>

              <div className={styles.trustpilot_stars}>
                <GatsbyImage image={trustpilot_stars.childImageSharp.gatsbyImageData} alt={`stars`} />
              </div>
            </div>
          </div>

          <div className={styles.avisDescription}>
            <img className={styles.quote} src={quote.publicURL}></img>
            <p>{item.testimonial}</p>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default Avis;
