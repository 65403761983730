import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { styled } from '@stitches/react';

export default function FertilityTestCta({ label, display = 'none', justifyContent = 'start' }) {
  const data = useStaticQuery(graphql`
    query {
      allStaticNavbarYaml {
        nodes {
          fertilityTest {
            alt
            img {
              publicURL
            }
          }
        }
      }
    }
  `);

  const StyledLabel = styled('span', {
    marginLeft: '10px',
    fontSize: '16px',
    letterSpacing: '0.64px',
    fontWeight: 500,
    whiteSpace: 'nowrap'
  });

  const StyledLink = styled(Link, {
    alignItems: 'center',
    justifyContent,
    height: '85px',
    padding: '28.5px',
    backgroundColor: '#FFF3F3',
    textUnderlineOffset: '0.7rem',
    '&:hover': {
      boxShadow: '0px 3px 25px #0000000D',
      border: '1px solid rgba(112, 112, 112, 0.26)',
    },
    display,
    '@media only screen and (min-width: 1020px)': {
      display: 'flex',
    },
  });

  return (
    <StyledLink to="/test-fertilite/">
      <img
        src={data.allStaticNavbarYaml.nodes[0].fertilityTest.img.publicURL}
        alt={data.allStaticNavbarYaml.nodes[0].fertilityTest.alt}
      />
      <StyledLabel>{label}</StyledLabel>
    </StyledLink>
  );
}
