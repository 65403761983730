import React, { useState, useEffect, useContext, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import styles from '../modules/programme-2024.module.scss';
import NavIcon from '../components/program-2024/navIcon-item';
import ChoiceCards from '../components/program-2024/choice-cards';
import DietarySupplementsSection from '../components/program-2024/dietary-supplements';
import Product from '../components/program-2024/product';
import Diets from '../components/program-2024/diets';
import FixedRecap from '../components/program-2024/fixed-recap';
import Avis from '../components/program-2024/avis';
import CartContext from '../context/CartContext';
import { navigate } from 'gatsby';
import Link from '../components/utils/link';

const ProgrammeBemum = ({ location }) => {
  const { cart, setCart } = useContext(CartContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const toggleVisibility = () => {
    setIsExpanded(!isExpanded);
    setIsButtonVisible(!isButtonVisible);
  };

  const resetVisibility = () => {
    setIsExpanded(false);
    setIsButtonVisible(true);
  };
  const [isFixed, setIsFixed] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedChoices, setSelectedChoices] = useState({
    equilibre: false,
    antiox: false,
    coaching: false,
    essentiel: false,
  });

  const [filteredRecommendations, setFilteredRecommendations] = useState([]);
  const [activeIcon, setActiveIcon] = useState(0);
  const [recapIsFixed, setRecapIsFixed] = useState(false);
  const [filteredTestimonials, setFilteredTestimonials] = useState([]);
  const ref = useRef(null);

  useEffect(() => {
    if (location.state && location.state.hash) {
      const section = document.querySelector(location.state.hash);
      if (section) {
        const yOffset = -185;
        const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }, [location.state]);

  const handleScrollToSection = (id) => {
    const section = document.querySelector(id);
    if (section) {
      const yOffset = -140;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      const sections = [
        { id: '#recipes', element: document.querySelector('#recipes') },
        { id: '#food-supplements', element: document.querySelector('#food-supplements') },
        { id: '#coaching', element: document.querySelector('#coaching') },
      ];

      const sectionOffsets = sections
        .filter((section) => section.element !== null)
        .map((section) => ({
          id: section.id,
          offset: section.element.offsetTop - 150,
        }));

      let activeIndex = 0;
      for (let i = 0; i < sectionOffsets.length; i++) {
        if (scrollPosition >= sectionOffsets[i].offset) {
          activeIndex = i;
        } else {
          break;
        }
      }
      setActiveIcon(activeIndex);
    };

    window.addEventListener('scroll', handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSelectionChange = (choiceId) => {
    if (choiceId === 'essentiel') {
      setSelectedChoices({
        equilibre: false,
        antiox: false,
        coaching: false,
        essentiel: !selectedChoices.essentiel,
      });
    } else {
      setSelectedChoices((prevChoices) => ({
        ...prevChoices,

        essentiel: false,
        [choiceId]: !prevChoices[choiceId],
      }));
    }
  };

  const handleSubmit = () => {
    setCart('add', 'program', 'essentiel');

    const recommendationsToShow = recommendations.filter((item) => item.id === 'essentiel' || selectedChoices[item.id]);
    setFilteredRecommendations(recommendationsToShow);

    Object.entries(selectedChoices).forEach(([productId, isSelected]) => {
      if (isSelected) {
        const actionType = cart.definition.availableProducts[productId] ? 'product' : 'program';
        setCart('add', actionType, productId);
      }
    });
    handleFilterAvis();
    setIsSubmitted(true);
    document.documentElement.classList.add('smooth-scroll');
    window.scrollBy({ top: 475, behavior: 'smooth' });
  };

  const handleRecapScroll = () => {
    if (!ref.current) return;

    const componentTop = ref.current.offsetTop;
    const scrollPosition = window.scrollY + window.innerHeight;

    if (scrollPosition > componentTop) {
      setRecapIsFixed(true);
    } else {
      setRecapIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleRecapScroll);
    return () => window.removeEventListener('scroll', handleRecapScroll);
  }, []);

  const handlePillarScroll = () => {
    const section = document.getElementById('navSection');
    if (!section) return;
    const scrollTop = window.scrollY;
    const sectionTop = section.offsetTop - 80;

    if (scrollTop > sectionTop) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    setCart('add', 'membershipPeriod', 'monthly');
    window.addEventListener('scroll', handlePillarScroll);

    return () => {
      window.removeEventListener('scroll', handlePillarScroll);
    };
  }, []);

  const isAnyCheckboxChecked = () => {
    return Object.values(selectedChoices).some((value) => value === true);
  };

  function addToCart() {
    const queryParams = new URLSearchParams();

    filteredRecommendations.forEach((product) => {
      queryParams.append('product', product.id);
    });

    navigate(`/panier/?${queryParams.toString()}`);
  }

  const getGradientStyle = (activeIconIndex) => {
    switch (activeIconIndex) {
      case 0:
        return 'linear-gradient(to right,  #F5F5F5 50%, #E0DFDF 50%)';
      case 1:
        return 'linear-gradient(to right, #E7E6FF 50%, #E0DFDF 50%)';
      case 2:
        return 'linear-gradient(to right, #e0dfdf 50%, #febebd 50%)';
      default:
        return 'linear-gradient(to right, #e0dfdf 50%, #E0DFDF 50%)';
    }
  };

  const handleFilterAvis = () => {
    let results = [];

    const filteredAll = testimonials.filter((t) => t.program.some((p) => p.value === 'all'));

    if (
      selectedChoices.essentiel ||
      (selectedChoices.coaching && !selectedChoices.antiox && !selectedChoices.equilibre)
    ) {
      results.push(...testimonials.filter((t) => t.program.some((p) => p.value === 'essentiel')));
    }

    if (selectedChoices.antiox && !selectedChoices.equilibre) {
      results.push(...testimonials.filter((t) => t.program.some((p) => p.value === 'antiox')));
    }

    if (selectedChoices.equilibre && !selectedChoices.antiox) {
      results.push(...testimonials.filter((t) => t.program.some((p) => p.value === 'equilibre')));
    }

    if (selectedChoices.antiox && selectedChoices.equilibre) {
      results.push(...testimonials.filter((t) => t.program.some((p) => p.value === 'absolute')));
    }

    results.push(...filteredAll);

    setFilteredTestimonials(results);
  };

  const {
    pageProgramme2024Yaml: {
      title,
      star_icon,
      arrow_icon,
      right_arrow_icon,
      mark,
      quote,
      quote_reverse_icon,
      seo,
      trustpilot_stars,
      plus,
      choices,
      icons,
      personalized_recipes,
      dietary_supplements,
      fertility_coaching,
      recommendations,
      testimonials,
      trustpilot_star,
      food_program,
    },
  } = useStaticQuery(graphql`
    query pageProgrammeQuery {
      pageProgramme2024Yaml {
        title
        star_icon
        trustpilot_star
        arrow_icon {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        plus {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        right_arrow_icon {
          publicURL
        }
        mark {
          publicURL
        }
        quote {
          publicURL
        }

        choices {
          id
          title
          description
        }

        icons {
          image {
            publicURL
          }
          alt
          href
        }
        trustpilot_stars {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        quote_reverse_icon {
          publicURL
        }
        seo {
          title
          meta
          canonical
        }
        personalized_recipes {
          title
          items {
            description
            options
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
            alt
          }
          appInfo {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
            icon {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
            text
            subscription
          }
        }

        dietary_supplements {
          title
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          essentials {
            title
            description
            items
            testimonial
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
            expert
            details {
              title
              supplements {
                description
                dosage
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                  }
                }
                composition
              }
              ingredients
              benefits_image {
                publicURL
              }
              benefits_description
              benefits_description2
            }
          }
          optional_supplements {
            title
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
            option
            description
            price
            details {
              text
              author
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
              title
              sub_title
              composition_title
              composition_image {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
              composition
              ingredients
              benefits_image {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
              benefits_description
              benefits_description2
            }
          }
        }

        fertility_coaching {
          title
          description
          alt
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          services
          coach_selection {
            description
            services
          }
          extra_coaching_option {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
            title
            description
            price
          }
          refund_info {
            title
            description
          }
        }
        recommendations {
          id
          title
          description
          options
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          price
          checked
        }

        testimonials {
          name
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          age
          tagLabel
          tags {
            value
          }
          program {
            value
          }
          testimonial
        }

        food_program {
          title
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          description
          program
          sub_title
          option {
            title
            description
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title={seo.title} description={seo.meta} canonical={seo.canonical} />
      <div className={styles.baseStyles}>
        <section>
          <div className={styles.programmeContainer}>
            <div>
              <h1 className={styles.programmeContainerTitle} dangerouslySetInnerHTML={{ __html: title }} />
              <div className={styles.desktopButtonContainer}>
                <button
                  onClick={handleSubmit}
                  className={`Btn Btn--Black Btn--No-Icon ${!isAnyCheckboxChecked() ? styles.BtnDisabled : ''}`}
                  disabled={!isAnyCheckboxChecked()}
                >
                  Valider mon profil fertilité
                </button>
              </div>
            </div>
            <div className={styles.choicesContainer}>
              {choices.map((choice, index) => (
                <ChoiceCards
                  choice={choice}
                  mark={mark.publicURL}
                  checked={selectedChoices[choice.id]}
                  onSelectionChange={handleSelectionChange}
                />
              ))}
            </div>
          </div>
          <div className={styles.mobileButtonContainer}>
            <button
              onClick={() => {
                handleSubmit();
              }}
              className={`Btn Btn--Black Btn--No-Icon ${!isAnyCheckboxChecked() ? styles.BtnDisabled : ''}`}
              disabled={!isAnyCheckboxChecked()}
            >
              Valider mon profil fertilité
            </button>
          </div>
        </section>

        {!isSubmitted ? (
          <>
            <section id="navSection">
              <div
                className={
                  isFixed ? `${styles.navIconContainer} ${styles.fixedNavIconContainer}` : styles.navIconContainer
                }
                style={{
                  background: getGradientStyle(activeIcon),
                }}
              >
                {icons.map((item, index) => (
                  <NavIcon
                    key={index}
                    item={item}
                    index={index}
                    isActive={index === activeIcon}
                    isPreviousActive={index === 1 && activeIcon === 2}
                    onClick={handleScrollToSection}
                  />
                ))}
              </div>
            </section>
            <section id="recipes">
              <div className={styles.recipeContainer}>
                <div>
                  <span
                    className={styles.highlightedText}
                    style={{ backgroundImage: 'linear-gradient(to right, #FFF2F3, #FFF2F3)' }}
                  >
                    {personalized_recipes.title}
                  </span>
                </div>

                {personalized_recipes.items.map((recipe, index) => (
                  <div key={index} className={styles.recipeContent}>
                    <div className={styles.recipeList}>
                      <h3>{recipe.description}</h3>
                      <ul>
                        {recipe.options.map((option, key) => (
                          <li key={key} className={styles.contents_items}>
                            <div className={styles.contents_icon} dangerouslySetInnerHTML={{ __html: star_icon }} />
                            <div className={styles.contents_single_item} dangerouslySetInnerHTML={{ __html: option }} />
                          </li>
                        ))}
                      </ul>
                    </div>
                    <GatsbyImage
                      image={recipe.image.childImageSharp.gatsbyImageData}
                      alt={recipe.alt}
                      className={styles.recipeImage}
                    />
                  </div>
                ))}
              </div>
              <div className={styles.appInfo}>
                <div>
                  <GatsbyImage
                    image={personalized_recipes.appInfo.image.childImageSharp.gatsbyImageData}
                    className={styles.appInfoImage}
                  />
                </div>

                <i>{personalized_recipes.appInfo.text}</i>
              </div>

              <div className={styles.appInfoSubscription}>
                <p dangerouslySetInnerHTML={{ __html: personalized_recipes.appInfo.subscription }} />
                <GatsbyImage
                  image={personalized_recipes.appInfo.icon.childImageSharp.gatsbyImageData}
                  className={styles.arrowIcon}
                />
              </div>
            </section>

            <section id="food-supplements">
              <DietarySupplementsSection
                optional_supplements={dietary_supplements.optional_supplements}
                dietary_supplements={dietary_supplements}
                quote={quote.publicURL}
                star_icon={star_icon}
                image={arrow_icon.childImageSharp.gatsbyImageData}
                // image2={dietary_supplements.optional_supplements.details.image.childImageSharp.gatsbyImageData}
                highlightedText={styles.highlightedText}
              />
            </section>

            <section id="coaching">
              <div className={styles.coachingContainer}>
                <div>
                  <span
                    className={styles.highlightedText}
                    style={{ backgroundImage: 'linear-gradient(to right, #FDBEBD, #FDBEBD)' }}
                  >
                    {fertility_coaching.title}
                  </span>
                </div>

                <p>{fertility_coaching.description}</p>

                <div className={styles.coachingContent}>
                  <div className={styles.coachingContentFirstBlock}>
                    <div>
                      <GatsbyImage
                        image={fertility_coaching.image.childImageSharp.gatsbyImageData}
                        className={styles.coachingImage}
                      />
                    </div>
                    <div className={styles.coachingContentFirstList}>
                      <ul>
                        {fertility_coaching.services.map((option, key) => (
                          <li key={key} className={styles.contents_items}>
                            <div className={styles.contents_icon} dangerouslySetInnerHTML={{ __html: star_icon }} />
                            <div className={styles.contents_single_item} dangerouslySetInnerHTML={{ __html: option }} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className={styles.coachingContentSecondList}>
                    <p>{fertility_coaching.coach_selection.description}</p>
                    <ul>
                      {fertility_coaching.coach_selection.services.map((option, key) => (
                        <li key={key} className={styles.contents_items}>
                          <div className={styles.contents_icon} dangerouslySetInnerHTML={{ __html: star_icon }} />
                          <div className={styles.contents_single_item} dangerouslySetInnerHTML={{ __html: option }} />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <Diets />
            </section>
            <div className={styles.extraCoachingContainer}>
              <GatsbyImage
                image={fertility_coaching.extra_coaching_option.image.childImageSharp.gatsbyImageData}
                className={styles.extraCoachingImage}
              />
              <div className={styles.extraCoachingContent}>
                <p className={styles.extraCoachingTitle}>{fertility_coaching.extra_coaching_option.title}</p>
                <p className={styles.extraCoachingSubTitle}>Coaching +</p>
                <p
                  className={styles.extraCoachingDescription}
                  dangerouslySetInnerHTML={{ __html: fertility_coaching.extra_coaching_option.description }}
                />
                <div className={styles.extraCoachingPrice}>
                  <button className={`Btn Btn--Black Btn--Circle-Icon Btn--Circle-Icon--Checked`}>
                    {fertility_coaching.extra_coaching_option.price}
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.refund_infoContainer}>
              <h3 className={styles.refund_infoTitle}>{fertility_coaching.refund_info.title}</h3>
              <p
                className={styles.refund_infoDescription}
                dangerouslySetInnerHTML={{ __html: fertility_coaching.refund_info.description }}
              />
              {isButtonVisible && (
                <button onClick={toggleVisibility} aria-label="Afficher/Cacher les détails">
                  <GatsbyImage
                    image={personalized_recipes.appInfo.icon.childImageSharp.gatsbyImageData}
                    className={isExpanded ? styles.arrowIconOpen : styles.arrowIcon}
                    alt={isExpanded ? 'Cacher les détails' : 'Afficher les détails'}
                  />
                </button>
              )}
              {isExpanded && (
                <div className={styles.refund_infoContainer}>
                  <h3 className={styles.refund_infoTitle}>{food_program.title}</h3>
                  <GatsbyImage
                    image={food_program.image.childImageSharp.gatsbyImageData}
                    className={styles.food_program_img}
                  />
                  <div className={styles.refund_infoDescription}>
                    <p>{food_program.description}</p>
                    <p>{food_program.program}</p>
                    <p>{food_program.sub_title}</p>
                  </div>
                  <div className={styles.refund_infoDescription}>
                    {food_program.option.map((option, key) => (
                      <div key={key}>
                        <h3 className={styles.refund_infoTitle}>{option.title}</h3>
                        <p className={styles.refund_infoDescription}>{option.description}</p>
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={resetVisibility}
                    className={styles.buttonStyle}
                    aria-label="Afficher/Cacher les détails"
                  >
                    <GatsbyImage
                      image={personalized_recipes.appInfo.icon.childImageSharp.gatsbyImageData}
                      className={styles.arrowIconOpen}
                      alt={isExpanded ? 'Cacher les détails' : 'Afficher les détails'}
                    />
                  </button>
                </div>
              )}
            </div>
          </>
        ) : (
          <div style={{ margin: '20px 0' }}>
            <section id="recommandation">
              <div className={styles.recSection}>
                <h2>Votre recommandation :</h2>
                <div className={styles.recItems}>
                  {filteredRecommendations.map((item, index) => (
                    <Product
                      key={item.id}
                      item={item}
                      showPlusIcon={index < filteredRecommendations.length - 1}
                      plusIconURL={plus.childImageSharp.gatsbyImageData}
                      star_icon={star_icon}
                    />
                  ))}
                </div>
              </div>
              <div className={`Center`}>
                <button onClick={addToCart} className={`Btn Btn--Black Btn--No-Icon Center`}>
                  AJOUTER AU PANIER
                </button>
              </div>
              <div className={styles.avis}>
                <div className={styles.trustpilot}>
                  <Link to={`https://fr.trustpilot.com/review/bemum.co`} extern>
                    <div className={styles.trustpilotIcon} dangerouslySetInnerHTML={{ __html: trustpilot_star }} />
                  </Link>
                </div>
                <Avis
                  item={filteredTestimonials}
                  trustpilot_stars={trustpilot_stars}
                  quote={quote_reverse_icon}
                  arrow={right_arrow_icon}
                />
              </div>
              <div className={styles.videoContainer}>
                <h2> Pourquoi cette recommandation ? </h2>
                <div className={styles.video}>
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/SDQLqLcs3rg?rel=0&autoplay=0&mute=1"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer;?autoplay; clipboard-write; encrypted-media; gyrosco"
                    id="audio"
                    allowfullscreen
                  ></iframe>
                </div>
                <h3> D’autres questions ? </h3>
                <Link to={`https://calendly.com/bemum/15min`} extern className={styles.rdv}>
                  Prenez rendez-vous
                </Link>
              </div>
              <div ref={ref}>
                <FixedRecap cart={cart} recapIsFixed={recapIsFixed} addToCart={addToCart} />
              </div>
            </section>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ProgrammeBemum;
