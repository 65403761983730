import React, { useState, useContext, useEffect, useRef } from 'react';
import { graphql, navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import * as style from '../modules/age.module.scss';
import Avis from '../components/program-2024/avis';
import HandBandItem from '../components/sopk/handband-item';
import Composition from '../components/sopk/composition';
import CartContext from '../context/CartContext';
import Link from '../components/utils/link';
import ArticleCard from '../components/sopk/article-card';
import headsetImage from '../images/sopk/headset.svg';
import PodcastCard from '../components/sopk/podcast-card';
import ConversionLayout from '../components/conversion-layout';

const AgePage = ({ data }) => {
  const {
    pageAgeYaml: {
      hero_section,
      partners,
      information_section,
      packOptions,
      handBandItems,
      pillars_section,
      social_posts,
    },
    pageProgramme2024Yaml: { testimonials, trustpilot_star, trustpilot_stars, quote_reverse_icon, right_arrow_icon },
  } = data;

  const [selectedOption, setSelectedOption] = useState('');
  const [purchaseBtnText, setPurchaseBtnText] = useState("S'abonner");
  const { setCart } = useContext(CartContext);
  const [error_abo, setErrorAbo] = useState(false);
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [pillarHoverIndex, setPillarHoverIndex] = useState(null);

  const totalSlides = Math.ceil(partners.length / 4);

  const handleNextSection = () => {
    setActiveSectionIndex((prevIndex) => (prevIndex + 1) % data.pageAgeYaml.sub_sections.length);
  };

  const handlePrevSection = () => {
    setActiveSectionIndex((prevIndex) => (prevIndex === 0 ? data.pageAgeYaml.sub_sections.length - 1 : prevIndex - 1));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveImageIndex((prevIndex) => (prevIndex + 1) % hero_section.images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [hero_section.images.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % (totalSlides + 1));
  };

  useEffect(() => {
    setCart('add', 'program', 'age');
    const handleTransitionEnd = () => {
      if (currentIndex >= totalSlides) {
        carouselRef.current.style.transition = 'none';
        setCurrentIndex(0);
        setTimeout(() => {
          carouselRef.current.style.transition = 'transform 0.5s ease-in-out';
        }, 50);
      } else if (currentIndex < 0) {
        carouselRef.current.style.transition = 'none';
        setCurrentIndex(totalSlides - 1);
        setTimeout(() => {
          carouselRef.current.style.transition = 'transform 0.5s ease-in-out';
        }, 50);
      }
    };

    const carousel = carouselRef.current;
    carousel.addEventListener('transitionend', handleTransitionEnd);
    return () => carousel.removeEventListener('transitionend', handleTransitionEnd);
  }, [currentIndex, totalSlides]);

  const handleChange = (optionId) => {
    setSelectedOption(optionId);
    setPurchaseBtnText(optionId === 'six_months' ? 'Acheter' : "S'abonner");
  };

  const handleSubscribe = () => {
    if (selectedOption) {
      setCart('add', 'membershipPeriod', selectedOption);
      navigate('/panier/');
    } else {
      setErrorAbo(true);
    }
  };

  const handleImageHover = (hoveredIndex) => {
    setMainImageIndex(hoveredIndex);
  };

  const handlePillarHover = (index) => {
    setPillarHoverIndex(index);
  };

  const handlePillarLeave = () => {
    setPillarHoverIndex(null);
  };

  const images = packOptions.itemimage.map((imgObj) => getImage(imgObj.image.childImageSharp.gatsbyImageData));
  const mainImage = images[mainImageIndex];
  const sideImages = images.filter((_, index) => index !== mainImageIndex);

  const slides = [...partners.slice(-4), ...partners, ...partners.slice(0, 4)];

  const filteredTestimonials = testimonials.filter((testimonial) => {
    const age = parseInt(testimonial.age, 10);
    return age >= 35 || testimonial.tags.some((tag) => tag.value === '+35 ans');
  });

  return (
    <ConversionLayout>
      <div>
        <SEO title="Votre fertilité après 35 ans" />

        {/* Hero Section */}
        <div className={style.header}>
          {hero_section.images.map((imgObj, index) => {
            const imageData = getImage(imgObj.image.childImageSharp.gatsbyImageData);
            return (
              <GatsbyImage
                key={index}
                image={imageData}
                alt={imgObj.alt}
                className={`${style.headerImage} ${index === activeImageIndex ? style.active : ''}`}
              />
            );
          })}
          <div className={style.headerText}>
            <h1 dangerouslySetInnerHTML={{ __html: hero_section.title }}></h1>
            <a href="#buy" className={style.headerButton}>
              {hero_section.button_text}
            </a>
          </div>
        </div>

        {/* Carousel Section */}
        <div className={style.carousel}>
          <div className={style.carouselText}>Ils parlent de nous !</div>
          <div
            className={style.carouselInner}
            ref={carouselRef}
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {slides.map((partner, index) => (
              <div className={style.carouselItem} key={index}>
                <a href={partner.link} target="_blank" rel="noopener noreferrer">
                  <GatsbyImage image={getImage(partner.icon.childImageSharp.gatsbyImageData)} alt={partner.alt} />
                </a>
              </div>
            ))}
          </div>
        </div>

        {/* Information Section */}
        <section className={style.informationSection}>
          {information_section.content.map((item, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: item.paragraph }}></p>
          ))}
        </section>

        {/* Avis Section */}
        <div className={style.avisSection}>
          <div className={style.avis}>
            <div className={style.trustpilot}>
              <Link to={`https://fr.trustpilot.com/review/bemum.co`} extern>
                <div className={style.trustpilotIcon} dangerouslySetInnerHTML={{ __html: trustpilot_star }} />
              </Link>
            </div>
          </div>
          <div className={style.avisCustom}>
            <Avis
              item={filteredTestimonials}
              trustpilot_stars={trustpilot_stars}
              quote={quote_reverse_icon}
              arrow={right_arrow_icon}
            />
          </div>
        </div>

        {/* Achat Pack Section */}
        <section className={style.buy} id="buy">
          <p className={`${style.pack_price} ${style.pack_mobile}`}>
            Retrouvez votre pack fertilité spécial <span>+35ans :</span>
          </p>
          <div className={style.img_stars_buy}>
            <GatsbyImage
              image={getImage(trustpilot_stars.childImageSharp.gatsbyImageData)}
              className={style.img_stars}
              alt="5 étoiles trustpilot"
            />
          </div>
          <div className={style.imageContainer}>
            <div className={style.sideImages}>
              {sideImages.map((imgObj, index) => (
                <div
                  key={index}
                  className={style.sideImage}
                  role="button"
                  tabIndex={0}
                  onMouseEnter={() => handleImageHover(images.indexOf(imgObj))}
                  onKeyDown={(e) => e.key === 'Enter' && handleImageHover(images.indexOf(imgObj))}
                >
                  <GatsbyImage image={imgObj} alt={`Image ${index + 1}`} />
                </div>
              ))}
            </div>
            <div className={style.mainImage}>
              <GatsbyImage image={mainImage} alt="Le pack SOPK" className={style.buy_img} />
            </div>
          </div>

          <div className={style.buy_text}>
            <p className={style.buy_text_title}>Votre pack contient :</p>
            <ul className={style.list_buy}>
              <li>
                <span>Un régime profertilité antioxydant*</span>
                <br></br>disponible sur votre application BeMum®.
              </li>
              <li>
                2 Téléconsultations avec votre <b>coach Fertilité.</b>
              </li>
              <li>
                2 <b>compléments alimentaires</b> "Le duo fertilité".
              </li>
              <li>
                1 <b>complément alimentaire</b> spécial « + 35 ans ».
              </li>
            </ul>

            <form className={style.buy_form}>
              {packOptions.items.map((option, index) => (
                <div
                  key={index}
                  className={`${style.buy_inputs} ${selectedOption === option.id ? style.selectedBackground : ''}`}
                  onClick={() => handleChange(option.id)}
                >
                  <div className={style.radio_legende}>
                    <input
                      type="radio"
                      id={option.id}
                      name="fav_language"
                      value={option.id}
                      onChange={() => handleChange(option.id)}
                      checked={selectedOption === option.id}
                    />
                    <label htmlFor={option.id}>
                      <span className={style.buy_inputs_price_text}>{option.duration}</span>
                      <span className={style.buy_inputs_details}>{option.details}</span>
                      <br />
                      <span className={style.buy_inputs_price_text}>{option.price}</span>
                      <span className={style.buy_inputs_details}>{option.savings}</span>
                    </label>

                    {option.id === 'six_months' && (
                      <div className={style.logo_clarna_container}>
                        <GatsbyImage
                          image={getImage(packOptions.logo_klarna.childImageSharp.gatsbyImageData)}
                          alt="logo Klarna"
                          className={style.logo_klarna}
                        />
                      </div>
                    )}
                  </div>
                  {option.subtitle && <p className={style.buy_inputs_subtitle}>{option.subtitle}</p>}
                </div>
              ))}
            </form>
            <button className={`${style.btn} ${style.abo}`} onClick={handleSubscribe}>
              {purchaseBtnText}
            </button>
            {error_abo && <p className={style.error_msg}>Veuillez sélectionner un abonnement</p>}

            <div className={style.handBandContainer}>
              {handBandItems.map((item, index) => (
                <HandBandItem key={index} item={item} />
              ))}
            </div>
            <div className={style.composition}>
              <Composition />
            </div>
          </div>
        </section>

        {/* Pillars Section */}
        <section className={style.pillarsSection}>
          <h2 className={style.pillarsTitle}>{pillars_section.title}</h2>
          <div className={style.pillarsContainer}>
            {pillars_section.pillars.map((pillar, index) => {
              const isHovered = pillarHoverIndex === index;
              const image = isHovered
                ? getImage(data.pageAgeYaml.pillars_section.pillars[index].hover_image)
                : getImage(data.pageAgeYaml.pillars_section.pillars[index].image);

              const titleClass =
                index === 0 ? style.firstPillarTitle : index === 1 ? style.secondPillarTitle : style.thirdPillarTitle;

              return (
                <React.Fragment key={index}>
                  <div
                    className={`${style.pillar} ${
                      index === 0
                        ? style.firstPillar
                        : index === pillars_section.pillars.length - 1
                        ? style.lastPillar
                        : ''
                    } ${isHovered ? style.hovered : ''}`}
                    onMouseEnter={() => handlePillarHover(index)}
                    onMouseLeave={handlePillarLeave}
                  >
                    <GatsbyImage image={image} alt={pillar.title} className={style.pillarImage} />
                    {!isHovered && (
                      <p
                        className={`${style.pillarTitle} ${titleClass}`}
                        dangerouslySetInnerHTML={{ __html: pillar.title }}
                      ></p>
                    )}
                  </div>
                  {index < pillars_section.pillars.length - 1 && <span className={style.plusSymbol}>+</span>}
                </React.Fragment>
              );
            })}
          </div>
        </section>

        {/* Sub Sections (Sections de coaching) */}
        <section className={style.subSections}>
          <h2
            className={style.subSectionTitle}
            dangerouslySetInnerHTML={{ __html: data.pageAgeYaml.sub_sections[activeSectionIndex].title }}
          ></h2>
          <div className={style.subSectionImages}>
            {data.pageAgeYaml.sub_sections[activeSectionIndex].images.map((image, index) => (
              <GatsbyImage key={index} image={getImage(image)} alt="Coaching Image" className={style.subSectionImage} />
            ))}
          </div>
          <p
            className={style.subSectionDescription}
            dangerouslySetInnerHTML={{ __html: data.pageAgeYaml.sub_sections[activeSectionIndex].description }}
          ></p>

          <div className={style.navigationArrows}>
            <button onClick={handlePrevSection} className={style.arrowButton}>
              &#8249;
            </button>
            <button onClick={handleNextSection} className={style.arrowButton}>
              &#8250;
            </button>
          </div>
        </section>

        {/* Section Articles */}
        <section className={style.article_section}>
          <h2 className={style.article_header}>{data.pageAgeYaml.articles.title}</h2>
          <div className={style.article_container}>
            {data.pageAgeYaml.articles.items.map((article, index) => (
              <ArticleCard key={index} article={article} index={index} />
            ))}
          </div>
        </section>

        {/* Section des posts réseaux sociaux */}
        <section className={style.socialPostsSection}>
          <h2>{social_posts.title}</h2>
          <div className={style.socialPostsContainer}>
            {social_posts.items.map((post, index) => (
              <Link key={index} to={post.link} className={style.socialPostLink}>
                <GatsbyImage
                  image={getImage(post.image.childImageSharp.gatsbyImageData)}
                  alt={`Post Réseaux Sociaux ${index + 1}`}
                  className={style.socialPostImage}
                />
              </Link>
            ))}
          </div>
        </section>
        {/* Section Podcasts */}
        <section className={style.podcast_section}>
          <h2 className={style.podcast_header}>{data.pageAgeYaml.podcasts.title}</h2>
          <div className={style.podcast_container}>
            {data.pageAgeYaml.podcasts.items.map((podcast, index) => (
              <PodcastCard
                key={index}
                podcast={podcast}
                podcastImage={data.pageAgeYaml.podcasts.podcastimg}
                headsetImage={headsetImage}
                customCard={style.custom_podcast_card}
                customImg={style.custom_podcast_img}
                customContainer={style.custom_podcast_container}
                customText={style.custom_podcast_text}
                customAudio={style.custom_podcast_audio}
              />
            ))}
          </div>
        </section>
      </div>
    </ConversionLayout>
  );
};

export const query = graphql`
  query {
    pageAgeYaml {
      hero_section {
        title
        images {
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          alt
        }
        button_text
      }
      partners {
        alt
        icon {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        link
      }
      information_section {
        content {
          paragraph
        }
      }
      packOptions {
        itemimage {
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        logo_klarna {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        items {
          id
          duration
          price
          savings
          details
          subtitle
        }
      }
      handBandItems {
        image {
          publicURL
        }
        description
      }
      pillars_section {
        title
        pillars {
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          hover_image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          title
        }
      }
      sub_sections {
        title
        images {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        description
      }
      articles {
        title
        items {
          title
          desc
          link
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      social_posts {
        title
        items {
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          link
        }
      }
      podcasts {
        title
        items {
          title
          desc
          id
          audio_url {
            publicURL
          }
        }
        podcastimg {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    pageProgramme2024Yaml {
      testimonials {
        name
        image {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        age
        tagLabel
        tags {
          value
        }
        program {
          value
        }
        testimonial
      }
      trustpilot_star
      trustpilot_stars {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      quote_reverse_icon {
        publicURL
      }
      right_arrow_icon {
        publicURL
      }
    }
  }
`;

export default AgePage;
