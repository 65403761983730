import { useLocation } from '@reach/router';
import { graphql, navigate, StaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useContext, useState } from 'react';
import Link from '../../components/utils/link';
import CartContext from '../../context/CartContext';
import useFacebookConversionApi from '../../hooks/useFacebookConversionApi';
import useLocalStorage from '../../hooks/useLocalStorage';
import Ellipses from '../repeatable/ellipses';
import style from './food-supplements.module.scss';

const FoodSupplements = ({ data }) => {
  const {
    pageFoodSupplementsYaml: { essentiel, products, quote, doctor, star, rea, benefits, inserm, fertility_age },
  } = data;

  return (
    <>
      <EssentielSupplements
        product={essentiel}
        quote={quote.publicURL}
        doctor={doctor.publicURL}
        star={star.publicURL}
      />

      {products.map((product, key) => (
        <React.Fragment key={key}>
          <FoodSupplement
            key={key}
            product={product}
            quote={quote.publicURL}
            doctor={doctor.publicURL}
            star={star.publicURL}
            rea={rea}
            benefits={benefits}
            inserm={inserm}
            fertility_age={fertility_age}
          />
        </React.Fragment>
      ))}
    </>
  );
};

function EssentielSupplements({ product, quote, doctor, star }) {
  return (
    <section>
      <div className={style.section}>
        <div className={style.flex}>
          <div>
            {/** Heading */}
            <div className={`t2 ${style.essentiel_title}`}>
              {product.title} : <p className="Red">Les Essentiels</p>
            </div>
            <div className={`t3`}>{product.subtitle}</div>

            <ul>
              {product.items.map((item, key) => (
                <li key={key} className={`${style.contents_items} p0`}>
                  <img className={style.contents_icon} src={star} alt="star" />
                  <div className={style.contents_single_item} dangerouslySetInnerHTML={{ __html: item }} />
                </li>
              ))}
            </ul>
          </div>

          {/** Image */}
          <div className={`${style.image_wrapper} ${style.image_wrapper__essentiel}`}>
            <GatsbyImage image={product.image.childImageSharp.gatsbyImageData} alt={product.title} />
          </div>
        </div>
      </div>

      <div className={`${style.section} BgRedLighter`}>
        {/** Advice from experts */}
        <div className={`p0 ${style.xp} BlackLighter`}>
          <div className={`${style.image_wrapper} ${style.image_wrapper_author} `}>
            <GatsbyImage
              image={product.expertise.image.childImageSharp.gatsbyImageData}
              alt={product.expertise.author}
            />
          </div>
          <div>
            <div className={style.xp_image_wrapper}>
              <img alt="quote" src={quote} />
            </div>
            <div dangerouslySetInnerHTML={{ __html: product.expertise.content }} />
            <div className={`${style.xp_author}`}>{product.expertise.author}</div>
            <div className={`${style.xp_role}`}>{product.expertise.role}</div>
          </div>
        </div>

        <div className={style.detailed_compo}>
          <img height="20" src={star} alt="star" />
          <div
            className={`${style.detailed_compo_title} p0 Medium`}
            dangerouslySetInnerHTML={{ __html: product.composition.title }}
          />
        </div>
        <div className={`${style.detailed_compo_details}`}>
          <div>
            <img
              className="xs-hide sm-hide md-hide"
              src={product.composition.image.publicURL}
              alt='Compléments Essentiels'
              height="230px"
              width="212px"
            />
            <img
              className="lg-hide"
              src={product.composition.image_mobile.publicURL}
              height="225px"
              width="212px"
              alt='Compléments Essentiels'
            />
          </div>
          <div>
            <div className={style.product_contents__essentiel}>
              <div dangerouslySetInnerHTML={{ __html: product.composition.content_1 }} />
              <div dangerouslySetInnerHTML={{ __html: product.composition.content_2 }} />
            </div>

            {/** Overview of composition */}
            <div className={style.illustration_wrapper}>
              <img src={doctor} height="82px" alt="doctor" />

              <div className={`${style.composition_wrapper} ${style.composition_wrapper__essentiel}`}>
                {product.contents.map((ingredient, key) => (
                  <div key={key} className={`p0`}>
                    <span className={`Medium`}>{ingredient.name} : </span> {ingredient.description}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function FoodSupplement({ product, quote, doctor, star, rea, benefits, inserm, fertility_age }) {
  const [isInflated, setIsInflated] = useState(false);

  const location = useLocation();
  const [fertilityTestResults] = useLocalStorage('answers', null);
  const [eventId] = useLocalStorage('eventId', null);

  const [fbEventName, setFbEventName] = useState(null);

  // cf issue bemum#1638
  useFacebookConversionApi(
    fbEventName,
    location.href,
    fertilityTestResults?.email,
    fertilityTestResults?.firstname,
    fertilityTestResults?.phone,
    eventId,
    product.amount || product.price
  );

  const { setCart } = useContext(CartContext);

  function addToCart() {
    setFbEventName('ViewContent');
    setCart('add', 'product', product.name);
    navigate('/panier/');
  }

  return (
    <section className={`${style.section} ${style.section__options}`} id={`${product.name}`}>
      <Ellipses top={'0'} left={'-120px'} />

      <div className={style.flex}>
        {/** Image */}

        <div className={`t2 mb4 lg-hide`}>
          Complément optionnel <span className={`Red ${style.product_title}`}>{product.title}</span>
        </div>

        <div className={`${style.image_wrapper} ${style.image_wrapper__options}`}>
          <GatsbyImage image={product.image.childImageSharp.gatsbyImageData} alt={product.title} />
        </div>

        <div>
          {/** Heading */}
          <div className={`t2 mb4 xs-hide sm-hide md-hide`}>
            Complément optionnel <span className={`Red ${style.product_title}`}>{product.title}</span>
          </div>
          <div className={`t3`} dangerouslySetInnerHTML={{ __html: product.subtitle }} />

          {/** Price */}
          <div className={style.price_wrapper}>
            <div className={`${style.price} p0`} dangerouslySetInnerHTML={{ __html: product.price }} />
            <button className={`Btn Btn--Reg Btn--Circle-Icon Btn--Circle-Icon--Checked`} onClick={addToCart}>
              Ajouter
            </button>
          </div>

          {/** Fertility Test */}
          <div className={`${style.doubt}`}> Un doute sur la pertinence pour vous de cette cure complémentaire ?</div>
          <Link to="/test-fertilite/form/" className={`Btn Btn--Purple--Light`}>
            Je fais mon test de fertilité
          </Link>
        </div>
      </div>

      {/** Rea */}
      <div className={`p0 ${style.product_rea}`} dangerouslySetInnerHTML={{ __html: product.rea }} />

      {/** Show more */}
      <div className={style.show_more} role="button" onClick={() => setIsInflated(!isInflated)}>
        <div className="p0"> En savoir plus </div>
        <div className={style.show_more_button}>{isInflated ? <span>-</span> : <span>+</span>}</div>
      </div>

      {/** Detailed composition */}
      {isInflated && (
        <>
          <div className={style.more} dangerouslySetInnerHTML={{ __html: product.more }} />

          {/** Advice from experts */}
          <div className={`p0 ${style.xp} ${style.xp__options}`}>
            <div className={`${style.image_wrapper} ${style.image_wrapper_author__options} `}>
              <GatsbyImage
                image={product.expertise.image.childImageSharp.gatsbyImageData}
                alt={product.expertise.author}
              />
            </div>
            <div>
              <div className={style.xp_image_wrapper}>
                <img alt="quote" src={quote} />
              </div>
              <div dangerouslySetInnerHTML={{ __html: product.expertise.content }} />
              <div className={`${style.xp_author}`}>{product.expertise.author}</div>
              <div className={`${style.xp_role}`}>{product.expertise.role}</div>
            </div>
          </div>

          <div className={style.detailed_compo}>
            <img height="20" src={star} alt="star" />
            <div className={`${style.detailed_compo_title} p0 Uppercase Medium`}>
              Composition détaillée complément alimentaire
              <span className="Red"> {product.composition.title} </span>
            </div>
          </div>
          <div className={`${style.detailed_compo_details}`}>
            <div>
              <img
                src={product.composition.image.publicURL}
                className={style.detailed_compo_img}
                alt={product.composition.title}
              />
            </div>
            <div>
              <div
                className={`${style.product_contents__options} p0`}
                dangerouslySetInnerHTML={{ __html: product.composition.content }}
              />
              {/** Overview of composition */}
              <div className={style.illustration_wrapper}>
                <img src={doctor} height="82px" alt="doctor" />

                <div className={`${style.composition_wrapper} BgRedLighter`}>
                  {product.contents.map((ingredient, key) => (
                    <div key={key} className={`p0`}>
                      <span className={`Medium`}>{ingredient.name} : </span> {ingredient.description}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className={`${style.spacer}`} />
        </>
      )}

      {/** Profil age fertility for antiox */}
      {isInflated && product.name === 'antiox' && (
        <>
          <FertilityAge fertility_age={fertility_age} />
        </>
      )}

      {/** Benefits and rea for equilibre */}
      {isInflated && product.name === 'equilibre' && (
        <>
          <section className={style.rea_container}>
            {rea.map((item, key) => (
              <div key={key} dangerouslySetInnerHTML={{ __html: item }} className={style.rea} />
            ))}
          </section>

          <Benefits benefits={benefits} star={star} inserm={inserm} />
        </>
      )}
    </section>
  );
}

function FertilityAge({ fertility_age }) {
  return (
    <div className={`${style.section__benefits} BgRedLighter ${style.section__fertility_age}`}>
      <h2 className={style.benefits_title}>{fertility_age.title}</h2>

      <div className={`${style.flex} ${style.flex__benefits}`}>
        <div className={style.image_wrapper}>
          <GatsbyImage
            image={fertility_age.image.childImageSharp.gatsbyImageData}
            alt={fertility_age.title}
            objectPosition="0% 0%"
            className={style.benefits_image}
          />
        </div>

        <div className={`p0 BlackLighter`} dangerouslySetInnerHTML={{ __html: fertility_age.content }} />
      </div>
    </div>
  );
}

function Benefits({ benefits, star, inserm }) {
  return (
    <div className={`${style.section__benefits} BgRedLighter`}>
      <h2 className={style.benefits_title}>{benefits.title}</h2>

      <div className={`${style.flex} ${style.flex__benefits}`}>
        <div className={style.image_wrapper}>
          <GatsbyImage
            image={benefits.image.childImageSharp.gatsbyImageData}
            alt={benefits.title}
            objectPosition="0% 0%"
            className={style.benefits_image}
          />

          <GatsbyImage
            image={inserm.image.childImageSharp.gatsbyImageData}
            className={style.inserm_image}
            alt="inserm"
          />

          <div className="p0" dangerouslySetInnerHTML={{ __html: inserm.content }} />
        </div>

        <div>
          <h3 className={style.benefits_subtitle}>{benefits.subtitle}</h3>

          <ul>
            {benefits.items.map((item, key) => (
              <li key={key} className={`${style.contents_item__benefits} p0`}>
                <img className={style.contents_icon} src={star} alt="star" />
                <div className={style.contents_single_item} dangerouslySetInnerHTML={{ __html: item }} />
              </li>
            ))}
          </ul>

          <h3 className={style.benefits_subtitle}>{benefits.subtitle_2}</h3>

          <div className="p0" dangerouslySetInnerHTML={{ __html: benefits.content }} />
        </div>
      </div>
    </div>
  );
}

export default function (props) {
  return (
    <StaticQuery
      render={(data) => <FoodSupplements data={data} {...props} />}
      query={graphql`
        {
          pageFoodSupplementsYaml {
            star {
              publicURL
            }
            quote {
              publicURL
            }
            doctor {
              publicURL
            }
            essentiel {
              title
              subtitle
              items
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
              expertise {
                content
                author
                role
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                  }
                }
              }
              composition {
                title
                image {
                  publicURL
                }
                image_mobile {
                  publicURL
                }
                content_1
                content_2
              }
              contents {
                name
                description
              }
            }
            products {
              name
              title
              subtitle
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
              price
              amount
              rea
              more
              expertise {
                content
                author
                role
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                  }
                }
              }
              contents {
                name
                description
              }
              composition {
                title
                content
                image {
                  publicURL
                }
              }
            }
            rea
            benefits {
              title
              subtitle
              subtitle_2
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
              items
              content
            }
            inserm {
              content
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
            }
            fertility_age {
              title
              content
              image {
                publicURL
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      `}
    />
  );
}
