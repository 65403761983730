const defaultLang = `fr-fr`;

/**
 * Prismic linkresolver
 * Convert doc to url based on its type
 * @returns
 */
module.exports = function (doc) {
  const { uid, lang, type } = doc;
  const langPath = lang === defaultLang ? `` : `/${lang}`;

  switch (type) {
    case `post`:
      return `${langPath}/blog/${uid}/`;
    case `category`:
      return `${langPath}/blog/${uid}`;
    case `blog`:
    default:
      return `${langPath}/blog`;
  }
};
