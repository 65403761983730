import React, { useState, useRef } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styles from './dietary-supplements.module.scss';

const DietarySupplementsSection = ({
  optional_supplements,
  dietary_supplements,
  quote,
  star_icon,
  image,
  highlightedText,
}) => {
  const [openStatus, setOpenStatus] = useState({});
  const [isVisible, setIsVisible] = useState(
    optional_supplements.reduce((acc, _, index) => ({ ...acc, [index]: true }), {})
  );
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const [isOpenButtonVisible, setIsOpenButtonVisible] = useState(true);
  const [isClosing, setIsClosing] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const detailsRef = useRef(null);
  const refs = useRef([]);

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      const offset = -500;
      window.scrollTo({
        top: ref.current.offsetTop + offset,
        behavior: 'smooth',
      });
    }
  };

  const toggleVisibility = (key) => {
    if (openStatus[key]) {
      setIsClosing(true);
      setTimeout(() => {
        setOpenStatus((prev) => ({ ...prev, [key]: !prev[key] }));
        setIsVisible((prev) => ({ ...prev, [key]: !prev[key] }));
        setIsClosing(false);
        scrollToRef(refs.current[key]);
      }, 0);
    } else {
      setOpenStatus((prev) => ({ ...prev, [key]: !prev[key] }));
      setIsVisible((prev) => ({ ...prev, [key]: !prev[key] }));
    }
  };

  const toggleDetailsVisibility = () => {
    if (isDetailsVisible) {
      setIsClosing(true);
      setTimeout(() => {
        setIsDetailsVisible(false);
        setIsOpenButtonVisible(true);
        setIsClosing(false);
        scrollToRef(detailsRef);
      }, 0);
    } else {
      setIsDetailsVisible(true);
      setIsOpenButtonVisible(false);
    }
  };

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 2000);
    }, 1000); 
  };

  return (
    <>
      {showNotification && (
        <div className={styles.notification}>
          Pour sélectionner une option, veuillez indiquer votre profil fertilité.
        </div>
      )}

      <div className={styles.dietarySupplementsSection}>
        <div>
          <span className={highlightedText} style={{ backgroundImage: 'linear-gradient(to right, #E7E6FF, #E7E6FF)' }}>
            {dietary_supplements.title}
          </span>
        </div>

        <div className={styles.dietarySupplementsContainer}>
          <div>
            <GatsbyImage
              image={dietary_supplements.image.childImageSharp.gatsbyImageData}
              className={styles.dietarySupplementsImage}
            />
          </div>

          <div className={styles.dietarySupplementsContent}>
            <i>{dietary_supplements.essentials.title}</i>
            <h3>{dietary_supplements.essentials.description}</h3>
            <ul>
              {dietary_supplements.essentials.items.map((option, key) => (
                <li key={key} className={styles.contents_items}>
                  <div className={styles.contents_icon} dangerouslySetInnerHTML={{ __html: star_icon }} />
                  <div className={styles.contents_single_item} dangerouslySetInnerHTML={{ __html: option }} />
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.dietarySupplements}>
          <div className={styles.dietaryTestimonial}>
            <div>
              <img className={styles.xp_image_wrapper} alt="quote" src={quote} />
              <p>{dietary_supplements.essentials.testimonial}</p>
            </div>

            <div>
              <GatsbyImage
                image={dietary_supplements.essentials.image.childImageSharp.gatsbyImageData}
                className={styles.dietaryCoachImage}
              />
            </div>
          </div>

          <div className={styles.dietarySupplementsExpert}>
            <p dangerouslySetInnerHTML={{ __html: dietary_supplements.essentials.expert }} />
          </div>
        </div>
        {isOpenButtonVisible && (
          <div className={styles.arrowIconContainer} ref={detailsRef} onClick={toggleDetailsVisibility}>
            <GatsbyImage image={image} className={styles.arrowIcon} alt="Open details" />
          </div>
        )}

        <div
          className={`${styles.additionalInfo} ${isDetailsVisible ? styles.open : ''} ${
            isClosing ? styles.closing : ''
          }`}
        >
          <h3>{dietary_supplements.essentials.details.title}</h3>
          {dietary_supplements.essentials.details.supplements.map((supplement, index) => (
            <div key={index}>
              <div className={styles.compositionTitle}>
                <p dangerouslySetInnerHTML={{ __html: supplement.description }}></p>
                <h4>{supplement.dosage}</h4>
              </div>
              <div className={styles.compositionDetails}>
                <GatsbyImage
                  image={supplement.image.childImageSharp.gatsbyImageData}
                  className={styles.compositionImage}
                />
                <ul>
                  {supplement.composition.map((comp, idx) => (
                    <li key={idx}>{comp}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
          <div className={styles.benefits_section_white}>
            <img
              className={styles.benefitsImage}
              src={dietary_supplements.essentials.details.benefits_image.publicURL}
            />
            <ul>
              <li
                dangerouslySetInnerHTML={{ __html: dietary_supplements.essentials.details.benefits_description }}
              ></li>
              <li
                dangerouslySetInnerHTML={{ __html: dietary_supplements.essentials.details.benefits_description2 }}
              ></li>
            </ul>
          </div>
          <div className={styles.arrowIconContainer} onClick={toggleDetailsVisibility}>
            <GatsbyImage image={image} className={styles.arrowIconClose} alt="Close details" />
          </div>
        </div>
      </div>
      <div className={styles.optionalSupplements}>
        {optional_supplements.map((item, key) => ( 
          <div key={key} className={key === 1 ? styles.secondItemBackground : ''}>
            <div className={styles.optionalSupplementsItem}>
              <div>
                <GatsbyImage
                  image={item.image.childImageSharp.gatsbyImageData}
                  className={styles.optionalSupplementsImage}
                />
              </div>
              <div className={styles.optionalSupplementsContent}>
                <h3>
                  <i>{item.title}</i>
                </h3>
                <span>{item.option}</span>
                <p>{item.description}</p>
                <button 
                  className={`Btn Btn--Black Btn--Circle-Icon Btn--Circle-Icon--Checked`}
                  onClick={handleClick}
                >
                  {item.price}
                </button>
              </div>
            </div>
            {isVisible[key] && (
              <div
                className={styles.arrowIconContainer}
                ref={(el) => (refs.current[key] = el)}
                onClick={() => toggleVisibility(key)}
              >
                <GatsbyImage image={image} className={styles.arrowIcon} alt="Open details" />
              </div>
            )}
            <div
              className={`${styles.additionalInfo} ${openStatus[key] ? styles.open : ''} ${
                isClosing ? styles.closing : ''
              }`}
            >
              <img className={styles.xp_image_wrapper} alt="quote" src={quote} />
              <div className={styles.author_section}>
                <div className={styles.author_text}>
                  <p>{item.details[0].text}</p>
                  <p className={styles.author_name}>{item.details[0].author}</p>
                </div>
                <GatsbyImage
                  image={item.details[0].image.childImageSharp.gatsbyImageData}
                  className={styles.optionalSupplementsImage2}
                />
              </div>
              <div className={styles.compositionTitle}>
                <h3>{item.details[0].title}</h3>
                <p dangerouslySetInnerHTML={{ __html: item.details[0].sub_title }}></p>
                <h4>{item.details[0].composition_title}</h4>
              </div>
              <div className={styles.compositionDetails}>
                <GatsbyImage
                  image={item.details[0].composition_image.childImageSharp.gatsbyImageData}
                  className={styles.compositionImage}
                />
                <ul>
                  {item.details[0].composition.map((comp, index) => (
                    <li key={index}>{comp}</li>
                  ))}
                </ul>
              </div>

              <p className={styles.ingredients}>
                <b>Ingrédients :</b> {item.details[0].ingredients}
              </p>

              <div className={styles.benefits_section}>
                <img
                  className={styles.benefitsImage}
                  src={dietary_supplements.essentials.details.benefits_image.publicURL}
                ></img>
                <ul>
                  <li dangerouslySetInnerHTML={{ __html: item.details[0].benefits_description }}></li>
                  <li dangerouslySetInnerHTML={{ __html: item.details[0].benefits_description2 }}></li>
                </ul>
              </div>

              <div className={styles.arrowIconContainer} onClick={() => toggleVisibility(key)}>
                <GatsbyImage image={image} className={styles.arrowIconClose} alt="Close details" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default DietarySupplementsSection;
