import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import React from 'react';
import { dataEdge } from '../../functions';
import style from './modules/partners.module.scss';

function Partners({ data, hideLastTagline }) {
  const { allStaticPartnersYaml } = data;
  const {
    node: { items, title, content, team, finances },
  } = dataEdge(allStaticPartnersYaml);

  return <>
    <div className={style.main_wrapper}>
      <h2 className={style.partner_title } dangerouslySetInnerHTML={{__html: title}} />
      <h3 className={style.content_title}>{content}</h3>
      <div className={style.img_team}>
        <GatsbyImage image={team.childImageSharp.gatsbyImageData} alt="teams" />
      </div>
      <div>
        <h4 className={style.h5}>PARTENAIRES INSTITUTIONNELS ET UNIVERSITAIRES&nbsp;:</h4>
        <div className={style.university_container}>
          {items.map((item, key) => (
            <div key={key} className={style.logo_university}>
              <GatsbyImage image={item.img.childImageSharp.gatsbyImageData} alt="logo partenaire" />
            </div>
          ))}
        </div>
        <h4 className={style.h5}>PARTENAIRES FINANCIERS ET TECHNIQUES&nbsp;:</h4>
        <div className={style.university_container}>
          {finances.map((item, key) => (
            <div key={key} className={style.logo_finance}>
              <GatsbyImage image={item.imgs.childImageSharp.gatsbyImageData} alt="logo partenaire" />
            </div>
          ))}
        </div>
      </div>
    </div>

    {/* {!hideLastTagline && <h3 className={`Center mt2 mb2 clg8 cmd10 shlg2 shmd1 ${style.Content}`}>{content}</h3>} */}
  </>;
}

export default function (props) {
  return (
    <StaticQuery
      render={(data) => <Partners data={data} {...props} />}
      query={graphql`{
  allStaticPartnersYaml {
    edges {
      node {
        title
        content
        items {
          img {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        finances {
          imgs {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        team {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
}`}
    />
  );
}
