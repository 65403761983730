import React, { } from 'react';
import styles from './fixed-recap.module.scss';
import { navigate } from 'gatsby';

const FixedRecap = ({ cart, recapIsFixed, addToCart }) => {
  return (
    <div className={recapIsFixed ? styles.recap__fixed : `${styles.recap__hidden__mobile} ${styles.mobile}`}>
      <div className={`${styles.recap} ${styles.recap_wrapper__fixed}`}>
        <div className={styles.recap_title}>
          Votre&nbsp;recommandation :
        </div>

        <div className={styles.subtotal}>
          {cart.subtotal}€ / MOIS, SANS ENGAGEMENT
        </div>
        <button onClick={addToCart} className={`Btn Btn--Black Btn--No-Icon Center ${styles.button}`}>
          AJOUTER AU PANIER
        </button>
      </div>
    </div>

  );
}

export default FixedRecap;