import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import CartContext, { products } from '../../context/CartContext';
import styles from './cart-item.module.scss';
import CartPopupOptions from './cart-popup-options';

/**
 * Display a single BeMum product in the cart.
 * @param {Object} props
 * @param {import('../../context/CartContext').Product} props.item
 * @param {boolean} props.checked
 */
function CartItem({ item, checked, isForCheckoutView }) {
  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => {
    setModalOpen(false);
  };
  const { cart, setCart } = useContext(CartContext);
  const membershipPeriod = cart.membershipPeriod || 'monthly';

  const data = useStaticQuery(graphql`
    query CartItemQuery {
      pagePanierYaml {
        essentielPack {
          publicURL
        }
        coaching {
          publicURL
        }
        antiox {
          publicURL
        }
        equilibre {
          publicURL
        }
        arrow {
          publicURL
        }
        trash {
          publicURL
        }
        star {
          publicURL
        }
      }
    }
  `);

  if (!item) return null;

  const images = data.pagePanierYaml;
  let src;
  if (item.id === 'essentiel') {
    src = images.essentielPack.publicURL;
  }
  if (item.id === products.ANTIOX) {
    src = images.antiox.publicURL;
  }
  if (item.id === products.EQUILIBRE) {
    src = images.equilibre.publicURL;
  }
  if (item.id === products.COACHING) {
    src = images.coaching.publicURL;
  }

  const cartItem = cart.contents.items.find((cartItem) => cartItem.id === item.id);

  const displayPrice = cartItem
    ? cartItem.price
    : item.price && item.price[membershipPeriod]
    ? item.price[membershipPeriod]
    : item.price;

  const isSopk = cart.contents.program === 'sopk';
  const isAge = cart.contents.program === 'age';

  function toggleChecked() {
    if (checked) {
      return setCart('remove', 'product', item.id);
    }

    return setCart('add', 'product', item.id);
  }

  if (isForCheckoutView) {
    if ((item.id !== 'essentiel' && !checked) || isSopk || isAge) return null;
    return (
      <div className={styles.content__checkout_container}>
        <img className={styles.image__checkout_container} src={src} alt="product" height="55" />
        <div className={styles.item_detail__checkout_container}>
          <span>{item.label}</span>
          {item.id === 'essentiel' ? <div>61.50€</div> : <span>{displayPrice}€</span>}
        </div>
      </div>
    );
  }

  if (item.id === 'essentiel') {
    return (
      <div className={styles.wrapper}>
        <CartPopupOptions modalOpen={modalOpen} closeModal={closeModal} option={item.id} checked={checked} />
        {/** IMAGE */}
        <div className={styles.imageWrapper}>
          <img src={src} alt="produit" />
        </div>
        {/** DESCRIPTION */}
        <div className={styles.cart_description}>
          <div className={styles.label}>{item.label}</div>
          <div className={styles.description}>{item.description}</div>
          {!!item.longDescription && <div className={styles.description}>{item.longDescription}</div>}
          <div>
            <div className={styles.star}>
              <img src={images.star.publicURL} alt="star" />
              <div className={styles.star_content}>
                2 téléconsultations avec votre <br />
                <span className="Medium">coach Fertilité</span>
              </div>
            </div>
            <div className={styles.star}>
              <img src={images.star.publicURL} alt="star" />
              <div className={styles.star_content}>
                <span className="Medium">Un régime profertilité </span>disponible sur{' '}
                <br className="xs-hide sm-hide md-hide" />
                votre <span className="Medium">application BeMum®</span>. Plus des fiches outils{' '}
                <span className="xs-hide sm-hide md-hide"> avec des conseils personnalisés. </span>
              </div>
            </div>
            <div className={styles.star}>
              <img src={images.star.publicURL} alt="star" />
              <div className={styles.star_content}>
                2 compléments alimentaires
                <span className="Medium">“ Le duo fertilité “</span>
              </div>
            </div>
          </div>
          <div className={`${styles.know_more}`} onClick={() => setModalOpen(true)}>
            En savoir plus
          </div>
        </div>

        {/** PRICE ORANGE */}
        <div className={styles.price_wrapper}>
          <div>
            <span className={styles.price}>Prix</span>
          </div>
          <div className={styles.strikethrough_wrapper}>
            <div className={styles.price}>61.50€</div>
          </div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <CartPopupOptions modalOpen={modalOpen} closeModal={closeModal} option={item.id} checked={checked} />
      {/** IMAGE */}
      <div className={styles.imageWrapper}>
        <img src={src} alt="produit" />
      </div>
      {/** DESCRIPTION */}
      <div className={styles.cart_description}>
        <div className={styles.label}>{item.label}</div>
        <div className={styles.description}>{item.description}</div>
        {!!item.longDescription && <div className={styles.description}>{item.longDescription}</div>}

        <div className={`${styles.know_more}`} onClick={() => setModalOpen(true)}>
          En savoir plus
        </div>
      </div>

      <div className={styles.price_wrapper}>
        <div>
          <span className={styles.price}>{displayPrice}€</span>
        </div>

        {/** CHECKBOX */}
        <div>
          <button className={styles.checkbox} onClick={toggleChecked}>
            {checked && <div className={styles.checkbox_inner} />}
          </button>
        </div>
      </div>
    </div>
  );
}

CartItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default CartItem;
