import PropTypes from 'prop-types';
import React, { useState } from 'react';
import questionDefinitionProptypes from '../question-definition.proptypes';
import styles from './checkbox.module.scss';
import blinker from '../../../modules/animations/blinker.module.scss';

/**
 * Question of type "checkbox"
 * @param {Object} props
 * @param {import("./question").Definition} props.definition - Interlude question definition
 * @param {function} props.change - event handler
 * @param {*} props.value - current value
 */
function Checkbox({ definition, change, value, answers, imc }) {
  const [detail, setDetail] = React.useState(null);
  const [hover, setHover] = React.useState(null);
  const [isBlinking, setIsBlinking] = useState(false);

  function updateDetail() {
    setDetail(null);
    for (const option of definition.options) {
      if (value.includes(option.value) && !!option.detail) {
        setDetail(option.detail);
        break;
      }
    }
    if (definition.name === 'relationship_to_food') {
      let tmp = '';
      if (imc >= 27 && (value.includes('food_1') || value.includes('food_2') || value.includes('food_3'))) {
        tmp +=
          "Un rapport difficile à l'alimentation impacte le contenu de l'assiette et participe à un IMC élevé, qui réduit les chances de grossesse. ";
      }
      if (answers.infertility_diagnosis.includes('sopk') && value.includes('food_4')) {
        tmp +=
          "Réduire la consommation d'aliments sucrés et adopter une alimentation à indice glycémique bas est le premier traitement du SOPK. ";
      }
      if (answers.infertility_diagnosis.includes('endometriose') && value.includes('food_4')) {
        tmp +=
          "Une consommation d'aliments sucrés favorise l'inflammation et n'est pas conseillée dans le cadre d'une endométriose. ";
      }
      setDetail(tmp);
    }
  }

  function updateHover(evt) {
    evt.preventDefault();
    setHover(null);
    const option = definition.options.find((o) => o.label === evt.target.innerText);
    if (option && option.hover) {
      setHover(option.hover);
    }
  }

  React.useEffect(() => {
    updateDetail();
  });

  /**
   * Make the checkbox label blink when checked
   * @param {Event} event
   */
  function onClick(event) {
    const { name, value, checked } = event.target;

    if (!checked) {
      return;
    }
    // for checkout questions, only 'aucun' can lead to blinking
    if (value === 'aucun' && name !== 'infertility_diagnosis') {
      setIsBlinking(true);
      setTimeout(() => {
        setIsBlinking(false);
      }, 300);
    }
  }

  const containsHover = definition.options.some((option) => option.hover);
  const containsDetail = definition.options.some((option) => option.detail);

  return (
    <div className={styles.wrapper}>
      <div>
        <h2>{definition.title}</h2>
      </div>
      {definition.helper && <p className={`${styles.helper} p1`}>{definition.helper}</p>}

      <div className={styles.spacer}></div>

      <div className={styles.optionsWrapper}>
        {definition.options.map((option, index) => (
          <div key={index} className={`${styles.option}`}>
            <input
              type="checkbox"
              name={definition.name}
              value={option.value}
              checked={value.indexOf(option.value) > -1}
              id={`${definition.name}-${index}`}
              onChange={change}
              onClick={onClick}
            />
            <label
              className={isBlinking && option.value === 'aucun' ? blinker.blink : null}
              htmlFor={`${definition.name}-${index}`}
              onMouseEnter={(evt) => updateHover(evt)}
              onMouseLeave={() => setHover(null)}
            >
              {option.label}
              {option.helper && <span className={`p1 Block Center`}>{option.helper}</span>}
            </label>
          </div>
        ))}
      </div>
      {(containsHover || containsDetail) && <p className={`${styles.detail} p1`}>{hover ? hover : detail}</p>}
    </div>
  );
}
export default Checkbox;

Checkbox.propTypes = {
  definition: questionDefinitionProptypes.isRequired,
  change: PropTypes.func.isRequired,
  value: PropTypes.any,
};
