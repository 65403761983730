import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useContext, useState } from 'react';
import CartContext, { programs } from '../../context/CartContext';
import CartPopupOptions from '../panier/cart-popup-options';
import styles from './essential.module.scss';
import ResultsPopupOptions from './results-popup-options';

function Product({ image, title, checked, item, isNotEditable }) {
  const { cart, setCart } = useContext(CartContext);
  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => {
    setModalOpen(false);
  };

  // Default price if item is not provided
  const price = item ? item.price : '61,50';
  const membershipPeriod = cart.membershipPeriod || 'monthly';

  // Check if item and item.id are defined before using them
  const cartItem = item && cart.contents.items.find((cartItem) => cartItem.id === item.id);

  const displayPrice = cartItem
    ? cartItem.price
    : item && item.price && item.price[membershipPeriod]
    ? item.price[membershipPeriod]
    : '61,50';

  const optionPopup = isNotEditable ? programs.ESSENTIEL : item?.id;

  function toggleChecked() {
    if (isNotEditable) {
      return;
    }
    if (checked) {
      return item && setCart('remove', 'product', item.id);
    }
    return item && setCart('add', 'product', item.id);
  }

  return (
    <>
      {isNotEditable ? (
        <CartPopupOptions modalOpen={modalOpen} closeModal={closeModal} option={optionPopup} checked={checked} />
      ) : (
        <ResultsPopupOptions
          modalOpen={modalOpen}
          closeModal={closeModal}
          option={optionPopup}
          checked={checked}
          toggleChecked={toggleChecked}
          image={image}
        />
      )}

      <div className={styles.img}>
        {title && <h3 style={{ fontSize: '17px' }}>{title}</h3>}
        <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={optionPopup} />
        <div className={styles.product_why} onClick={() => setModalOpen(true)}>
          POURQUOI ON ME LE RECOMMANDE ?
        </div>
        <button
          onClick={toggleChecked}
          className={`Btn Btn--Reg Btn--Circle-Icon ${checked ? `${styles.black} Btn--Circle-Icon--Checked ` : ''} ${
            styles.button
          }`}
        >
          {displayPrice}€&nbsp;<span className="xs-hide sm-hide md-hide">{checked ? 'Ajouté' : 'Ajouter'}</span>
        </button>
      </div>
    </>
  );
}

export default Product;
