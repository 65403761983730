import { withPreviewResolver } from 'gatsby-source-prismic';
import * as React from 'react';
import SEO from '../components/seo';

// Update the path to your Link Resolver
import linkResolver from '../../linkResolver';

const PreviewPage = () => {
  return (
    <div>
      <SEO title={'Preview'} canonical={`https://www.bemum.co/preview/`} />
      <h1>Loading preview…</h1>
    </div>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: `bemum-blog`,
  linkResolver: () => (doc) => linkResolver(doc),
});
