import React from 'react';
import styles from './handband-item.module.scss';

const HandBandItem = ({ item }) => (
  <div className={styles.handBandItem}>
    <img src={item.image.publicURL} alt={item.description} className={styles.headbandImg} />
    <p>{item.description}</p>
  </div>
);

export default HandBandItem;
