import React from 'react';
import { graphql } from 'gatsby';

// Layout
import Layout from '../components/layout';

import Blog from './blogLayout';

function Page({ data, pageContext }) {
  return (
    <Layout extendedReduced>
      <Blog {...data} {...pageContext} />
    </Layout>
  );
}

export const query = graphql`
  query($lang: String, $skip: Int, $limit: Int) {
    allPrismicCategory(filter: { lang: { eq: $lang } }) {
      nodes {
        uid
        type
        lang
        data {
          name {
            text
          }
        }
      }
    }
    posts: allPrismicPost(
      limit: $limit
      skip: $skip
      sort: { fields: data___date_published, order: DESC }
      filter: { data: { publication_level: { ne: true } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...PostShort
      }
    }
    prismicBlog(lang: { eq: $lang }) {
      data {
        title {
          text
        }
        theme
        search_article
        other_articles {
          text
        }
        extra_content {
          html
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        content {
          html
        }
      }
    }
  }
`;

export default Page;
