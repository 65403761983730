import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
// Layout
import Layout from '../components/layout';
import Ellipses from '../components/repeatable/ellipses';
// React Helmet SEO
import SEO from '../components/seo';
// Component: Reassurance BeMum
import Reassurance from '../components/static/rea';
import style from '../modules/innovation.module.scss';
import Team from '../components/static/team';
import Partners from '../components/static/partners';

function IndexPage({ data }) {
  const {
    pageInnovationYaml: {
      title,
      seo,
      content,
      innovation,
      meal_planner,
      rea_complements,
      sections,
      nutrition,
      banner,
      rea,
      star,
      quote,
    },
  } = data;

  return (
    <Layout>
      <SEO title={seo.title} description={seo.description} canonical={seo.canonical} />

      {/* Header */}
      <header className={`BgRedLighter`} style={{ position: 'relative', zIndex: 1 }}>
        <div className={style.header}>
          <h1 className={`t1 ${style.header_title}`}>{title}</h1>
          <div className={style.header_content} dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </header>

      {/* Nutrition */}
      <div className={`${style.section} ${style.flex}`}>
        <Ellipses top={'-150px'} right={'0'} rotate />
        <div>
          <h2 className={`t2 mb3`}>{nutrition.title}</h2>
          <div className={`p0 BlackLighter`} dangerouslySetInnerHTML={{ __html: nutrition.content }} />
        </div>

        <div>
          <Testimonial
            name={nutrition.doctor.name}
            role={nutrition.doctor.role}
            text={nutrition.doctor.description}
            avatar={nutrition.doctor.picture.childImageSharp.gatsbyImageData}
            quote={quote.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>

      {/** Rea banner */}
      <div className={`BgPurpleLighter ${style.rea_banner} `}>
        <img className={style.rea_banner_icon} src={star.publicURL} alt="star" />
        <div className={style.rea_banner_content}>{rea}</div>
        <img className={style.rea_banner_icon} src={star.publicURL} alt="star" />
      </div>

      {/* Meal planner */}
      <div className={`${style.meal_planner}`}>
        <h2 className={`t2 mb4 lg-hide`}>{meal_planner.title}</h2>
        <div>
          <div className={`${style.meal_planner_images}`}>
            <div className={`${style.meal_planner_image_1}`}>
              <GatsbyImage image={meal_planner.image.childImageSharp.gatsbyImageData} alt={meal_planner.title} />
            </div>
            <div className={`${style.meal_planner_image_2}`}>
              <GatsbyImage image={meal_planner.image_2.childImageSharp.gatsbyImageData} alt={meal_planner.title} />
            </div>
          </div>
        </div>

        <div>
          <h2 className={`t2 mb3 xs-hide sm-hide md-hide`}>{meal_planner.title}</h2>
          <div className={`BlackLighter p0`} dangerouslySetInnerHTML={{ __html: meal_planner.content }} />
        </div>
      </div>

      {/* Innovation */}
      <div className={`BgRedLighter ${style.innovation}`}>
        <h2 className={`t2 Center`}>{innovation.title}</h2>
        <div className={`Center ${style.innovation_content}`}>{innovation.content}</div>

        <div className={style.innovation_items}>
          {innovation.items.map(({ title, content }, key) => (
            <div key={key} className={`Center ${style.innovation_item}`}>
              <div className={style.innovation_content} dangerouslySetInnerHTML={{ __html: title }} />
              <div className={`p0 ${style.innovation_item_content}`}>{content}</div>

              {key === 0 && <div className={style.spacer} />}
            </div>
          ))}
        </div>
      </div>

      {/* Sections */}
      <div className={style.section}>
        <Ellipses top={'-120px'} right={'0'} rotate />
        <Ellipses bottom={'225px'} left={'-120px'} />
        <h2 className={`t2 ${style.section_title}`}>Compléments alimentaires et accompagnement humain et digital.</h2>
        <h3 className={style.section_content}>
          Pour vous soutenir dans votre régime profertilité, BeMum vous propose 2 dispositifs complémentaires.
        </h3>
        {sections.map((section, key) => (
          <div key={key} className={`${style.item}`}>
            <div className={style.item_icon}>
              <img src={section.icon.publicURL} className={style.item_icon_img} alt={section.title} />
              <div className={style.item_index}>{key + 1}.</div>
            </div>
            <div>
              <div className={style.item_title}>{section.title}</div>
              <div className={`p0 BlackLighter`}>{section.content}</div>
              {key === 0 && (
                <div className="p0 BlackLighter Medium">
                  <div className={`Bold ${style.details}`}>
                    Dans le cadre de votre abonnement au pack Essentiel, bénéficiez d'
                    <span className="Red"> une téléconsultation </span> avec votre coach, chaque mois en plus d'
                    <span className="Red">une offerte </span>le premier mois.
                  </div>
                </div>
              )}
              {key === 1 && (
                <div className="p0 BlackLighter Medium">
                  <div className={`Bold ${style.details}`}>
                    Dans le cadre de votre abonnement au pack Essentiel, recevez chaque mois, chez vous, votre cure de
                    compléments alimentaires Essentiels :
                  </div>
                  <ul>
                    <li className={style.details_item}>
                      <span className="Red">• E+ : </span> tous les vitamines et minéraux essentiels à la fertilité
                      féminine.
                    </li>
                    <li>
                      <span className="Red">• E omega-3 : </span> une source d’oméga-3 de très haute qualité, certifiée
                      EPAX®.
                    </li>
                  </ul>
                  <div className={`Bold ${style.details__2}`}>
                    En fonction de votre profil fertilité, vous pourrez opter pour une ou plusieurs cure(s) de
                    compléments alimentaires optionnels :
                  </div>
                  <ul>
                    <li className={`${style.details_item} ${style.details_flex}`}>
                      <img
                        src={section.equilibre.publicURL}
                        className={style.option_icon}
                        alt="compléments alimentaires"
                      />{' '}
                      <div>
                        <span className="Red">• Spécificité poids / SOPK : </span> conçus pour faciliter la perte de poids,
                        limiter les fringales, réguler les cycles et favoriser l’ovulation spontanée.
                      </div>
                    </li>
                    <li className={style.details_flex}>
                      <img
                        src={section.antiox.publicURL}
                        className={style.option_icon}
                        alt="compléments alimentaires"
                      />{' '}
                      <div>
                        <span className="Red">• P+ / Spécificité + 35 ans : </span>vous aident à compenser les effets du stress
                        oxydatif.
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Rea Complements Banner */}
      <div className={style.rea_complements}>
        <div className="wrapper">
          <div className="grid-uniform">
            {rea_complements.map((item, index) => (
              <div key={index} className={`grid__item cmd3 clg3 csm6 cxs6`}>
                <div className={`${style.rea_complements_component} mb2 mbmd0`}>
                  <div className={`${style.rea_complements_component__Img} mb3`}>
                    <img src={item.svg.publicURL} alt={item.title} />
                  </div>
                  <h3 className={`Light Center ${style.rea_complements_component_title}`}>{item.title}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Benefices */}
      <div className={`${style.section} ${style.flex}`}>
        <div className={style.benefits}>
          <h2 className={`t2 mb3`}>{banner.title}</h2>
          <div className={`p0 Bold BlackLighter`} dangerouslySetInnerHTML={{ __html: banner.content }} />
          <div className={`Uppercase BlackLighter`}>
            {banner.icons && (
              <ul>
                {banner.icons.map((icon, key) => (
                  <li key={key} className={style.bannerDesc}>
                    <img className={style.bannerDesc_icon} src={icon.svg.publicURL} alt={icon.title} />
                    <div className={style.bannerDesc_title}>{icon.title}</div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div>
          <Testimonial
            name={banner.doctor.name}
            role={banner.doctor.role}
            text={banner.doctor.description}
            avatar={banner.doctor.picture.childImageSharp.gatsbyImageData}
            quote={quote.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
      {/* ADD Team And Partner */}
      <Partners />
      <Team />
      {/* Reassurance */}
      <div className={`mt2`}>
        <Reassurance />
      </div>
    </Layout>
  );
}

/**
 * UI Component representing a person giving a testimonial
 *
 * @param {*} props
 * @param {string} props.name Name of the person
 * @param {import('gatsby-image').FixedObject} props.avatar Picture of the person
 * @param {string} props.program Program of the person
 * @param {string} props.text Contents of the testimonial
 * @param {import('gatsby-image').FixedObject} props.quote Quote image
 *
 */
function Testimonial({ name, avatar, role, text, quote }) {
  return (
    <div className={`BlackLighter ${style.testimonial}`}>
      <div className={style.testimonial_title}>
        {/** PERSON AVATAR */}
        <div className={style.testimonial_avatar}>
          <GatsbyImage image={avatar} alt={name} />
        </div>
        <div>
          {/** PERSON DETAILS */}
          <div className={`Bold p0`}>{name}</div>
          <div className={`p0`}>{role}</div>
        </div>
      </div>

      {/** PERSON TEXT */}
      <div className={`${style.testimonial_text} p0`}>
        <GatsbyImage image={quote} alt="quote" className={`${style.testimonial_quote}`} />
        <span className="xs-hide sm-hide md-hide" dangerouslySetInnerHTML={{ __html: text }} />
        <div className="lg-hide" dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  );
}

export default IndexPage;

export const query = graphql`
  {
    pageInnovationYaml {
      content
      seo {
        title
        description
        canonical
      }
      innovation {
        items {
          content
          title
        }
        title
        content
      }
      nutrition {
        content
        title
        doctor {
          description
          name
          role
          picture {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
      rea
      star {
        publicURL
      }
      quote {
        childImageSharp {
          gatsbyImageData(width: 54, placeholder: NONE, layout: FIXED)
        }
      }
      meal_planner {
        title
        content
        image {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        image_2 {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
      }
      rea_complements {
        title
        svg {
          publicURL
        }
      }
      sections {
        icon {
          publicURL
        }
        antiox {
          publicURL
        }
        equilibre {
          publicURL
        }
        title
        content
      }
      title
      banner {
        title
        content
        icons {
          svg {
            publicURL
          }
          title
        }
        doctor {
          description
          name
          role
          picture {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
