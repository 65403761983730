import React, { useState } from 'react';
import styles from './podcast-header.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import PodcastCard from './podcast-card';

const PodcastHeader = ({ title, seo, links, seasons }) => {
  const [selectedSeason, setSelectedSeason] = useState(2);
  const [isSelected, setSelected] = useState(false);

  const toggleSeason = (seasonNumber) => {
    setSelectedSeason(seasonNumber);
    setSelected(false);
  };

  const { microphone, femme, apple, spotify, google, deezer, arrowDown } = useStaticQuery(graphql`
    query PodcastPageQuery {
      microphone: file(relativePath: { eq: "podcast/microphone.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      femme: file(relativePath: { eq: "podcast/women.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      apple: file(relativePath: { eq: "podcast/apple.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      spotify: file(relativePath: { eq: "podcast/spotify.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      google: file(relativePath: { eq: "podcast/google.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      deezer: file(relativePath: { eq: "podcast/deezer.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      arrowDown: file(relativePath: { eq: "podcast/arrow-down.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  `);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.imgContainer}>
          <GatsbyImage image={microphone.childImageSharp.gatsbyImageData} alt="BeMum vous écoute et vous renseigne" />
        </div>
        <div className={styles.content}>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.text}>{seo.description}</p>
        </div>
      </div>

      <div className={styles.secondContainer}>
        <div className={styles.imgContainer}>
          <GatsbyImage image={femme.childImageSharp.gatsbyImageData} alt="À l’écoute de votre fertilité" />
        </div>
        <div className={styles.secondContent}>
          <h2 className={styles.info}>{seo.title}</h2>
          <p className={styles.text}>{seo.content}</p>
          <div className={styles.iconsContainer}>
            <a href={links.apple} target="_blank" className={styles.icon}>
              <GatsbyImage image={apple.childImageSharp.gatsbyImageData} alt="Apple podcast" />
            </a>
            <a href={links.spotify} target="_blank" className={styles.icon}>
              <GatsbyImage image={spotify.childImageSharp.gatsbyImageData} alt="Spotify podcast" />
            </a>
            <a href={links.google} target="_blank" className={`${styles.icon} ${styles.google}`}>
              <GatsbyImage image={google.childImageSharp.gatsbyImageData} alt="Google podcast" />
            </a>
            <a href={links.deezer} target="_blank" className={`${styles.icon} ${styles.deezer}`}>
              <GatsbyImage image={deezer.childImageSharp.gatsbyImageData} alt="Deezer podcast" />
            </a>
          </div>
        </div>
      </div>
      {/* Desktop */}
      <div className={styles.menu}>
        {seasons.map((season, index) => (
          <h2
            key={index}
            className={`${styles.button} ${selectedSeason === season.title ? styles.activeButton : styles.normalButton}`}
            onClick={() => toggleSeason(season.title)}
          >
            Saison {season.title}
          </h2>
        ))}
      </div>

      {/* Mobile */}
      <div className={styles.dropdown}>
        <div className={styles.dropdowntitle}>
          <h2 className={styles.dropbtn} onClick={() => setSelected(!isSelected)}>
            {selectedSeason && `Saison ${selectedSeason}`}
          </h2>
          <div onClick={() => setSelected(!isSelected)}>
            <GatsbyImage className={styles.arrow} image={arrowDown.childImageSharp.gatsbyImageData} alt="select a season" />
          </div>
        </div>
        <div className={styles.dropdowncontent}>
          {isSelected && seasons.map((season, index) => (
            <h2
              key={index}
              className={`${selectedSeason === season.title ? styles.activeButton : styles.normalButton}`}
              onClick={() => toggleSeason(season.title)}
            >
              Saison {season.title}
            </h2>
          ))}
        </div>
      </div>
      <hr className={styles.divider} />
      {seasons.sort((a, b) => b.id - a.id).map((season, key) => (
        <PodcastCard key={key} season={selectedSeason} seasons={season} />
      ))}
    </>
  );
};

export default PodcastHeader;
