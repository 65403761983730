import React from 'react';
import QuestionDefinition from '../question-definition.proptypes';
import styles from './interlude.module.scss';

/**
 * Question of type "interlude"
 * @param {Object} props
 * @param {import("./question").Definition} props.definition - Interlude question definition
 */
function Interlude({ definition }) {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{definition.title}</h2>
      {definition.helper && (
        <div className={`${styles.helper} p0`} dangerouslySetInnerHTML={{ __html: definition.helper }}></div>
      )}
    </div>
  );
}
export default Interlude;

Interlude.propTypes = {
  definition: QuestionDefinition.isRequired,
};
