import React from 'react';

// React Helmet SEO
import SEO from '../../components/seo';

import Checkout from '../../components/checkout-form';
import Layout from '../../components/checkout-layout';

function IndexPage() {
  return (
    <>
      <SEO title={`Paiement`} description={`Paiement de la commande`} noindex />
      <Layout step={0}>
        <Checkout />
      </Layout>
    </>
  );
}

export default IndexPage;
