import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
// Layout
import Layout from '../components/layout';
// React Helmet SEO
import SEO from '../components/seo';
import Link from '../components/utils/link';
import style from '../modules/nutritionnistes.module.scss';

function IndexPage({ data }) {
  const {
    pageNutritionnistesYaml: { title, seo, content, diets, banner, mutuelle, star },
  } = data;

  return (
    <Layout>
      <SEO title={seo.title} description={seo.description} canonical={seo.canonical} />

      {/* Header */}
      <header className={`BgRedLighter ${style.header}`}>
        <h1 className={`t1 mb2 mbmd3`}>{title}</h1>
        <div className={`t3`} dangerouslySetInnerHTML={{ __html: content }} />
      </header>

      <div className={`wrapper Center`}>
        <div className={`grid-flex mb4 ${style.diets}`}>
          {diets.map((item, key) => (
            <div className={`grid-flex__item clg4 cmd4 csm11 mb2 ${style.diet__profile}`}>
              <Diet
                key={key}
                name={item.name}
                picture={item.picture.childImageSharp.gatsbyImageData}
                tags={item.tags}
                description={item.description}
                star={star.publicURL}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Banner */}
      <div className={`${style.banner} BgRedLighter`}>
        <div className="wrapper">
          <div className="grid">
            <div className={`grid__item Center`}>
              <div className={`p0 mb4`} dangerouslySetInnerHTML={{ __html: banner.content }} />

              <Link to={banner.button.link} className={`Btn Btn--Reg`}>
                {banner.button.text}
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Mutuelle */}
      <div className={style.mutuelle}>
        <div className="wrapper">
          <div className="grid">
            <div className="grid__item cmd10 clg10 shmd1 shlg1">
              <div className={`${style.mutuelle__component}`}>
                <h2 className={`t2 mb2 Center DeepBlack`}>{mutuelle.title}</h2>
                <div className={`p0 mb4 Center`} dangerouslySetInnerHTML={{ __html: mutuelle.content }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

/**
 * UI Component representing a diet description
 *
 * @param {*} props
 * @param {string} props.name Name of the diet
 * @param {import('gatsby-image').FluidObject} props.picture Picture of the diet
 * @param {string} props.tags Tags for the diet
 * @param {string} props.description Description of the diet
 * @param {import('gatsby-image').publicURL} props.star Star image
 *
 */
function Diet({ name, picture, tags, description, star }) {
  return (
    <div className={style.diet_wrapper}>
      <div className={style.diet}>
        <img src={star} height="30" alt="star" />

        <div className={`mb3`}>
          <div className={style.diet_picture}>
            <GatsbyImage image={picture} alt={name} />
          </div>

          <div className={style.diet_name}>{name}</div>

          <div className={style.diet_tags}>
            {tags && (
              <ul className={`${style.tag_wrapper}`}>
                {tags.map((tag, i) => (
                  <li key={i} className={`mb1`}>
                    <p className={`Center Black Regular ${style.tag}`}>{tag.value}</p>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className={style.diet_description} dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        <img src={star} height="30" className={style.star_bottom} alt="star" />
      </div>
    </div>
  );
}

export default IndexPage;

export const query = graphql`
  {
    pageNutritionnistesYaml {
      title
      seo {
        title
        description
        canonical
      }
      content
      diets {
        name
        tags {
          value
        }
        description
        picture {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
      }
      banner {
        content
        button {
          text
          link
        }
      }
      mutuelle {
        title
        content
      }
      star {
        publicURL
      }
    }
  }
`;
