import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styles from './cart-item.module.scss';
import CartContext from '../../context/CartContext';

function PackCartItem({ item }) {
  const { cart } = useContext(CartContext);

  const data = useStaticQuery(graphql`
    query PackCartItemQuery {
      pagePanierYaml {
        arrow {
          publicURL
        }
        trash {
          publicURL
        }
        star {
          publicURL
        }
        sopk {
          publicURL
        }
        age {
          publicURL
        }
      }
    }
  `);

  const images = data.pagePanierYaml;

  // Déterminer le pack et ses informations
  const isSopk = cart.contents.program === 'sopk';
  const packImage = isSopk ? images.sopk.publicURL : images.age.publicURL;
  const packLabel = isSopk ? 'Pack SOPK' : 'Pack Age';
  const packDescription = isSopk ? 'Pack SOPK ' : 'Pack Age';

  return (
    <div className={styles.wrapper}>
      {/** IMAGE */}
      <div className={styles.imageWrapper}>
        <img src={packImage} alt="produit" />
      </div>
      {/** DESCRIPTION */}
      <div className={styles.cart_description}>
        <div className={styles.label}>{packLabel}</div>
        <div className={styles.description}>{packDescription}</div>
        <div>
          <div className={styles.star}>
            <img src={images.star.publicURL} alt="star" />
            <div className={styles.star_content}> {isSopk ? 'UN' : 'DEUX'} COACHING AVEC UNE DIÉTÉTICIENNE</div>
          </div>
          <div className={styles.star}>
            <img src={images.star.publicURL} alt="star" />
            <div className={styles.star_content}>DES RECETTES PROFERTILITÉ</div>
          </div>
          <div className={styles.star}>
            <img src={images.star.publicURL} alt="star" />
            <div className={styles.star_content}>DES COMPLÉMENTS ALIMENTAIRES FABRIQUÉS EN FRANCE</div>
          </div>
        </div>
      </div>

      <div className={styles.price_wrapper}>
        <div>
          <span className={styles.price}>Prix Total</span>
        </div>
        <div className={styles.strikethrough_wrapper}>
          <div className={styles.price}>{cart.total}€</div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

PackCartItem.propTypes = {
  packType: PropTypes.oneOf(['sopk', 'age']).isRequired,
};

export default PackCartItem;
