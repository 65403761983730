import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styles from './avis.module.scss';

function Avis({ avis, trustpilotStarsImage, openingImage, closingImage }) {
  return (
    <div className={styles.avis}>
      <GatsbyImage
        image={avis.image.childImageSharp.gatsbyImageData}
        alt={`un avis beMum`}
        className={styles.img_avis}
      />
      <div className={styles.header_avis}>
        <h3 className={styles.h3}>Avis BeMum</h3>
        <GatsbyImage image={trustpilotStarsImage} alt="5 étoiles trustpilot" className={styles.img_stars} />
      </div>
      <hr className={styles.bar_avis} />
      <GatsbyImage image={openingImage} alt="opening quote" className={styles.opening_quote} />
      <p className={styles.texte}>{avis.text}</p>
      <GatsbyImage image={closingImage} alt="closing quote" className={styles.closing_quote} />
      <p className={styles.avis_author}>{avis.name}</p>
    </div>
  );
}

export default Avis;
