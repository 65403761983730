import React from 'react';
import Modal from 'react-modal';
import styles from './prescription-popup.module.scss';
import { Formik, Form, Field } from 'formik';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.58)',
    zIndex: 9000,
  },
  content: {
    top: '40%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible',
    padding: '0',
    backgroundColor: 'transparent',
    marginTop: '60px',
  },
  zIndex: 9000,
};

/**
 * Results popup for options
 */
export default function PrescriptionPopup({ modalOpen, closeModal }) {
  const modalCloseTimeout = 100;

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Champ requis';
    } else if (!/^[a-z][a-z '-.,]{0,31}$|^$/i.test(values.name)) {
      errors.name = 'non valide';
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = 'Champ requis';
    } else if (
      !/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(values.phoneNumber) &&
      !/^((\+|00\s?)352)?\s?6[269]1(\s?\d{3}){2}$/.test(values.phoneNumber)
    ) {
      errors.phoneNumber = 'Numero de téléphone non valide';
    }
    if (!values.email) {
      errors.email = 'Champ requis';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Adresse email invalide';
    }
    if (!values.doctorName) {
      errors.doctorName = 'Champ requis';
    } else if (!/^[a-z][a-z '-.,]{0,31}$|^$/i.test(values.doctorName)) {
      errors.doctorName = 'non valide';
    }
    return errors;
  };

  const onSubmit = async (values, { setStatus }) => {
    const message = {
      blocks: [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text:
              `:bust_in_silhouette: *Nom:* ${values.name}\n` + // Nom du patient
              `:phone: *Téléphone:* ${values.phoneNumber}\n` + // Numéro de téléphone du patient
              `:email: *Email:* ${values.email}\n` + // Adresse email du patient
              `:male-doctor: *Médecin:* ${values.doctorName}`, // Nom du médecin
          },
        },
      ],
    };

    try {
      await axios.post(process.env.GATSBY_PRESCRIPTION_BEMUM_SLACK_WEBHOOK, JSON.stringify(message));
      setStatus({
        success: true,
        message: 'Votre demande a bien été envoyée. Nous vous contacterons dans les plus brefs délais.',
      });
    } catch (error) {
      console.error(error);
      setStatus({ success: false, message: 'Une erreur est survenue. Veuillez réessayer plus tard.' });
    }
    setTimeout(() => closeModal(), 5000);
  };

  return (
    <>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        style={modalStyles}
        closeTimeoutMS={modalCloseTimeout}
      >
        {/** Close Button */}
        <button className={styles.close} onClick={closeModal}>
          <svg width="20" height="20" viewBox="0 0 15 15">
            <path
              d="M1.033 14.468l6.48-6.48 6.467 6.467.475-.475-6.467-6.466 6.48-6.48-.528-.529-6.48 6.48L1.02.546l-.475.475 6.44 6.44-6.48 6.48z"
              fill="#000"
              fillRule="nonzero"
            />
          </svg>
        </button>

        <div className={styles.popup_container}>
          <div className={styles.popup_content}>
            <h3 className={styles.popup_title}>J'ai une prescription</h3>
            <Formik
              initialValues={{
                name: '',
                phoneNumber: '',
                email: '',
                doctorName: '',
              }}
              validate={validate}
              onSubmit={onSubmit}
            >
              {({ values, errors, touched, isValid, dirty, status }) => (
                <Form className={styles.formContainer}>
                  <ReactTooltip place="top" id="pay-button-tip" uuid="t1969a1dc-d357-49d5-b1de-f4238f19d95f" />
                  <div className={styles.fieldContainer}>
                    <Field
                      id="name"
                      name="name"
                      placeholder="Votre nom"
                      value={values.name}
                      className={
                        errors.name && touched.name ? `${styles.input} ${styles.inputError}` : `${styles.input}`
                      }
                    />

                    <span className={styles.Error}>{errors.name && touched.name ? errors.name : ''}</span>
                  </div>
                  <div className={styles.fieldContainer}>
                    <Field
                      id="phoneNumber"
                      name="phoneNumber"
                      placeholder="Votre numéro de téléphone"
                      value={values.phoneNumber}
                      className={
                        errors.phoneNumber && touched.phoneNumber
                          ? `${styles.input} ${styles.inputError}`
                          : `${styles.input}`
                      }
                    />
                    <span className={styles.Error}>
                      {errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : ''}
                    </span>
                  </div>

                  <div className={styles.fieldContainer}>
                    <Field
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Votre email"
                      value={values.email}
                      className={
                        errors.email && touched.email ? `${styles.input} ${styles.inputError}` : `${styles.input}`
                      }
                    />
                    <span className={styles.Error}>{errors.email && touched.email ? errors.email : ''}</span>
                  </div>
                  <div className={styles.fieldContainer}>
                    <Field
                      id="doctorName"
                      type="text"
                      name="doctorName"
                      placeholder="Le nom de votre médecin"
                      value={values.doctorName}
                      className={
                        errors.doctorName && touched.doctorName
                          ? `${styles.input} ${styles.inputError}`
                          : `${styles.input}`
                      }
                    />
                    <span className={styles.Error}>
                      {errors.doctorName && touched.doctorName ? errors.doctorName : ''}
                    </span>
                  </div>
                  <button
                    type="submit"
                    className={`${
                      !isValid || !dirty
                        ? `${styles.BlackBtn} ${styles.BtnDisable} `
                        : `${styles.BlackBtn} ${styles.BlackBtnEnable}  `
                    }`}
                    data-for={!(isValid && dirty) ? 'pay-button-tip' : ''}
                    data-tip={
                      !isValid
                        ? 'Votre formulaire contient des erreurs. Merci de vérifier vos informations avant de soumettre à nouveau.'
                        : ''
                    }
                  >
                    je veux un rendez vous
                  </button>
                  <span className={status?.success ? styles.Success : styles.Error}> {status?.message} </span>
                </Form>
              )}
            </Formik>
          </div>
          <div className={styles.popup_img} />
        </div>
      </Modal>
    </>
  );
}
