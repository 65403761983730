import React from 'react';
import styles from './modules/process.module.scss';

export const steps = Object.freeze({
  CART: 'cart',
  INFORMATIONS: 'informations',
});

export default function Process({ currentStep }) {
  return (
    <div className={styles.timeline}>
      <ul className={styles.timeline_contents}>
        <li className={`${currentStep === steps.CART ? styles.timeline_active : ''} ${styles.left}`}>
          <div>
            <div className={styles.title}>Votre panier</div>
          </div>
        </li>
        <li className={`${currentStep === steps.INFORMATIONS ? styles.timeline_active : ''} ${styles.center}`}>
          <div className={styles.title}>Vos&nbsp;informations</div>
          <div className={styles.description}>Nom, Prénom, Adresse</div>
        </li>
        <li className={styles.right}>
          <div className={`${styles.title}`}>Paiement </div>
          <div className={styles.description}>Carte Bleue, Google Pay, Apple Pay</div>
        </li>
      </ul>
    </div>
  );
}
