import PropTypes from 'prop-types';
import React from 'react';
import QuestionDefinition from '../question-definition.proptypes';
import styles from './welcome.module.scss';

/**
 * Question of type "welcome"
 * @param {Object} props
 * @param {import("./question").Definition} props.definition - Welcome question definition
 * @param {string} firstname - User firstname
 */
function Welcome({ definition, firstname }) {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.welcome}>Bienvenue {firstname} !</h2>
      <h2 className={styles.title}>{definition.title}</h2>
      {definition.helper && (
        <div className={styles.helper} dangerouslySetInnerHTML={{ __html: definition.helper }}></div>
      )}
      {definition.expertise && (
        <div className={styles.proTips}>
          <h3 className={`t6`}>{definition.expertise.title}</h3>
          <p className={`BlackLight Regular p1`}>{definition.expertise.content}</p>
        </div>
      )}
    </div>
  );
}
export default Welcome;

Welcome.propTypes = {
  definition: QuestionDefinition.isRequired,
  firstname: PropTypes.string.isRequired,
};
