import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import useLocalStorage from '../../hooks/useLocalStorage';
import { getDescriptionForOptionItem } from './helpers';
import style from './results-popup-options.module.scss';

Modal.setAppElement(`#___gatsby`);

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.58)',
    zIndex: 9000,
  },
  content: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    maxHeight: '80vh',
    margin: '10vh',
    padding: '2rem 0',
    border: '1px solid #262626',
    overflowY: 'auto',
    borderRadius: '30px'
  },
  zIndex: 9000,
};

/**
 * Results popup for options
 */
function ResultsPopupOptions({ modalOpen, closeModal, option, checked, toggleChecked, image }) {
  const modalCloseTimeout = 300;

  const [fertilityTestResults] = useLocalStorage('answers', null);

  const [description, setDescription] = useState(null);

  const product = {};

  if (option === 'coaching') {
    product.title = 'Plus de téléconsultations Coaching +';
    product.price = 30;
  }

  if (option === 'antiox') {
    product.title = `Spécificité + 35 ans`;
    product.price = 19.80;
  }
  if (option === 'equilibre') {
    product.title = `Spécificité poids / SOPK`;
    product.price = 16.50;
  }

  useEffect(() => {
    const description = getDescriptionForOptionItem(option, fertilityTestResults);
    setDescription(description);
  }, [fertilityTestResults]);

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Modal"
      closeTimeoutMS={modalCloseTimeout}
    >
      {/** Close Button */}
      <button className={style.close} onClick={closeModal}>
        <svg width="30" height="30" viewBox="0 0 15 15">
          <path
            d="M1.033 14.468l6.48-6.48 6.467 6.467.475-.475-6.467-6.466 6.48-6.48-.528-.529-6.48 6.48L1.02.546l-.475.475 6.44 6.44-6.48 6.48z"
            fill="#000"
            fillRule="nonzero"
          />
        </svg>
      </button>
      <div className={`${style.flex} ${style.section} Center`}>
        <div className={`t2 mb4`} dangerouslySetInnerHTML={{ __html: product.title }} />

        {/** Image */}
        <div className={`${style.image_wrapper}`}>
          <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={option} />
        </div>

        <div>
          {/** Heading */}
          {/* <div className={`t2 mb4 xs-hide sm-hide md-hide`} dangerouslySetInnerHTML={{ __html: product.title }} /> */}
          <div className={`mt4 mb3 ${style.description}`} dangerouslySetInnerHTML={{ __html: description }} />

          {/** Price */}
          <div className={style.price_wrapper}>
            <button
              className={`Btn Btn--Reg Btn--Circle-Icon Btn--Circle-Icon ${checked ? 'Btn--Circle-Icon--Checked Btn--Black ' : ''
                }`}
              onClick={toggleChecked}
            >
              {product.price}€ &nbsp; {checked ? 'Ajouté' : 'Ajouter'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ResultsPopupOptions;
