import React, { useEffect, useState } from 'react';

const ProgressBar = ({ score, progress }) => {
  const [width, setWidth] = useState('0%');
  const [progressIncrement, setAmountIncrement] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (progressIncrement < progress) {
        setAmountIncrement(progressIncrement + 1);
      } else {
        clearInterval(intervalId);
      }
    }, 5000 / progress);

    return () => clearInterval(intervalId);
  }, [progress, progressIncrement]);

  useEffect(() => {
    setWidth(`${progress}%`);
  }, [progress]);

  const Parentdiv = {
    height: 23,
    width: '100%',
    backgroundColor: 'whites',
    borderRadius: 70,
    border: '1px solid #EDEDED',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 23,
    width: 325,
  };

  const Childdiv = {
    height: '100%',
    width: width,
    background:
      score >= 20
        ? 'linear-gradient(90deg, #EDEDED 0%, #EDEDED 35%, #FB7977 100%)'
        : 'linear-gradient(90deg, #EDEDED 0%, #EDEDED 35%, #58AA3A 100%)',
    borderRadius: 70,
    transition: 'width 5s ease-in-out',
  };

  const progresstext = {
    padding: 10,
    color: 'black',
    fontFamily: 'Poppins',
    fontSize: 10,
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv} />
      <div style={progresstext}>{`${progressIncrement}%`}</div>
    </div>
  );
};

export default ProgressBar;
