import PropTypes from 'prop-types';

export default PropTypes.shape({
  consent: PropTypes.string,
  error_message: PropTypes.string,
  exclude_opts: PropTypes.arrayOf(PropTypes.string),
  expertise: PropTypes.shape({
    content: PropTypes.string,
    title: PropTypes.string,
  }),
  helper: PropTypes.string,
  image: PropTypes.object,
  input: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
  max_opt: PropTypes.number,
  min_opt: PropTypes.number,
  name: PropTypes.string.isRequired,
  next: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.oneOf(['checkbox', 'radio']),
      value: PropTypes.string,
      detail: PropTypes.string,
    })
  ),
  range: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
  }),
  title: PropTypes.string,
  type: PropTypes.oneOf(['radio', 'checkbox', 'interlude', 'text', 'email', 'number', 'range', 'welcome', 'hello'])
    .isRequired,
});
